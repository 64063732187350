<template>
    <div class="m-4">
        <h3>3PL Info</h3>
        <hr />
        <mdb-btn @click="add" color="primary" class="mb-4">Add 3PL</mdb-btn>
        <vue-good-table
            class="w-100"
            :columns="headers"
            :rows="accounts"
            :search-options="{
                enabled: true,
            }"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'billing_type'">
                    <b>{{ props.row.zipcode }}</b>
                </span>
                <span v-else-if="props.column.field === 'actions'">
                    <div class="d-flex">
                        <mdb-btn icon color="info" @click.native="edit(props.row)" size="sm"
                            ><mdb-icon icon="edit"></mdb-icon
                        ></mdb-btn>
                        <mdb-btn icon color="danger" @click.native.stop="confirm(props.row)" size="sm"
                            ><mdb-icon icon="trash"></mdb-icon
                        ></mdb-btn>
                    </div>
                </span>
                <span v-else>{{ props.row[props.column.field] }}</span>
            </template>
        </vue-good-table>
        <mdb-modal :show="editModal" v-if="editingRow" scrollable size="lg">
            <mdb-modal-header :close="false">{{ editing ? "Edit" : "Add" }} 3PL</mdb-modal-header>
            <mdb-modal-body>
                <div v-for="item in editableRows" v-bind:key="item.field">
                    <div v-if="item.field === 'billing_type'">
                        <mdb-select
                            v-model="billTypes"
                            :label="item.label"
                            :searchable="false"
                            :clearable="false"
                            :disabled="item.disabled"
                        />
                    </div>
                    <div v-else-if="item.field === 'carrier'">
                        <mdb-select
                            v-model="carrierTypes"
                            :label="item.label"
                            :searchable="false"
                            :clearable="false"
                            :disabled="item.disabled"
                        />
                    </div>
                    <div v-else-if="item.field === 'active'">
                        <mdb-select
                            v-model="activeTypes"
                            :label="item.label"
                            :searchable="false"
                            :clearable="false"
                            :disabled="item.disabled"
                        />
                    </div>
                    <mdb-input
                        type="text"
                        v-model="editingRow[item.field]"
                        :label="item.label"
                        :disabled="item.disabled"
                        v-else
                    />
                </div>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn @click="save" color="success" v-if="!saving" text>Save</mdb-btn>
                <mdb-btn disabled color="success" v-else text>Saving...</mdb-btn>
                <mdb-btn @click="cancelEdit" color="danger" text>Cancel</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <mdb-modal class="mt-10" :show="deleteConfirm" v-if="deletingRow" @close="deleteConfirm = false">
            <mdb-modal-header>Delete 3PL?</mdb-modal-header>
            <mdb-modal-body>
                <p>Are you sure you want to delete this 3PL?</p>
                <p>{{ deletingRow.master_client_id }}</p>
                <p>{{ deletingRow.carrier }}</p>
                <p>{{ deletingRow.contact_email }}</p>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn @click="deleteAccount" color="success" v-if="!deleting" text>Delete</mdb-btn>
                <mdb-btn color="success" v-else text>Delete</mdb-btn>
                <mdb-btn @click="deleteConfirm = false" color="danger" text>Cancel</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>

<script>
import Auth from "aws-amplify";
export default {
    name: "ClientAdmin",

    data() {
        return {
            headers: [
                { label: "Client ID", field: "master_client_id", sortable: false, class: "grey lighten-4" },
                { label: "Company", field: "company_name", sortable: false, class: "grey lighten-4" },
                { label: "Email", field: "contact_email", sortable: false, class: "grey lighten-4" },
                { label: "Contact Name", field: "contact_name", sortable: false, class: "grey lighten-4" },
                { label: "Contact Phone", field: "contact_phone", sortable: false, class: "grey lighten-4" },
                { label: "Carrier", field: "carrier", sortable: false, class: "grey lighten-4" },
                { label: "Billing Type", field: "billing_type", sortable: false, class: "grey lighten-4" },
                { label: "Billing Amount", field: "billing_amount", sortable: false, class: "grey lighten-4" },
                { label: "Active", field: "active", sortable: false, class: "grey lighten-4" },
                { label: "", field: "actions", sortable: false, class: "grey lighten-4" },
            ],
            editableRows: [
                { label: "Client ID", field: "master_client_id", disabled: true },
                { label: "Company", field: "company_name" },
                { label: "Email", field: "contact_email" },
                { label: "Contact Name", field: "contact_name" },
                { label: "Contact Phone", field: "contact_phone" },
                { label: "Carrier", field: "carrier", disabled: true },
                { label: "Billing Type", field: "billing_type" },
                { label: "Billing Amount", field: "billing_amount" },
                { label: "Customer Status", field: "active" },
                { label: "FTP Username", field: "ftp_username" },
                { label: "FTP Password", field: "ftp_password" },
                { label: "FDX API Key", field: "api_key" },
                { label: "FDX API Pass", field: "api_pass" },
                { label: "FDX Account #", field: "account_number" },
                { label: "FDX Meter #", field: "meter_number" },
                { label: "UPS Username", field: "username" },
                { label: "UPS Password", field: "password" },
                { label: "UPS Access Key", field: "access_key" },
            ],
            billTypes: [
                { value: null, text: "Select Billing Type", selected: false },
                { value: 1, text: "Fixed Revenue", selected: false },
                { value: 2, text: "Monthly Agreement", selected: false },
            ],
            carrierTypes: [
                { value: null, text: "Select Carrier", selected: false },
                { value: "FDX", text: "FedEx", selected: false },
                { value: "UPS", text: "UPS", selected: false },
                { value: "DHL", text: "DHL", selected: false, disabled: true },
            ],
            activeTypes: [
                { value: 1, text: "Active", selected: false },
                { value: 0, text: "Inactive", selected: false },
            ],
            pmoClientID: "",

            account: {
                master_client_id: "",
                company_name: "",
                contact_email: "",
                contact_name: "",
                contact_phone: "",
                username: "",
                password: "",
                active: "",
                api_key: "",
                api_pass: "",
                account_number: "",
                meter_number: "",
                access_key: "",
                ftp_username: "",
                ftp_password: "",
                billing_type: 0,
                billing_amount: 0,
                notes: "",
                ref_field: "",
                carrier: "",
            },
            accounts: null,
            showPMOOldPassword: false,
            showPMONewPassword: false,
            showFTPPassword: false,
            editModal: false,
            msgText: "",
            editingRow: null,
            editing: false,
            deletingRow: null,
            deleteConfirm: false,
            saving: false,
            deleting: false,
        };
    },

    created: async function () {
        this.getClientAccounts();
    },

    methods: {
        async getClientAccounts() {
            try {
                const url = `client-accounts`;

                const accounts = await this.$httpNew.get(url);

                if (accounts.data) {
                    this.accounts = accounts.data;
                }
            } catch (err) {
                // alert(err.message)
            }
        },
        edit(row) {
            this.editing = true;
            this.editableRows.find((c) => c.field === "master_client_id").disabled = true;
            this.editableRows.find((c) => c.field === "carrier").disabled = true;
            if (row.billing_type) {
                this.billTypes.map((c) => {
                    c.selected = false;
                    if (c.value === row.billing_type) {
                        c.selected = true;
                    }
                    return c;
                });
            }
            this.carrierTypes.map((c) => {
                c.selected = false;
                if (c.value === row.carrier) {
                    c.selected = true;
                }
                return c;
            });
            const active = row.active === true ? 1 : 0;
            this.activeTypes.map((c) => {
                c.selected = false;
                if (c.value === active) {
                    c.selected = true;
                }
                return c;
            });
            this.editingRow = {
                ...row,
                api_key: null,
                api_pass: null,
                access_key: null,
                ftp_username: null,
                ftp_password: null,
                account_number: null,
                username: null,
                password: null,
            };
            this.editModal = true;
        },
        add() {
            this.editableRows.find((c) => c.field === "master_client_id").disabled = false;
            this.editableRows.find((c) => c.field === "carrier").disabled = false;
            this.editing = false;
            this.editingRow = this.account;
            this.editModal = true;
        },
        cancelEdit() {
            this.editingRow = null;
            this.editModal = false;
            this.account = {
                master_client_id: "",
                company_name: "",
                contact_email: "",
                contact_name: "",
                contact_phone: "",
                username: "",
                password: "",
                active: false,
                api_key: "",
                api_pass: "",
                account_number: "",
                meter_number: "",
                access_key: "",
                ftp_username: "",
                ftp_password: "",
                billing_type: 0,
                billing_amount: 0,
                notes: "",
                ref_field: "",
                carrier: "",
            };
        },
        async save() {
            this.saving = true;
            try {
                const url = `client-accounts`;
                let response;
                this.editingRow.billing_type = this.billTypes.find((c) => c.selected).value;
                this.editingRow.carrier = this.carrierTypes.find((c) => c.selected).value;
                const foundActive = this.activeTypes.find((c) => c.selected).value;
                this.editingRow.active = foundActive === 1 ? true : false;
                if (this.editing) {
                    response = await this.$httpNew.put(url, this.editingRow);
                } else {
                    response = await this.$httpNew.post(url, this.editingRow);
                }

                if (!response || !response.data || response.data.error_msg) {
                    this.$notify.error({
                        message: response && response.data ? response.data.error_msg : "Error processing your request.",
                        timeOut: 9000,
                    });
                } else {
                    this.$notify.success({ message: "Successfully saved client information", timeOut: 9000 });
                    this.editModal = false;
                    this.getClientAccounts();
                }
            } catch (err) {
                this.editModal = false;
                this.$notify.error({ message: "Error processing your request.", timeOut: 9000 });
            }
            this.saving = false;
        },
        async deleteAccount() {
            this.deleting = true;
            const url = `client-accounts`;
            const response = await this.$httpNew.delete(url, { params: this.deletingRow });
            if (!response || !response.data || response.data.error_msg) {
                this.$notify.error({
                    message: response && response.data ? response.data.error_msg : "Could not delete 3PL account.",
                    timeOut: 9000,
                });
                this.deleteConfirm = false;
            } else {
                this.$notify.success({ message: "Successfully deleted 3PL client", timeOut: 9000 });
                this.deleteConfirm = false;
                this.getClientAccounts();
            }
            this.deleting = false;
        },
        confirm(row) {
            this.deletingRow = row;
            this.deleteConfirm = true;
        },
        changePassword() {
            if (this.account.account_password && this.account.newPassword) {
                Auth.currentAuthenticatedUser()
                    .then((user) => {
                        return Auth.changePassword(user, this.account.account_password, this.account.newPassword);
                    })
                    .then((data) => {
                        console.log(data);
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            } else {
                this.$notify.error({ message: "Enter old password and new password before continuing", timeOut: 9000 });
            }
        },
    },
};
</script>

<style lang="scss" scoped></style>

<template>
    <div class="m-4">
        <div class="d-flex align-items-center" v-if="!isLoading">
            <mdb-dropdown v-if="reportSetup" class="flex-fill mr-2">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    selectedReport ? selectedReport.reportName : "Select Report"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item
                        v-for="report in reportSetup"
                        :key="report.reportID"
                        :active="selectedReport && report.reportID === selectedReport.reportID"
                        @click.native="changeReport(report)"
                        >{{ report.reportName }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-btn outlined color="orange" v-on:click="regenReports()"> {{this.packageCount ? 'Recalculate Reports': 'Recalculate Reports: ' + this.packageCount + ' Pkgs' }}</mdb-btn>
            <mdb-btn
                outlined
                color="orange"
                v-on:click="postReports"
                v-show="
                    pldData &&
                    invoiceSummaryData &&
                    carrierDataRegenData &&
                    (pldData.length > 0 || invoiceSummaryData.length > 0 || carrierDataRegenData.length > 0)
                "
            >
                Post All Reports
            </mdb-btn>
        </div>
        <mdb-row class="m-4">
            <mdb-col col="8">
                <div v-show="downloading">
                    Please wait ...

                    <mdb-spinner color="blue"> </mdb-spinner>
                </div>
                <mdb-row v-if="outputData">
                    <div>
                        <h3 class="md-headline4">{{ reportName }}</h3>
                        <vue-good-table
                            v-if="outputData.length > 0 && isDisplayResults"
                            :columns="headers"
                            :rows="outputData"
                            item-key="client_id"
                            ref="datatable"
                        >
                            <template slot="table-row" slot-scope="props">
                                <span v-if="props.column.field === 'clientId'">
                                    <b>{{ props.row.clientId }}</b>
                                </span>
                                <span v-if="props.column.field === 'clientName'">{{ props.row.clientName }}</span>
                                <span v-if="props.column.field === 'externalID'">{{ props.row.externalID }}</span>
                                <span v-if="props.column.field === 'threePLCost'">{{ props.row.threePLCost }}</span>
                                <span v-if="props.column.field === 'clientPrice'">{{ props.row.clientPrice }}</span>
                                <span v-if="props.column.field === 'threePLProfit'">{{ props.row.threePLProfit }}</span>
                                <span v-if="props.column.field === 'threePLMargin'">{{ props.row.threePLMargin }}</span>
                                <span v-if="props.column.field === 'pkgs'">{{ props.row.pkgs }}</span>
                                <span v-if="props.column.field === 'pkgRevenue'">{{ props.row.pkgRevenue }}</span>
                                <span v-if="props.column.field === 'avgTNT'">{{ props.row.avgTNT }}</span>
                                <span v-if="props.column.field === 'startDate'">{{ props.row.startDate }}</span>
                                <span v-if="props.column.field === 'endDate'">{{ props.row.endDate }}</span>
                                <span v-if="props.column.field === 'actions'">
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 0"
                                        icon="table"
                                        color="orange"
                                        @click.native.stop="downloadExcel(props.row.originalIndex)"
                                    >
                                    </mdb-btn>
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 2"
                                        color="orange"
                                        icon="save"
                                        @click.native.stop="downloadFile(props.row.originalIndex)"
                                    >
                                    </mdb-btn>
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 1"
                                        color="orange"
                                        icon="save"
                                        @click.native.stop="downloadExcel(props.row.originalIndex)"
                                    ></mdb-btn>
                                </span>
                                <span v-if="props.column.field === 'waitNotice'" width="15%">{{
                                    props.row.waitNotice
                                }}</span>
                            </template>
                        </vue-good-table>
                        <mdb-row v-if="noResults">
                            <p>No results found in this date range for this report/client/carrier combo.</p>
                        </mdb-row>
                    </div>
                </mdb-row>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";
import PMO_Reports from "@/assets/js/PMO_Reports";

export default {
    name: "ReportPosting",

    props: {
        selectedSubclient: Object,
        selectedCarrier: Object,
        startDate: String,
        endDate: String,
    },

    data() {
        return {
            reportSetup: [
                { reportID: 0, reportName: "Package Level Detail" },
                { reportID: 1, reportName: "Invoice Summary" },
                { reportID: 2, reportName: "Carrier Data Regeneration" },
            ],
            selectedReport: null,
            headers: null,
            outputData: null,
            isLoading: false,
            pldData: null,
            invoiceSummaryData: null,
            carrierDataRegenData: null,
            subclient: null,
            carrier: null,
            beginRptDte: null,
            endRptDate: null,
            isDisplayResults: false,
            externalID: "",
            downloading: false,
            noResults: false,
            packageCount: null
        };
    },

    methods: {
        changeReport(report) {
            try {
                this.selectedReport = report;
            } catch (err) {
            } finally {
                this.selectReport(this.selectedReport);
            }
        },

        async selectReport(report) {
            this.isDisplayResults = false;
            switch (report.reportID) {
                case 0:
                    this.noResults = false;
                    if (this.pldData === null) {
                        this.downloading = true;
                        this.pldData = (await this.getPLDHold()).data;
                        this.downloading = false;
                    }
                    if (this.pldData.length === 0) {
                        this.noResults = true;
                    }
                    this.headers = [
                        { label: "3RO ID", field: "clientId" },
                        { label: "External ID", field: "externalID" },
                        { label: "Client Name", field: "clientName" },
                        { label: "Packages", field: "pkgs" },
                        { label: "Start Date", field: "startDate", sortable: false, width: "100px" },
                        { label: "End Date", field: "endDate", sortable: false, width: "100px" },
                        { label: "Download", field: "actions" },
                    ];
                    let numPkgs = 0;
                    this.pldData.forEach((row) => {
                        if (row[18] !== "") {
                            numPkgs++;
                        }
                    });
                    this.outputData = [];
                    if (numPkgs > 0) {
                        this.outputData = [
                            {
                                clientId: this.selectedSubclient.client_id,
                                clientName: this.selectedSubclient.sub_client_name,
                                externalID: this.externalID,
                                pkgs: numPkgs.toLocaleString("en-US"),
                                pkgCnt: numPkgs,
                                startDate: this.beginRptDte,
                                endDate: this.endRptDate,
                                waitNotice: "",
                                data: this.pldData,
                            },
                        ];
                    }

                    break;
                case 1:
                    this.noResults = false;
                    if (this.invoiceSummaryData === null) {
                        this.downloading = true;
                        this.invoiceSummaryData = (await this.getInvoiceSummaryHold()).data;
                        this.downloading = false;
                    }
                    if (this.invoiceSummaryData.length === 0) {
                        this.noResults = true;
                    }
                    this.headers = [
                        { label: "3RO ID", field: "clientId", sortable: false },
                        { label: "Client Name", field: "clientName", sortable: false, width: "250px" },
                        { label: "3RO ID", field: "externalID", sortable: false },
                        { label: "3PL Cost", field: "threePLCost", sortable: false },
                        { label: "Client Price", field: "clientPrice", sortable: false },
                        { label: "3PL Profit", field: "threePLProfit", sortable: false },
                        { label: "3PL Margin", field: "threePLMargin", sortable: false, width: "100px" },
                        { label: "Packages", field: "pkgs", sortable: false },
                        { label: "Revenue/Pkg", field: "pkgRevenue", sortable: false },
                        { label: "Start Date", field: "startDate", sortable: false, width: "100px" },
                        { label: "End Date", field: "endDate", sortable: false, width: "100px" },
                        { label: "Download", field: "actions", sortable: false },
                    ];
                    let invoiceOutput = [];
                    for (const row of this.invoiceSummaryData) {
                        let thisRow = {
                            clientId: this.selectedSubclient.client_id,
                            clientName: this.selectedSubclient.sub_client_name,
                            startDate: row.start_date.split("T")[0],
                            endDate: row.end_date.split("T")[0],
                            createdDate: row.created_date,
                            carrier: row.carrier,
                            externalID: this.externalID,
                            pkgs: row.report.rerate_count.toLocaleString("en-US"),
                            clientPrice: "$" + PMO_Global.formatMoney(row.report.subclient_net.toFixed(2)),
                            threePLCost: "$" + PMO_Global.formatMoney(row.report.data_net.toFixed(2)),
                            threePLProfit: "$" + PMO_Global.formatMoney(row.report.threepl_profit.toFixed(2)),
                            threePLMargin:
                                (row.report.rerate_count > 0
                                    ? PMO_Global.formatMoney(
                                          ((row.report.threepl_profit / row.report.threepl_net) * 100).toFixed(2)
                                      )
                                    : "0") + " %",
                            pkgRevenue:
                                "$" +
                                PMO_Global.formatMoney(
                                    (row.report.threepl_profit / row.report.rerate_count).toFixed(2)
                                ),
                            avgTNT: parseFloat(row.report.avg_tnt.toFixed(2)),
                            data: row.report,
                        };
                        invoiceOutput.push(thisRow);
                    }
                    this.outputData = invoiceOutput;
                    break;
                case 2:
                    this.noResults = false;
                    this.outputData = [];
                    if (this.carrierDataRegenData === null) {
                        this.downloading = true;
                        this.carrierDataRegenData = (await this.getCarrierDataHold()).data;
                        this.downloading = false;
                    }
                    if (this.carrierDataRegenData.length === 0) {
                        this.noResults = true;
                    }
                    this.headers = [
                        { label: "3RO ID", field: "clientId", sortable: false },
                        { label: "Client Name", field: "clientName", sortable: false, width: "250px" },
                        { label: "External ID", field: "externalID", sortable: false },
                        { label: "Records", field: "pkgs", sortable: false },
                        { label: "Start Date", field: "startDate", sortable: false, width: "100px" },
                        { label: "End Date", field: "endDate", sortable: false, width: "100px" },
                        { label: "Download", field: "actions", sortable: false },
                    ];
                    for (const row of this.carrierDataRegenData) {
                        this.outputData.push({
                            startDate: row.start_date.split("T")[0],
                            endDate: row.end_date.split("T")[0],
                            clientId: this.selectedSubclient.client_id,
                            clientName: this.selectedSubclient.sub_client_name,
                            externalID: this.externalID,
                            pkgs: row.data_row.length.toLocaleString("en-US"),
                            waitNotice: "",
                            data: row.data_row,
                        });
                    }
                    break;
            }
            this.isDisplayResults = true;
        },

        async regenReports() {
            let ownerResp = await this.$httpNew.get(`client`);

            let ownsSubclient = ownerResp.data.clients.find((i) => i.client_id === this.subclient.client_id);

            if (!ownsSubclient) {
                this.$notify.error({
                    message:
                        "You do not have permission to regenerate reports for this subclient. Please contact an administrator.",
                    timeOut: 9000,
                });
                return false;
            }

            const user = this.$store.getters.user;

            let resp = await this.$http.post(`/Run3ROReports`, {
                username: user.username,
                start_date: this.beginRptDte,
                end_date: this.endRptDate,
                client_id: this.subclient.client_id,
            });

            if (resp) {
                this.$notify.success({
                    message: `Regenerating reports for ${this.subclient.sub_client_name}. This may take a while-- come back later.`,
                    timeOut: 9000,
                });
                this.$emit("close");
            }
        },

        async postReports() {
            if (confirm("Post reports with current contract details?")) {
                const postPLDResult = await this.postPLDHold();
                const postInvoiceSummaryResult = await this.postInvoiceSummaryHold();
                const postCarrierDataResult = await this.postCarrierDataHold();
                if (postPLDResult && postInvoiceSummaryResult && postCarrierDataResult) {
                    this.$notify.success({
                        message: `Successfully posted current week reports for ${this.subclient.sub_client_name}`,
                        timeOut: 9000
                    });
                    this.$emit("close", false);
                }
            }
        },

        async postPLDHold() {
            return await this.$httpNew.post(
                `/pldReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.carrier.value}&start_date=${this.beginRptDte}&end_date=${this.endRptDate}`
            );
        },

        async postInvoiceSummaryHold() {
            return await this.$httpNew.post(
                `/invoiceSummaryReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.carrier.value}&start_date=${this.beginRptDte}&end_date=${this.endRptDate}`
            );
        },

        async postCarrierDataHold() {
            return await this.$httpNew.post(
                `/carrierDataReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.carrier.value}&start_date=${this.beginRptDte}&end_date=${this.endRptDate}`
            );
        },

        async downloadFile(recordIdx) {
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);

            // update parent data waitNotice
            const record = this.outputData[recordIdx];
            record.waitNotice = "Please wait ...";

            await PMO_Reports.saveFileWithObject(
                PMO_Reports.create_carrier_data_flat_file(
                    `${_objReportSetup.reportName} - ${this.subClientName} - ${this.selectedCarrier.value} - ${this.beginRptDte} - ${this.endRptDate}`,
                    this.selectedCarrier.value,
                    record.data
                )
            );
            record.waitNotice = "";
        },

        async downloadExcel(reportIdx) {
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);
            this.isGenerateOutput = true;
            await PMO_Reports.buildExcelFile(
                _objReportSetup,
                [this.outputData[reportIdx]],
                this.externalID,
                this.subclient.sub_client_name,
                this.carrier.value,
                this.beginRptDte,
                this.endRptDate,
                this.headers
            );
            this.isGenerateOutput = false;
        },

        async getPLDHold() {
            return await this.$httpNew.get(
                `/pldReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.selectedCarrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async getInvoiceSummaryHold() {
            return await this.$httpNew.get(
                `/invoiceSummaryReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.carrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async getCarrierDataHold() {
            return await this.$httpNew.get(
                `/carrierDataReportHold?subclient_id=${this.subclient.client_id}&carrier=${this.carrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async getPackageCount() {
            let countResp = await this.$http.get(`/SubclientPackageCount?subclient_id=${this.subclient.client_id}&start_date=${this.startDate}&end_date=${this.endDate}`)
            this.packageCount = countResp.data
        },
    },
    async mounted() {
        this.isLoading = true;
        this.carrier = await this.selectedCarrier;
        this.subclient = await this.selectedSubclient;
        this.externalID = this.subclient.external_id;
        this.beginRptDte = this.startDate;
        this.endRptDate = this.endDate;
        await this.getPackageCount()
        this.isLoading = false;
    },
};
</script>

<template>
    <mdb-container id="app" class="h-100 w-100 overflow-hidden m-0 p-0 mb-0" fluid>
        <!-- The content is in the router view -->
        <TISuite />
    </mdb-container>
</template>
<script>
import { Auth } from "aws-amplify";
import TISuite from "@/components/TISuite";
import { useAuthenticationStore } from "@/store/pinia";
export default {
    components: { TISuite },
    setup() {
        const authenticationStore = useAuthenticationStore();
        return { authenticationStore };
    },
    async created() {
        try {
            const user = await Auth.currentAuthenticatedUser();
            if (user) {
                this.$store.dispatch("signIn", user);
                this.authenticationStore.signIn(user);
            }
        } catch (err) {
            this.$store.dispatch("signOut");
            this.authenticationStore.signOut();
        }
    },
};
</script>
<style>
.main-content {
    min-height: 85vh;
    /*width: 50%;*/
    margin: 25px 0 0;
}

.container-fluid {
    margin-top: 15px;
}
.container {
    max-width: 100% !important;
    margin: 0 !important;
    padding: 0 !important;
}
</style>

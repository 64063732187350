<template>
    <app-tabs
        :tabs="filteredTabs"
        :prospects="prospectsList"
        :clients="subClientList"
        :threeplCarriers="threeplCarriers"
        :combined="clientList"
        :locations="clientLocations"
        :getClients="getClients"
        :getLocations="loadLocations"
    ></app-tabs>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";
import AppTabs from "@/components/common/AppTabs";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers();

import { Auth } from "aws-amplify";
export default {
    name: "PMOBase",

    components: {
        "app-tabs": AppTabs,
    },

    data() {
        return {
            hasError: false,
            msgError: [],
            clientList: null,
            locations: null,
            threeplCarriers: [],
            tabs: [
                {
                    id: 0,
                    text: "Analysis",
                    to: "/",
                    permission: "PMO",
                },
                {
                    id: 1,
                    text: "Clients",
                    to: "/clients",
                    permission: "PMO",
                },
                {
                    id: 2,
                    text: "Profiles",
                    to: "/profiles",
                    permission: "PMO",
                },
                // {
                //     id: 3,
                //     text: "Alerts",
                //     to: "/alerts",
                //     permission: "PMO"
                // },
                {
                    id: 4,
                    text: "Reports",
                    to: "/reports",
                    permission: "PMO",
                },
                {
                    id: 5,
                    text: "Locations",
                    to: "/locations",
                    permission: "PMO",
                },
                {
                    id: 6,
                    text: "3PL Info",
                    to: "/setup",
                    permission: "Administrators",
                },
                {
                    id: 7,
                    text: "Users",
                    to: "/users",
                    permission: "Administrators",
                },
            ],
        };
    },

    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        filteredTabs() {
            return this.tabs.filter((t) => {
                const user = this.$store.getters.user;
                const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
                if (groups && groups.includes(t.permission)) {
                    return t;
                }
            });
        },
        prospectsList() {
            return this.clientList ? this.clientList.filter((c) => c.prospect) : null;
        },
        subClientList() {
            return this.clientList ? this.clientList.filter((c) => !c.prospect) : null;
        },
        clientLocations() {
            return this.locations;
        },
    },

    async created() {},

    async mounted() {
        // load client ID
        await Auth.currentUserInfo().then((info) => {
            this.$store.commit("pmo/savePMOClientID", info.attributes["custom:TIClientID"]);
        });
        this.getClients();
        this.loadLocations();
        if (this.$router.currentRoute.path === "/pmo") {
            this.$router.push("/pmo/Analysis");
        }
    },

    methods: {
        async getClients() {
            try {
                const apiClientList = await this.$httpNew.get(`client`);
                if (apiClientList && apiClientList.data) {
                    for (const account of apiClientList.data.clients) {
                        this.$store.commit("pmo/addPMOClientCarrier", account.carrier);
                        this.$store.commit("pmo/savePMOCarrierAccounts", {
                            carrier: account.carrier,
                            carrier_accounts: account.carrier_accounts,
                        });
                    }
                    this.clientList = apiClientList.data.clients;
                    this.threeplCarriers = apiClientList.data.client_accounts.map((acc) => {
                        return acc.carrier;
                    });
                    this.clientList = this.clientList.map((client) => {
                        client["accounts"] = apiClientList.data.client_accounts;
                        return client;
                    });
                    const clientName = apiClientList.data.client_name;
                    this.$store.commit("pmo/savePMOClientName", clientName);
                }
            } catch (e) {
                this.handleError(e);
            }
        },
        async loadLocations() {
            const locations = await this.$httpNew.get("locations");
            if (locations) {
                this.locations = locations.data.map((c) => {
                    c.city = c.address.city;
                    c.state = c.address.state;
                    return c;
                });
            }
        },
    },

    beforeDestroy() {
        this.$store.commit("pmo/resetPMOEntityList"); // TODO deprecate
    },
};
</script>

<style scoped>
.v-tabs__wrapper {
    border-bottom: 1pt solid rgb(0, 0, 0, 0.12);
}
</style>

<script>
export default {
    props: { packages: Promise, accessorials: Promise, is_markup: Boolean },
    data() {
        return {
            isLoading: false,
            view_package_table: true,
            view_acc_table: false,
            packages_data: [],
            accessorials_data: [],
            package_table_headers: (!this.is_markup ? [
                { field: "carrier_price_group", label: "Carrier Price Group" },
                { field: "weight_break", label: "Weight Break" },
                { field: "carrier", label: "Carrier" },
                { field: "date_changed", label: "Date Changed", formatFn: this.formatDate },
                { field: "old_discount", type: "number", label: "Old Discount", formatFn: this.formatPercent },
                { field: "new_discount", type: "number", label: "New Discount", formatFn: this.formatPercent },
                { field: "old_minimum", type: "number", label: "Old Minimum", formatFn: this.formatMoney },
                { field: "new_minimum", type: "number", label: " New Minimum", formatFn: this.formatMoney },
                { field: "changed_by" , label: "Changed By" },
            ] :
            [{ field: "carrier_price_group", label: "Carrier Price Group" },
              { field: "weight_break", label: "Weight Break" },
              { field: "carrier", label: "Carrier" },
              { field: "date_changed", label: "Date Changed", formatFn: this.formatDate },
              { field: "old_markup", type: "number", label: "Old Markup", formatFn: this.formatPercent },
              { field: "new_markup", type: "number", label: "New Markup", formatFn: this.formatPercent },
              { field: "changed_by" , label: "Changed By" }
            ] ),
            acc_table_header:  (!this.is_markup ?[
                { field: "accessorial_carrier_price_group", label: "Carrier Price Group" },
                { field: "carrier", label: "Carrier" },
                { field: "date_changed", label: "Date Changed", formatFn:this.formatDate},
                { field: "old_discount", label: "Old Discount", formatFn:this.formatPercent},
                { field: "new_discount", label: "New Discount", formatFn:this.formatPercent },
                { field: "changed_by", label: "Changed By" },
            ]:[
              { field: "accessorial_carrier_price_group", label: "Carrier Price Group" },
              { field: "carrier", label: "Carrier" },
              { field: "date_changed", label: "Date Changed", formatFn:this.formatDate},
              { field: "old_markup", label: "Old Discount", formatFn:this.formatPercent},
              { field: "new_markup", label: "New Discount", formatFn:this.formatPercent },
              { field: "changed_by", label: "Changed By" }
            ]),
        };
    },
    methods: {
        formatDate(date) {
            return date.split("T")[0];
        },
        formatPercent(number) {
            number = Number(number);
            return `${number.toFixed(1)}%`;
        },
        formatMoney(number) {
            number = Number(number);
            return `$${number.toFixed(2)}`;
        },
        switchToPackages() {
            this.view_package_table = true;
            this.view_acc_table = false;
        },
        switchToAccessorials() {
            this.view_package_table = false;
            this.view_acc_table = true;
        },
    },
    async mounted() {
        this.isLoading = true;
        this.packages_data = (await this.packages).data;
        this.accessorials_data = (await this.accessorials).data;
        this.isLoading = false;
    },
};
</script>

<template>
    <div>
        <mdb-btn color="orange" v-if="view_acc_table" @click="switchToPackages()">View Packages Changes</mdb-btn>
        <mdb-btn color="orange" v-if="view_package_table" @click="switchToAccessorials()"
            >View Accessorial Changes</mdb-btn
        >

        <vue-good-table
            v-if="view_package_table"
            :is-loading="isLoading"
            max-height="300px"
            :fixed-header="true"
            :columns="package_table_headers"
            :rows="packages_data"
        >
        </vue-good-table>
        <vue-good-table
            v-if="view_acc_table"
            :is-loading="isLoading"
            max-height="300px"
            :fixed-header="true"
            :columns="acc_table_header"
            :rows="accessorials_data"
        >
        </vue-good-table>
    </div>
</template>

<template>
    <mdb-container fluid v-if="profileRates">
        <mdb-row>
            <mdb-col col="10">
                <h2>{{ selectedCategory }}</h2>
            </mdb-col>
        </mdb-row>
        <div class="d-flex justify-items-center">
            <div class="flex-fill">
                <mdb-input
                    type="checkbox"
                    id="checkbox-dim"
                    name="checkbox-dim"
                    v-if="profileRates[categoryFormatted]"
                    label="Update All"
                    value="blnUpdateAll"
                    v-model="blnUpdateAll"
                ></mdb-input>
            </div>
            <div class="flex-fill">
                <mdb-input
                    type="text"
                    v-if="profileRates[categoryFormatted] && !blnShowDimInfo && blnUpdateAll"
                    v-model="txtAllMarkup"
                    label="Markup %"
                    filled
                    prefix="%"
                    reverse
                    @keydown.native.enter="updateBatchRates"
                    v-currency="configCurrencyDiscount"
                />
            </div>
            <div class="flex-fill">
                <mdb-btn
                    v-if="profileRates[categoryFormatted] && !blnShowDimInfo && blnUpdateAll"
                    color="primary"
                    outlined
                    @click.native="updateBatchRates"
                >
                    UPDATE
                </mdb-btn>
            </div>
            <div class="flex-fill">
                <mdb-input
                    type="text"
                    v-if="profileRates[categoryFormatted]"
                    v-model="searchText"
                    append-icon="search"
                    label="Search"
                    filled
                />
            </div>
        </div>
        <mdb-row>
            <mdb-col col="2">
                <div v-for="category in profileCategories" :key="category">
                    <mdb-btn
                        flat
                        :class="{ selected: selectedCategory === category }"
                        class="d-flex flex-column p-1 m-1 text-center w-100"
                        @click.native="switchCategory(category)"
                    >
                        <span class="w-100 text-center">{{ category }}</span>
                        <mdb-progress
                            indeterminate
                            color="orange darken-2"
                            absolute
                            bottom
                            v-if="loading[formatName(category)]"
                        ></mdb-progress>
                    </mdb-btn>
                </div>
            </mdb-col>

            <mdb-col col="10">
                <vue-good-table
                    ref="dataFiltered"
                    :columns="headersForTable"
                    :rows="profileRates[categoryFormatted]"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchText,
                    }"
                    :sort-options="{
                        enabled: true,
                    }"
                    :options.sync="pagination"
                    :footer-props="{
                        'items-per-page-options': arrPageOptions,
                    }"
                    :hide-default-footer="!profileRates[categoryFormatted]"
                    :is-loading="isLoading"
                >
                    <div slot="emptystate">No records found for {{ selectedCategory }}</div>
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'cpg'">
                            <b>{{ props.row.cpg }}</b>
                        </span>
                        <span v-if="props.column.field === 'carrier'">
                            <b>{{ props.row.carrier }}</b>
                        </span>
                        <span v-if="props.column.field === 'weight_break'" class="text-md-left">
                            {{ props.row.weight_break }}
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'markup_pct'">
                            <mdb-input
                                type="text"
                                v-model="profileRates[categoryFormatted][props.row.originalIndex].subclient.markup_pct"
                                :ref="'markup_pct_' + props.row.originalIndex + '_subclient'"
                                reverse
                                @focus="$event.target.select()"
                                @keyup.right="move(props.row.originalIndex, 'markup_pct', 'right')"
                                @keyup.enter="move(props.row.originalIndex, 'markup_pct', 'right')"
                                @keyup.left="move(props.row.originalIndex, 'markup_pct', 'left')"
                                @keyup.up="move(props.row.originalIndex, 'markup_pct', 'up')"
                                @keyup.down="move(props.row.originalIndex, 'markup_pct', 'down')"
                                @blur="
                                    updateSubClientCPGRate(
                                        profileRates[categoryFormatted][props.row.originalIndex],
                                        'markup_pct',
                                        props.row.originalIndex,
                                        'subclient'
                                    )
                                "
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                        </span>
                        <span
                            class="text-md-right"
                            v-if="!blnShowDimInfo && props.column.field === 'altMarkupSubClient'"
                        >
                            <mdb-input
                                type="text"
                                v-model="
                                    profileRates[categoryFormatted][props.row.originalIndex].altContract.markup_pct
                                "
                                :ref="'markup_pct_' + props.row.originalIndex + '_altContract'"
                                reverse
                                @focus="$event.target.select()"
                                @keyup.right="move(props.row.originalIndex, 'markup_pct', 'right')"
                                @keyup.enter="move(props.row.originalIndex, 'markup_pct', 'right')"
                                @keyup.left="move(props.row.originalIndex, 'markup_pct', 'left')"
                                @keyup.up="move(props.row.originalIndex, 'markup_pct', 'up')"
                                @keyup.down="move(props.row.originalIndex, 'markup_pct', 'down')"
                                @blur="
                                    updateSubClientCPGRate(
                                        profileRates[categoryFormatted][props.row.originalIndex],
                                        'markup_pct',
                                        props.row.originalIndex,
                                        'altContract'
                                    )
                                "
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'discSubClient'">
                            {{
                                props.row.baseline && props.row.baseline.discount
                                    ? props.row.baseline.discount[0].toFixed(2)
                                    : "0.00"
                            }}%
                        </span>
                        <span
                            v-if="propAnalysis && !blnShowDimInfo && props.column.field === 'minSubClient'"
                            class="text-md-right"
                        >
                            ${{ props.row.baseline ? formatMoney(props.row.baseline.minimum) : "0.00" }}
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'discContract'">
                            <mdb-input
                                v-if="props.row.threepl && props.row.threepl.discount"
                                type="text"
                                v-model="props.row.threepl.discount"
                                :ref="'discount_' + props.row.originalIndex + '_3plDiscount'"
                                reverse
                                @focus="$event.target.select()"
                                @keyup.right="move(props.row.originalIndex, 'discount', 'right')"
                                @keyup.enter="move(props.row.originalIndex, 'discount', 'right')"
                                @keyup.left="move(props.row.originalIndex, 'discount', 'left')"
                                @keyup.up="move(props.row.originalIndex, 'discount', 'up')"
                                @keyup.down="move(props.row.originalIndex, 'discount', 'down')"
                                @blur="
                                    update3plDiscount(profileRates[categoryFormatted][props.row.originalIndex], $event)
                                "
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                            <mdb-input
                                v-else
                                :ref="'discount_' + props.row.originalIndex + '_3plDiscount'"
                                reverse
                                type="text"
                                value="0"
                                @blur="
                                    update3plDiscount(profileRates[categoryFormatted][props.row.originalIndex], $event)
                                "
                                @focus="$event.target.select()"
                                @keyup.right="move(props.row.originalIndex, 'discount', 'right')"
                                @keyup.enter="move(props.row.originalIndex, 'discount', 'right')"
                                @keyup.left="move(props.row.originalIndex, 'discount', 'left')"
                                @keyup.up="move(props.row.originalIndex, 'discount', 'up')"
                                @keyup.down="move(props.row.originalIndex, 'discount', 'down')"
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                        </span>
                        <span v-if="!blnShowDimInfo && props.column.field === 'spend.count'" class="text-md-right">
                            {{ props.row.spend ? props.row.spend.count.toLocaleString() : "0" }}
                        </span>
                        <span
                            v-if="!blnShowDimInfo && props.column.field === 'spend.gross_spend'"
                            class="text-md-right"
                        >
                            ${{ props.row.spend ? formatMoney(props.row.spend.gross_spend) : "0.00" }}
                        </span>
                    </template>
                </vue-good-table>
            </mdb-col>
        </mdb-row>
        <mdb-modal persistent class="mt-10" :show="showOverrideModal" @close="showOverrideModal = false" width="500">
            <mdb-modal-header class="headline">{{ overrideModalItem.title }}</mdb-modal-header>
            <mdb-modal-body>{{ overrideModalItem.message }}</mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn
                    @click="
                        (overrideApproved = true),
                            updateSubClientCPGRate(
                                valuesForOverride.item,
                                valuesForOverride.type,
                                valuesForOverride.index,
                                valuesForOverride.contract
                            ),
                            (showOverrideModal = false)
                    "
                    color="success"
                    >Continue</mdb-btn
                >
                <mdb-btn @click.native="cancelOverride(valuesForOverride)" color="danger">Cancel</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </mdb-container>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";
import ProfileHistory from "@/components/common/ProfileHistory.vue";

export default {
    name: "objProfileRatesMarkup",
    components: { ProfileHistory },

    props: ["propSubClient", "propCarrier", "propAnalysis", "prop3plCarrier", "prop3plClient_id"],

    data() {
        return {
            tplHeadersRateMarkupAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Current Prospect Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Current Prospect Minimum", field: "minSubClient", align: "right", sortable: false },
                { label: "Prospect Markup %", field: "markup_pct", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Markup %",
                    field: "altMarkupSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersAccRateMarkupAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Prospect Markup %", field: "markup_pct", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Markup %",
                    field: "altMarkupSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "Current Prospect Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Current Prospect Minimum", field: "minSubClient", align: "right", sortable: false },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersDimInfoAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Proposed Prospect Divisor", field: "dimDvsrPropose", align: "right", sortable: false },
                { label: "3PL Divisor", field: "dimDvsrContract", align: "right", sortable: false },
                { label: "Proposed Prospect Threshold", field: "dimThshldPropose", align: "right", sortable: false },
                { label: "3PL Threshold", field: "dimThshldContract", align: "right", sortable: false },
            ],

            tplHeadersDimInfoProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Client Divisor", field: "dimDvsrPropose", align: "right", sortable: false },
                { label: "3PL Divisor", field: "dimDvsrContract", align: "right", sortable: false },
                { label: "Prospect Threshold", field: "dimThshldPropose", align: "right", sortable: false },
                { label: "3PL Threshold", field: "dimThshldContract", align: "right", sortable: false },
            ],

            tplHeadersRateMarkupProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Current Client Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Client Markup %", field: "markup_pct", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Markup %",
                    field: "altMarkupSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                // { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                // { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersAccRateMarkupProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Current Client Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Client Markup %", field: "markup_pct", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Markup %",
                    field: "altMarkupSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                // { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                // { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            configCurrencyDiscount: {
                currency: null,
                locale: "en",
                precision: 2,
                valueAsInteger: false,
                distractionFree: true,
                allowNegative: false,
            },

            configCurrencyMinimum: {
                currency: null,
                locale: "en",
                precision: 2,
                valueAsInteger: false,
                distractionFree: true,
                allowNegative: true,
            },

            rateHeaders: [],
            dimInfoHeaders: [],

            pagination: {
                page: 1,
                sortBy: ["low_weight", "high_weight", "cpg"],
                itemsPerPage: -1,
            },

            arrPageOptions: [5, 25, 50, { text: "ALL", value: -1 }],

            isLoading: false,
            getRates: false,

            hasError: false,
            errMsgArray: [],

            profileRates: null,
            profileDimInfo: [],

            profileCategories: null,
            selectedCategory: "",

            hasUpdateError: false,

            searchText: "",

            blnUpdateAll: false,
            txtAllMarkup: 0,

            blnShowDimInfo: false,
            txtAllDimDivisor: 0,
            txtAllDimThreshold: 0,
            showDiscountModal: false,
            loading: [],
            valuesForDiscount: null,

            overrideModalConfigs: {
                discount: {
                    title: "Discount Confirm?",
                    message: "The value entered is above your discount. Are you sure you want to continue?",
                },
                minimum: {
                    title: "Minimum Confirm?",
                    message: "The value entered is below your minimum. Are you sure you want to continue?",
                },
            },

            overrideModalItem: {},

            showOverrideModal: false,
            valuesForOverride: null,
            bulkUpdateErrors: false,
            discountComparisons: [],
            current_profile_header: null,
        };
    },

    computed: {
        subclient() {
            return this.propSubClient;
        },
        threeplCarrier() {
            return this.prop3plCarrier;
        },

        carrier() {
            return this.propCarrier;
        },
        categoryFormatted() {
            return this.selectedCategory ? this.formatName(this.selectedCategory) : "";
        },
        headersForTable() {
            let setHiddenHeaders = false;
            if (this.threeplCarrier || !this.propAnalysis) {
                setHiddenHeaders = true;
            }
            for (const header of this.rateHeaders) {
                if (header.field === "altMarkupSubClient") {
                    header.hidden = setHiddenHeaders;
                }
            }

            if (this.propAnalysis) {
                return this.blnShowDimInfo ? this.tplHeadersDimInfoAnalysis : this.rateHeaders;
            } else {
                return this.blnShowDimInfo ? this.tplHeadersDimInfoProfile : this.rateHeaders;
            }
        },
    },

    watch: {
        propAnalysis() {
            if (this.propAnalysis) {
                this.rateHeaders = this.tplHeadersRateMarkupAnalysis;
                this.dimInfoHeaders = this.tplHeadersDimInfoAnalysis;
            } else {
                this.rateHeaders = this.tplHeadersRateMarkupProfile;
                this.dimInfoHeaders = this.tplHeadersDimInfoProfile;
            }
        },
        subclient() {
            this.profileRates = [];
            this.selectedCategory = this.profileCategories[0];
            if (this.subclient && this.carrier && !this.isLoading) {
                this.getRates = true;
                this.getPMORates(this.subclient, this.carrier);
            }

            this.blnUpdateAll = false;
            this.txtAllMarkup = 0;
            this.txtAllDimDivisor = 0;
            this.txtAllDimThreshold = 0;
            this.searchText = "";
        },

        carrier() {
            this.profileRates = [];
            this.selectedCategory = this.profileCategories[0];

            if (this.subclient && this.carrier && !this.isLoading) {
                this.getRates = true;
                this.getPMORates(this.subclient, this.carrier);
            }

            this.blnUpdateAll = false;
            this.txtAllMarkup = 0;
            this.searchText = "";
        },
        prop3plCarrier() {
            this.profileRates = [];
            this.selectedCategory = this.profileCategories[0];

            this.getRates = true;
            this.getPMORates(this.subclient, this.carrier);

            this.blnUpdateAll = false;
            this.txtAllMarkup = 0;
            this.txtAllMinimum = 0;
            this.searchText = "";
        },
    },
    mounted() {
        this.$httpNew
            .get("profile-versions", { params: { client_id: this.propSubClient, carrier: this.propCarrier } })
            .then((response) => {
                for (let data of response.data) {
                    if (data.contract_enddate == null) {
                        this.current_profile_header = data.id;
                    }
                }
            });
    },
    methods: {
        formatMoney: function (_decInAmount) {
            return PMO_Global.formatMoney(_decInAmount);
        },

        async getCategories() {
            const categories = await this.$httpNew.get(`cpg-organization`);
            let categArr = categories.data.pkg;
            if (
                categArr.includes("GROUND RESIDENTIAL") &&
                categArr.includes("GROUND RESIDENTIAL (OTHERS)") &&
                categArr.includes("GROUND COMMERCIAL") &&
                categArr.includes("GROUND COMMERCIAL (OTHERS)") &&
                categArr.includes("INTERNATIONAL") &&
                categArr.includes("DOMESTIC AIR SERVICES") &&
                categArr.includes("POSTAL PACKAGES")
            ) {
                categArr = [
                    "GROUND RESIDENTIAL",
                    "GROUND RESIDENTIAL (OTHERS)",
                    "GROUND COMMERCIAL",
                    "GROUND COMMERCIAL (OTHERS)",
                    "POSTAL PACKAGES",
                    "DOMESTIC AIR SERVICES",
                    "INTERNATIONAL",
                ];
            }
            if (!this.propAnalysis) {
                this.getPMORates(this.subclient, this.carrier);
            }
            this.switchCategory(categArr[0]);
            // return [...categArr, "ACCESSORIALS", "DISCOUNT COMPARISONS"];
            return [...categArr, "ACCESSORIALS"];
        },

        switchCategory(p_objInCategory) {
            this.selectedCategory = p_objInCategory;
            // if (this.selectedEntity['is_markup'] == true) {
            //     if (this.propAnalysis) {
            //         if (this.selectedCategory === 'ACCESSORIALS') {
            //             this.rateHeaders = this.tplHeadersAccRateMarkupAnalysis
            //         }
            //         else {
            //             this.rateHeaders = this.tplHeadersRateMarkupAnalysis;
            //         }
            //         this.dimInfoHeaders = this.tplHeadersDimInfoAnalysis;
            //     } else {
            //         if (this.selectedCategory === 'ACCESSORIALS') {
            //             this.rateHeaders = this.tplHeadersAccRateMarkupProfile
            //         }
            //         else {
            //             this.rateHeaders = this.tplHeadersRateMarkupProfile
            //         }
            //         this.dimInfoHeaders = this.tplHeadersDimInfoProfile;
            //     }
            // }
            // else {
            if (this.propAnalysis) {
                if (this.selectedCategory === "ACCESSORIALS") {
                    this.rateHeaders = this.tplHeadersAccRateMarkupAnalysis;
                } else {
                    this.rateHeaders = this.tplHeadersRateMarkupAnalysis;
                }
                this.dimInfoHeaders = this.tplHeadersDimInfoAnalysis;
            } else {
                if (this.selectedCategory === "ACCESSORIALS") {
                    this.rateHeaders = this.tplHeadersAccRateMarkupProfile;
                } else {
                    this.rateHeaders = this.tplHeadersRateMarkupProfile;
                }
                this.dimInfoHeaders = this.tplHeadersDimInfoProfile;
            }
            // }

            this.blnUpdateAll = false;
            this.txtAllMarkup = 0;

            this.searchText = "";
        },
        formatName(val) {
            return val.replace(/ /g, "_");
        },
        async getPMORates(subclientId, carrier) {
            this.isLoading = true;
            this.profileRates = [];
            let contracts = [];
            let accContracts = [];
            if (this.propAnalysis && this.prop3plCarrier !== null) {
                const threeplCarrier = this.prop3plCarrier;
                const awaitRequests = [];
                awaitRequests.push(
                    this.$httpNew.get(
                        `contracts?client_id=${subclientId}&carrier=${carrier}&threeplCarrier=${threeplCarrier}`
                    )
                );
                awaitRequests.push(
                    this.$httpNew.get(
                        `contracts-acc?client_id=${subclientId}&carrier=${carrier}&threeplCarrier=${threeplCarrier}`
                    )
                );
                const results = await Promise.all(awaitRequests);
                contracts = results[0];
                accContracts = results[1];
            } else {
                const awaitRequests = [];
                awaitRequests.push(this.$httpNew.get(`contracts?client_id=${subclientId}&carrier=${carrier}`));
                awaitRequests.push(this.$httpNew.get(`contracts-acc?client_id=${subclientId}&carrier=${carrier}`));
                const results = await Promise.all(awaitRequests);
                contracts = results[0];
                accContracts = results[1];
            }
            this.profileRates = contracts.data;
            this.profileRates["ACCESSORIALS"] = accContracts.data;
            for (const key of Object.keys(this.profileRates)) {
                // this.profileRates[key] = this.profileRates[key].sort((a,b) => {
                //     return a.cpg.localeCompare(b.cpg)
                // })
                this.profileRates[key] = this.profileRates[key].sort((a, b) => {
                    if (a.cpg == b.cpg) {
                        return a.weight_break.localeCompare(b.weight_break);
                    } else {
                        return a.cpg.localeCompare(b.cpg);
                    }
                });
            }
            this.isLoading = false;
        },

        move(index, type, direction) {
            let objDest;
            const oppObject = type === "minimum" ? "discount" : "minimum";
            switch (direction) {
                case "up":
                    if (index - 1 < 0) {
                        return false;
                    }
                    objDest = this.$refs[`${type}_${index - 1}`];
                    objDest.focus();
                    break;
                case "down":
                    if (index + 1 >= this.profileRates[this.categoryFormatted].length) {
                        return false;
                    }
                    objDest = this.$refs[`${type}_${index + 1}`];
                    objDest.focus();
                    break;
                case "left":
                    if (index - 1 < 0 && oppObject === "minimum") {
                        return false;
                    }
                    objDest = this.$refs[`${oppObject}_${type === "discount" ? index - 1 : index}`];
                    objDest.focus();
                    break;
                case "right":
                    if (oppObject === "discount" && index + 1 >= this.profileRates[this.categoryFormatted].length) {
                        return false;
                    }
                    objDest = this.$refs[`${oppObject}_${type === "minimum" ? index + 1 : index}`];
                    objDest.focus();
                    break;
                default:
                    break;
            }
        },
        async updateSubClientCPGRate(item, type, index, contract) {
            let objDest;

            this.isLoading = true;
            if (Number(item[`${contract}`][`${type}`]) !== Number(item[`${contract}`][`${type}New`])) {
                if (typeof Number(item[`${contract}`][`${type}New`]) !== "number") {
                    item[`${contract}`][`${type}New`] = item[`${contract}`][`${type}`];
                    this.$notify.error({ message: "Not a valid number.", timeOut: 9000 });
                    objDest = this.$refs[`${type}_${index}`];
                    objDest.focus();
                    this.isLoading = false;
                    return false;
                }
                if (
                    !this.overrideApproved &&
                    "threepl" in item &&
                    Number(item[`${contract}`][`${type}New`]) < Number(item.threepl[`${type}`])
                ) {
                    this.overrideModalItem = this.overrideModalConfigs[type];
                    this.showOverrideModal = true;
                    this.valuesForOverride = {
                        type,
                        item,
                        index,
                        contract,
                    };
                    this.isLoading = false;
                    return false;
                }
                // else if (type === 'markup_pct') {
                //     try {
                //         await this.updateMarkup(item)
                //         this.$notify.success({ message: `Successfully updated ${type}` });
                //     } catch (e) {
                //         this.$notify.error({ message: e.message,timeOut:5000 });
                //     }
                // }
                else {
                    try {
                        await this.updateMarkup(item, contract);
                        this.$notify.success({ message: `Successfully updated ${type}`, timeOut: 9000 });
                        // eslint-disable-next-line require-atomic-updates
                        item.class = "";
                        // item.subclient[`${type}`] = item.subclient[`${type}New`];
                        this.showOverrideModal = false;
                        this.isLoading = false;
                        this.$forceUpdate();
                    } catch (e) {
                        this.isLoading = false;
                        this.$notify.error({ message: e.message, timeOut: 9000 });
                    }
                }
            }
            // I don't think we need to do a reload here
            // if it causes issues in the future, just uncomment this line
            // await this.getPMORates(this.subclient, this.carrier)
            this.overrideApproved = false;
            this.isLoading = false;
        },
        async updateMarkup(item, contract) {
            const data = {
                client_id: this.subclient,
                weight_break: item.weight_break,
                carrier_price_group: contract === "altContract" ? item["altContract"].carrier_price_group : item.cpg,
                carrier: contract === "altContract" ? item["altContract"].carrier : item.carrier,
                markup_pct: Number(item[`${contract}`].markup_pct),
                markup: true,
                profile_type: "SUBCLIENT",
                active: true,
                profile_header_id: item[`${contract}`].profile_header_id
                    ? item[`${contract}`].profile_header_id
                    : this.current_profile_header,
            };
            if (!item.weight_break) {
                const url = "contracts-acc";
                return await this.$httpNew.patch(url, data);
            } else {
                const url = "contracts";
                return await this.$httpNew.patch(url, data);
            }
        },
        async updateDivThres(item) {
            const data = {
                client_id: this.subclient,
                carrier_price_group: item.cpg,
                carrier: item.carrier,
                divisor: Number(item.subclient.divisorNew),
                threshold: Number(item.subclient.thresholdNew),
                profile_header_id: item.subclient.profile_header_id
                    ? item.subclient.profile_header_id
                    : this.current_profile_header,
            };
            const url = "dim-factors";
            return await this.$httpNew.patch(url, data);
        },
        cancelOverride(parmInValues) {
            if (parmInValues.type === "discount") {
                parmInValues.item.subclient.discountNew = parmInValues.item.subclient.discount;
            } else {
                parmInValues.item.subclient.minimumNew = parmInValues.item.subclient.minimum;
            }
            this.$refs[`${parmInValues.type}_${parmInValues.index}`].focus();

            this.showOverrideModal = false;
            this.isLoading = false;
        },
        async updateSubClientCPGDimInfo(item, type, index) {
            let error = "";
            let objDest;
            if (item.subclient[`${type}`] !== item.subclient[`${type}New`]) {
                if (!Number(item.subclient[`${type}New`])) {
                    item.subclient[`${type}New`] = item.subclient[`${type}`];
                    this.$notify.error({ message: "Not a valid number.", timeOut: 9000 });
                    objDest = this.$refs[`${type}_${index}`];
                    objDest.focus();
                    return false;
                }
                if (item.subclient[`${type}New`] > item.threepl[`${type}`]) {
                    this.hasUpdateError = true;
                    error = `The supplied dim ${type} cannot be greater than the existing 3PL dim ${type}`;
                    item.subclient[`${type}New`] = item.subclient[`${type}`];
                    objDest = this.$refs[`${type}_${index}`];
                    objDest.focus();
                    this.$notify.error({ message: error, timeOut: 9000 });
                } else {
                    try {
                        await this.updateDivThres(item);
                        this.$notify.success({ message: `Successfully updated ${type}`, timeOut: 9000 });
                        // eslint-disable-next-line require-atomic-updates
                        item.class = "";
                        item.subclient[`${type}`] = item.subclient[`${type}New`];
                        this.$forceUpdate();
                    } catch (e) {
                        this.$notify.error({ message: e.message, timeOut: 9000 });
                    }
                }
            }

            await this.getPMORates(this.subclient, this.carrier);
        },

        async updateBatchRates() {
            let data;
            let newRate;
            this.bulkUpdateErrors = false;
            this.isLoading = true;
            let logs = "";
            let failureLogs = "";
            let updateErrors = [];
            const items = this.$refs["dataFiltered"].filteredRows[0].children;
            let carriers = [this.carrier];
            if (this.threeplCarrier !== this.carrier) {
                carriers = [this.carrier, this.threeplCarrier];
            }
            for (const carrier of carriers) {
                for (const rate of items) {
                    data = {
                        client_id: this.subclient,
                        carrier_price_group:
                            carrier === this.carrier ? rate.cpg : rate["altContract"].carrier_price_group,
                        weight_break: rate.weight_break,
                        carrier: carrier,
                        profile_type: "SUBCLIENT",
                        active: true,
                        // TODO Remove hardcodes - should be pulled from the rate or not taken into account
                        // contract_startdate: "2019-01-01",
                        // contract_enddate: "2019-12-31",
                        is_markup: true,
                        markup_pct: Number(parseFloat(this.txtAllMarkup).toFixed(2)),
                        profile_header_id: rate.subclient.profile_header_id
                            ? rate.subclient.profile_header_id
                            : this.current_profile_header,
                    };

                    if (!rate.weight_break) {
                        try {
                            const url = "contracts-acc";
                            newRate = (await this.$httpNew.patch(url, data)).data;
                            rate.subclient.markup = newRate.markup;
                            rate.subclient.markup_pct = newRate.markup_pct;
                        } catch (e) {
                            // this.$notify.error({ message: e.message, timeOut: 9000 });
                        }
                    } else {
                        try {
                            const url = "contracts";

                            newRate = (await this.$httpNew.patch(url, data)).data;

                            rate.subclient.markup = newRate.markup;
                            rate.subclient.markup_pct = newRate.markup_pct;
                            rate.batchUpdated = true;
                            logs += `${rate.cpg} updated successfully.<br/>`;
                            rate.class = "green lighten-5";
                        } catch (e) {
                            // updateErrors.push({
                            //     error: e,
                            //     client_id: data.client_id,
                            //     cpg: data.carrier_price_group,
                            //     weight_break: rate.weight_break,
                            // });

                            // failureLogs += `Failed to update ${rate.cpg} (${rate.weight_break}).<br/>`;

                            // rate.class = "red lighten-5";
                            // this.bulkUpdateErrors = true;
                            // this.$notify.error({ message: e.message });
                        }
                    }
                }
            }

            // something's not right with the API errors right now... saving this for later
            // if (this.bulkUpdateErrors) {
            //     this.$notify.error({
            //         message:
            //             "One or more records could not be updated. <br/>" +
            //             failureLogs,timeOut:5000
            //     });
            // } else {
            this.$notify.success({
                message: `Successfully batch updated markup percentage for ${this.selectedCategory}`,
                timeOut: 9000,
            });
            // }
            await this.getPMORates(this.subclient, this.carrier);
            this.isLoading = false;
            this.txtAllMarkup = 0;
        },
        async update3plDiscount(value, new_discount) {
            let data = {};
            if (value && value.threepl) {
                data = value.threepl;
            } else {
                data = {
                    client_id: this.prop3plClient_id,
                    profile_type: "3PL CONTRACT",
                    carrier_price_group: value.cpg,
                    carrier: value.carrier,
                    weight_break: value.weight_break,
                    profile_header_id: null,
                    minimum: 0,
                    markup: true,
                    active: true,
                };
            }
            const value_discount = Number(new_discount.target.value);
            if (isNaN(value_discount)) {
                this.$notify.error({ message: "Not a valid number.", timeOut: 9000 });
            }
            if (data["markup_pct"] && data["markup_pct"][0] === value_discount) {
                return;
            }

            data["markup_pct"] = parseFloat(new_discount.target.value);
            if (!value.weight_break) {
                const url = "contracts-acc";
                return await this.$httpNew.patch(url, data);
            } else {
                const url = "contracts";
                return await this.$httpNew.patch(url, data);
            }
        },
        async updateBatchDimInfo() {
            let data;
            let newRate;
            this.isLoading = true;
            this.bulkUpdateErrors = false;
            let updateErrors = [];
            let logs = "";
            const items = this.$refs["dataFiltered"].$children[0].filteredItems;
            for (const rate of items) {
                data = {
                    client_id: this.subclient,
                    carrier_price_group: rate.cpg,
                    weight_break: rate.weight_break,
                    carrier: rate.carrier,
                    profile_type: "SUBCLIENT",
                    active: true,
                    // TODO Remove hardcodes - should be pulled from the rate or not taken into account
                    // contract_startdate: "2019-01-01",
                    // contract_enddate: "2019-12-31",
                    divisor:
                        this.txtAllDimDivisor &&
                        Number(this.txtAllDimDivisor) !== 0 &&
                        Number(this.txtAllDimDivisor) < rate.threepl.divisor
                            ? Number(parseFloat(this.txtAllDimDivisor).toFixed(2))
                            : rate.subclient.divisor,
                    threshold:
                        this.txtAllDimThreshold &&
                        Number(this.txtAllDimThreshold) !== 0 &&
                        Number(this.txtAllDimThreshold) < rate.threepl.threshold
                            ? Number(parseFloat(this.txtAllDimThreshold).toFixed(2))
                            : rate.subclient.threshold,
                    profile_header_id: rate.subclient.profile_header_id
                        ? rate.subclient.profile_header_id
                        : this.current_profile_header,
                };

                try {
                    const url = "dim-factors";
                    newRate = (await this.$httpNew.patch(url, data)).data;
                    rate.subclient.divisorNew = newRate.divisor;
                    rate.subclient.divisor = newRate.divisor;
                    rate.subclient.thresholdNew = newRate.threshold;
                    rate.subclient.threshold = newRate.threshold;
                } catch (e) {
                    updateErrors.push({
                        error: e,
                        client_id: client_id,
                        carrier_price_group: carrier_price_group,
                        weight_break: weight_break,
                    });
                    this.$notify.error({ message: e.message, timeOut: 9000 });
                }
                if (
                    Number(this.txtAllDimThreshold) > rate.threepl.threshold ||
                    Number(this.txtAllDimDivisor) > rate.threepl.divisor
                ) {
                    this.bulkUpdateErrors = true;
                    rate.class = "red lighten-5";
                } else {
                    logs += `${rate.cpg} updated successfully. <br/>`;
                    rate.class = "green lighten-5";
                }
            }
            if (this.bulkUpdateErrors) {
                this.$notify.error({
                    message:
                        "One or more records could not be updated. New amount cannot be greater than 3PL amount. <br/>" +
                        logs,
                    timeOut: 9000,
                });
            } else {
                this.$notify.success({ message: logs, timeOut: 9000 });
            }

            await this.getPMORates(this.subclient, this.carrier);
            this.txtAllDimDivisor = 0;
            this.txtAllDimThreshold = 0;
            this.isLoading = false;
        },
    },
    async created() {
        this.profileCategories = await this.getCategories();
        this.selectedCategory = this.profileCategories[0];
        await this.getPMORates(this.subclient, this.carrier);
    },
};
</script>

<style lang="sass">
table.v-table
  thead
    th
      white-space: normal
.nopadding
  padding: 0 !important
</style>

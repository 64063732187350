<template class="z-index: 2000">
    <div style="width: 100%" v-if="notifications && notifications.length > 0">
        <div v-for="notification in notifications" v-bind:key="notification.id">
            <mdb-alert
                :color="notification.type"
                v-if="notification.show"
                @closeAlert="notification.show = false"
                dismiss
            >
                <strong>{{ notification.title }}</strong> {{ notification.text }}
            </mdb-alert>
        </div>
    </div>
</template>

<script>
export default {
    name: "UserNotifications",
    props: {
        width: { default: "100%" },
    },
    data: function () {
        return {
            notifications: null,
        };
    },
    mounted() {
        this.getNotifications();
    },
    methods: {
        async getNotifications() {
            const notifications = await this.$httpNew.get("user-notifications");
            this.notifications = notifications
                ? notifications.data.map((c) => {
                      c.show = true;
                      return c;
                  })
                : [];
        },
    },
};
</script>

import { extend } from "vee-validate";
import { required, numeric, email } from "vee-validate/dist/rules";

extend("required", {
    ...required,
    message: "{_field_} is required",
});

extend("numeric", {
    ...numeric,
    message: "{_field_} must be numeric",
});

extend("email", {
    ...email,
    message: "Please enter a valid email address",
});

extend("min", {
    params: ["argMinLength"],
    validate: (value, { argMinLength }) => {
        return value.length >= argMinLength;
    },
    message: "{_field_} must be greater than {argMinLength} characters",
});

extend("clientExists", {
    params: ["argSubClientInfo"],
    validate: (value, { argSubClientInfo }) => {
        if (!argSubClientInfo.isUpdate) {
            const arrSubclients = argSubClientInfo.subClients.filter(
                (c) => c.external_id.toUpperCase().trim() == value.toUpperCase().trim()
            );

            return arrSubclients.length == 0;
        } else {
            return argSubClientInfo.isUpdate;
        }
    },
    message: "{_field_} already exists",
});

extend("passwordConfirm", {
    params: ["argConfirm"],
    validate(value, { argConfirm }) {
        return value === argConfirm;
    },
    message: "Password confirmation does not match",
});

export const rules = {
    numeric: {
        required: true,
        numeric: true,
    },

    email: {
        required: true,
        email: true,
    },

    clientExists: {
        custom: true,
    },
};

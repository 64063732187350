import store from "@/store";

export default {
    methods: {
        handleError(msg, splash = false) {
            if (splash) store.dispatch("setAlertMessage", { message: msg, type: "error" });
        },

        handleSuccess(msg, splash = false) {
            if (splash) store.dispatch("setAlertMessage", { message: msg, type: "success" });
        },

        handleWarn(msg, splash = false) {
            if (splash) {
                store.dispatch("setAlertMessage", { message: msg, type: "warning" });
            }
        },

        handleInfo(msg, splash = false, timeout = false) {
            if (splash) {
                //store.dispatch('setAlertMessage', {message: msg, type: 'info', persist: true})
                store.dispatch("setAlertMessage", { message: msg, type: "info", persist: timeout ? false : true });
            }
        },

        propertyComparator(property) {
            return function (x, y) {
                return x[property] === y[property] ? 0 : x[property] > y[property] ? 1 : -1;
            };
        },
    },
};

<template>
    <div class="m-4">
        <h3>Alerts</h3>
        <hr />
        <div class="d-flex">
            <mdb-dropdown v-if="subclients" class="flex-fill">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    selectedSubClient ? selectedSubClient.sub_client_name : "Select Client"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!subclients.length">Loading Clients</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="entity in subclients"
                        :key="entity.client_id"
                        :active="selectedSubClient && entity.sub_client_name === selectedSubClient.sub_client_name"
                        @click.native="changeSubClient(entity)"
                        >{{ entity.sub_client_name }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>

            <mdb-dropdown v-if="selectedCarrier" class="mx-2 flex-fill">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    isSelectedCarrier ? isSelectedCarrier.text : "Select Carrier"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!selectedCarrier.length">Loading Carriers</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="carrier in selectedCarrier"
                        :key="carrier.value"
                        :active="carrier.selected"
                        @click.native="changeCarrier(carrier)"
                        >{{ carrier.text }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-btn
                class="flex-fill my-0"
                outlined
                color="orange"
                @click.native="inputForm = true"
                :disabled="!selectedSubClient"
                v-if="isAdmin"
                >Add Alert</mdb-btn
            >
            <mdb-modal :show="inputForm" @close="inputForm = false" class="mt-10" size="lg">
                <mdb-modal-header>
                    <span class="headline"> {{ this.isEditing ? "Update" : "Add" }} Alerts </span>
                </mdb-modal-header>
                <mdb-modal-body>
                    <ValidationObserver ref="form" v-slot="{ invalid }">
                        <mdb-input type="text" v-model="form.behavior_name" placeholder="Name"></mdb-input>
                        <ValidationProvider name="Record Type" rules="required" v-slot="{ errors, valid }">
                            <mdb-select
                                class="my-2"
                                v-model="recordTypes"
                                label="Record Type"
                                @change="changeRecordType"
                                :error-messages="errors"
                                :success="valid"
                                filled
                            />
                        </ValidationProvider>

                        <ValidationProvider name="Carrier Price Group" rules="required" v-slot="{ errors, valid }">
                            <mdb-select
                                class="my-2"
                                v-model="filteredCpgs"
                                label="Carrier Price Group"
                                :error-messages="errors"
                                @change="changeCPG"
                                :success="valid"
                                filled
                            />
                        </ValidationProvider>

                        <ValidationProvider name="Zone" rules="required" v-slot="{ errors, valid }">
                            <mdb-select
                                class="my-2"
                                v-model="arrPMOAnomBhvrZones"
                                v-show="showZones"
                                label="Zone"
                                :error-messages="errors"
                                :success="valid"
                                filled
                            />
                        </ValidationProvider>

                        <ValidationProvider name="Column" rules="required" v-slot="{ errors, valid }">
                            <mdb-select
                                class="my-2"
                                v-model="filteredColumns"
                                label="Column"
                                :error-messages="errors"
                                :success="valid"
                                filled
                            />
                        </ValidationProvider>

                        <ValidationProvider name="Operator" rules="required" v-slot="{ errors, valid }">
                            <mdb-select
                                class="my-2"
                                v-model="allowedOperators"
                                label="Operator"
                                item-text="label"
                                item-value="value"
                                :error-messages="errors"
                                :success="valid"
                                filled
                            />
                        </ValidationProvider>

                        <ValidationProvider name="Value" rules="required|numeric" v-slot="{ errors, valid }">
                            <mdb-input
                                class="my-2"
                                type="text"
                                v-model="form.value"
                                label="Value"
                                :error-messages="errors"
                                :success="valid"
                                filled
                                numeric
                            />
                        </ValidationProvider>
                    </ValidationObserver>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="danger" text @click.native="cancel">Cancel</mdb-btn>
                    <mdb-btn color="success" text @click.native="addUpdate" :disabled="invalid" v-if="isEditing"
                        >Save</mdb-btn
                    >
                    <mdb-btn color="success" text @click.native="addAlert" :disabled="invalid" v-else>Add</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
        <div class="d-flex mt-4 w-100" v-if="arrAnomBhvrSubClient">
            <vue-good-table
                class="w-100"
                :columns="headers"
                :rows="filteredAlerts"
                :options.sync="pagination"
                :footer-props="{
                    'items-per-page-options': arrPageOptions,
                }"
                fixed-header
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'actions'">
                        <mdb-btn icon color="success" @click.native="update(props.row)" v-if="isAdmin"
                            ><mdb-icon icon="edit"></mdb-icon
                        ></mdb-btn>
                        <mdb-btn icon color="danger" @click.native="confirm(props.row)" v-if="isAdmin"
                            ><mdb-icon icon="trash"></mdb-icon
                        ></mdb-btn>
                    </span>
                    <span v-else class="upperTransform">
                        {{ props.row[props.column.field] }}
                    </span>
                </template>
            </vue-good-table>

            <mdb-modal :show="confirmation" max-width="25vw" class="mt-10">
                <mdb-modal-header>
                    <span class="headline"> Delete </span>
                </mdb-modal-header>
                <mdb-modal-body> Delete Alert? </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="danger" text @click.native="cancel">No</mdb-btn>
                    <mdb-btn color="success" text @click.native="remove">Yes</mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import PMO_Global from "@/assets/js/PMO_Global";

export const ACTION_CREATE = 1;
export const ACTION_UPDATE = 2;
export const ACTION_DELETE = 3;

export const API_POST_DATA = {
    client_id: "string",
    carrier: "string",
    carrier_price_group: "string",
    pack_or_acc: "string",
    behavior_name: "string",
    variable: "string",
    greater_or_less_than: "string",
    value: 0,
    zone: "string",
    delete: true,
};

export default {
    name: "Alerts",
    components: {
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
    },
    data() {
        return {
            actionCreate: ACTION_CREATE,
            actionUpdate: ACTION_UPDATE,
            actionDelete: ACTION_DELETE,
            invalid: false,
            headers: [
                { label: "Record Type", field: "pack_or_acc", sortable: true },
                { label: "Carrier Price Group", field: "carrier_price_group", sortable: true },
                { label: "Zone", field: "zone", sortable: true },
                { label: "Column", field: "variable", sortable: true },
                { label: "Operator", field: "greater_or_less_than", sortable: true },
                { label: "Value", field: "value", sortable: false },
                { label: "", field: "actions", sortable: false },
            ],

            pagination: {
                itemsPerPage: -1,
                page: 1,
                sortBy: ["carrier_price_group"],
            },

            arrPageOptions: [10, 15, 25, { text: "All", value: -1 }],

            inputForm: false,
            confirmation: false,
            isEditing: false,

            form: {
                pack_or_acc: "",
                carrier_price_group: "",
                zone: "",
                variable: "",
                greater_or_less_than: "",
                value: "",
                subclient_id: "",
                behavior_name: "",
            },

            promptDelete: {},

            carriers: [],

            arrAnomBhvrSubClient: null,

            arrPMOAnomBhvrZones: [],
            arrPMOAnomBhvrCPGs: null,

            selectedSubClient: null,

            //selectedZone: null,
            selectedCarrier: null,
            selectedCpg: null,
            updatingRow: null,

            maxID: -1,
            packageColumnNames: [
                { text: "Rated Weight", value: "rated_weight" },
                { text: "Actual Weight", value: "actual_weight" },
                { text: "Net Charge", value: "actual_net_charge" },
                { text: "Gross Charge", value: "actual_gross_charge" },
                { text: "Volume", value: "volume" },
                { text: "Any Dimension", value: "any_dimension" },
            ],
            accColumnNames: [
                { text: "Net Charge", value: "accessorial_actual_net_charge" },
                { text: "Gross Charge", value: "accessorial_actual_gross_charge" },
            ],
            recordTypes: [
                { text: "Package", value: "package" },
                { text: "Accessorial", value: "accessorial" },
            ],
            allowedOperators: [
                { text: "greater", value: "greater" },
                { text: "less_than", value: "less_than" },
                { text: "equals", value: "equals" },
                { text: "greater_or_equal", value: "greater_or_equal" },
                { text: "less_or_equal", value: "less_or_equal" },
            ],
        };
    },

    watch: {
        recordTypes() {
            this.getCpgs();
        },
    },

    computed: {
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
        subclients() {
            return this.clients;
        },
        filteredColumns() {
            return this.selectedRecordType.value === "package" ? this.packageColumnNames : this.accColumnNames;
        },
        selectedColumn() {
            const record = this.filteredColumns.find((c) => c.selected);
            return record ? record : null;
        },
        filteredAlerts() {
            const selectedCarrier = this.isSelectedCarrier ? this.isSelectedCarrier.value : "";
            return this.arrAnomBhvrSubClient
                ? this.arrAnomBhvrSubClient.filter((c) => {
                      if (c.carrier === selectedCarrier) {
                          return c;
                      }
                  })
                : null;
        },
        selectedOperator() {
            const record = this.allowedOperators.find((c) => c.selected);
            return record ? record : null;
        },
        selectedRecordType() {
            const record = this.recordTypes.find((c) => c.selected);
            return record ? record : null;
        },
        selectedCpg() {
            const cpg = this.filteredCpgs.find((c) => c.selected);
            return cpg ? cpg : null;
        },
        selectedZone() {
            const zone = this.arrPMOAnomBhvrZones.find((c) => c.selected);
            return zone ? zone : null;
        },
        isSelectedCarrier() {
            const carrier = this.selectedCarrier.find((c) => c.selected);
            return carrier ? carrier : null;
        },
        showZones() {
            return this.arrPMOAnomBhvrZones.length > 0;
        },
        filteredCpgs() {
            if (this.selectedRecordType) {
                if (this.selectedRecordType.value === "package") {
                    return this.arrPMOAnomBhvrCPGs.package.map((c) => {
                        if (this.updatingRow) {
                            if (c.carrier_price_group === this.updatingRow.carrier_price_group.toUpperCase()) {
                                c.selected = true;
                            }
                        }
                        c.value = c.carrier_price_group;
                        c.text = c.carrier_price_group;
                        return c;
                    });
                } else {
                    return this.arrPMOAnomBhvrCPGs.accessorial.map((c) => {
                        if (this.updatingRow) {
                            if (c.carrier_price_group === this.updatingRow.carrier_price_group.toUpperCase()) {
                                c.selected = true;
                            }
                        }
                        c.value = c.carrier_price_group;
                        c.text = c.carrier_price_group;
                        return c;
                    });
                }
            } else
                return this.arrPMOAnomBhvrCPGs
                    ? this.arrPMOAnomBhvrCPGs.package.map((c) => {
                          return {
                              value: c.carrier_price_group,
                              text: c.carrier_price_group,
                          };
                      })
                    : null;
        },
    },

    methods: {
        changeCarrier(carrier) {
            this.selectedCarrier = this.selectedCarrier.map((c) => {
                if (c.value === carrier.value) {
                    c.selected = true;
                }
                return c;
            });
        },
        async getCpgs() {
            const cpgs = await this.$httpNew.get(`cpgs?carrier=${this.isSelectedCarrier.value}`);
            this.arrPMOAnomBhvrCPGs = cpgs.data;
        },
        async getAlerts() {
            const alerts = await this.$httpNew.get(`alerts?subclient_id=${this.selectedSubClient.client_id}`);
            if (alerts && alerts.data) {
                this.arrAnomBhvrSubClient = alerts.data;
                await this.getCpgs();
            }
        },
        async changeSubClient(subclient) {
            this.arrAnomBhvrSubClient = [];
            this.selectedSubClient = subclient;
            if (typeof subclient.carrier !== "string") {
                this.selectedCarrier = subclient.carrier.map((c) => {
                    c.text = c;
                    c.value = c;
                    return c;
                });
            } else {
                this.selectedCarrier = [
                    {
                        text: subclient.carrier,
                        value: subclient.carrier,
                    },
                ];
            }
            this.selectedCarrier[0].selected = true;
            await this.getAlerts();
        },

        changeRecordType() {
            if (this.selectedRecordType) {
                this.form.pack_or_acc = this.selectedRecordType.value;
                this.arrPMOAnomBhvrZones.length = 0;
            }
        },

        async changeCPG() {
            this.selectedCpg = this.filteredCpgs.find((c) => c.selected);
            try {
                this.arrPMOAnomBhvrZones.length = 0;
                let apiResponse = await this.$httpNew.get(
                    `zones?carrier=${this.isSelectedCarrier.value}&carrier_price_group=${this.selectedCpg.value}`
                );
                if (apiResponse) {
                    this.arrPMOAnomBhvrZones = apiResponse.data.map((c) => {
                        if (this.updatingRow && this.updatingRow.zone === c.zone) {
                            c.selected = true;
                        }
                        c.text = c.zone;
                        c.value = c.zone;
                        return c;
                    });
                    this.arrPMOAnomBhvrZones.unshift({ text: "ANY", value: "ANY", zone: "ANY" });
                    if (!this.arrPMOAnomBhvrZones.find((c) => c.selected)) this.arrPMOAnomBhvrZones[0].selected = true;
                }
            } catch (err) {
                this.arrPMOAnomBhvrZones.length = 0;
            }
        },

        update(row) {
            this.updatingRow = row;
            this.form.value = row.value;
            this.form.behavior_name = row.behavior_name;
            this.recordTypes = this.recordTypes.map((c) => {
                if (c.value.toUpperCase() === row.pack_or_acc) {
                    c.selected = true;
                }
                return c;
            });
            if (row.pack_or_acc.toLowerCase() === "package") {
                this.packageColumnNames.map((c) => {
                    if (c.value.toUpperCase() === row.variable) {
                        c.selected = true;
                    }
                    return c;
                });
            } else {
                this.accColumnNames.map((c) => {
                    if (c.value.toUpperCase() === row.variable) {
                        c.selected = true;
                    }
                    return c;
                });
            }
            this.allowedOperators = this.allowedOperators.map((c) => {
                if (row.greater_or_less_than.toLowerCase() === c.value.toLowerCase()) {
                    c.selected = true;
                }
                return c;
            });

            this.isEditing = true;
            this.inputForm = true;
        },

        confirm(p_objInAnomBhvr) {
            this.promptDelete = p_objInAnomBhvr;
            this.confirmation = true;
        },
        async addAlert() {
            this.form = {
                pack_or_acc: this.selectedRecordType.value.toUpperCase(),
                carrier_price_group: this.selectedCpg.value.toUpperCase(),
                zone: this.selectedZone ? this.selectedZone.value : null,
                variable: this.selectedColumn.value.toUpperCase(),
                greater_or_less_than: this.selectedOperator.value.toUpperCase(),
                value: this.form.value,
                subclient_id: this.selectedSubClient.client_id,
                behavior_name: this.form.behavior_name,
                carrier: this.selectedCarrier[0].value,
            };
            try {
                const added = await this.$httpNew.post(`alerts`, this.form);
                if (added) {
                    this.$notify.success({ message: "Alert was successfully added" });
                    this.getAlerts();
                }
            } catch (e) {
                this.$notify.error({ message: "Alert could not be added", timeOut: 9000 });
            }

            this.closeForm();
        },

        async addUpdate() {
            this.form = {
                pack_or_acc: this.selectedRecordType.value.toUpperCase(),
                carrier_price_group: this.selectedCpg.value.toUpperCase(),
                zone: this.selectedZone ? this.selectedZone.value : this.updatingRow.zone,
                variable: this.selectedColumn.value.toUpperCase(),
                greater_or_less_than: this.selectedOperator.value.toUpperCase(),
                value: this.form.value,
                subclient_id: this.updatingRow.subclient_id,
                behavior_name: this.form.behavior_name,
                carrier: this.updatingRow.carrier,
            };
            try {
                const updated = await this.$httpNew.patch(`alerts/${this.updatingRow.id}`, this.form);
                if (updated) {
                    this.updatingRow = null;
                    this.isEditing = false;
                    this.$notify.success({ message: "Alert was successfully updated", timeOut: 9000 });
                    this.getAlerts();
                }
            } catch (e) {
                this.$notify.error({ message: "Alert could not be updated", timeOut: 9000 });
            }

            this.closeForm();
        },

        cancel() {
            this.closeForm();
        },

        async remove() {
            try {
                await this.$httpNew.delete(`alerts/${this.promptDelete.id}`);
            } catch (e) {
                this.$notify.error({ message: "Could not delete alert", timeOut: 9000 });
            }
            this.confirmation = false;
            await this.getAlerts();
        },

        closeForm() {
            this.$refs.form.reset();

            this.form = {
                record_type: "",
                cpg: "",
                zone: "",
                variable: "",
                operator: "",
                value: "",
            };

            //this.selectedZone = null;
            this.confirmation = false;
            this.isEditing = false;

            this.inputForm = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.upperTransform {
    text-transform: uppercase;
}
</style>

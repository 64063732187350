<template>
    <div id="app">
        <mdb-container fill-height>
            <mdb-row class="justify-content-md-center">
                <mdb-col md="4" class="p-4">
                    <img :src="require('@/assets/TI-Suite.png')" max-width="400px" class="mb-4 img-thumbnail" />

                    <cognito-sign-in />
                </mdb-col>
            </mdb-row>
        </mdb-container>
    </div>
</template>

<script>
import SignIn from "@/components/cognito/SignIn";

export default {
    name: "login",
    components: {
        "cognito-sign-in": SignIn,
    },
};
</script>

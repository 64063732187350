import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Axios from "axios";
import JsonCSV from "vue-json-csv";
import VueShepherd from "vue-shepherd";
import "shepherd.js/dist/css/shepherd.css";
import * as mdbvue from "mdbvue";
import "bootstrap-css-only/css/bootstrap.min.css";
import "mdbvue/lib/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import VueGoodTablePlugin from "vue-good-table";
import Datepicker from "vuejs-datepicker";
// import the styles
import "vue-good-table/dist/vue-good-table.css";
import "@/styles/main.scss";
import "@/styles/_custom.scss";
import moment from "moment";

import store from "./store";

// https://github.com/aws-amplify/amplify-js/tree/master/packages/aws-amplify
// https://github.com/aws-amplify/amplify-js/tree/master/packages/aws-amplify-vue
import Amplify, * as AmplifyModules from "aws-amplify";
import { Auth } from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import aws_exports from "./aws-exports";
Amplify.configure(aws_exports);
Vue.use(AmplifyPlugin, AmplifyModules);

Vue.use(VueGoodTablePlugin);

import Notify from "mdbvue/lib/components/Notify";
Vue.use(Notify);

// Styles
import "@/styles/_custom.scss";
// import config from "./config";

const axios = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_ROOT_API,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_APIKey + ":" + " "),
    },
});

const axiosApi = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_APIBaseURL,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Basic " + window.btoa(process.env.VUE_APP_APIKey + ":" + " "),
    },
});

const axiosNew = Axios.create({
    // eslint-disable-next-line no-undef
    baseURL: process.env.VUE_APP_API_TS,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

axios.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                await Auth.signOut();
                window.location = "/login";
            }
        }
    }
);

axiosNew.interceptors.response.use(
    function (response) {
        return response;
    },
    async function (error) {
        // handle error
        if (error.response) {
            if (error.response.status === 401) {
                await Auth.signOut();
                window.location = "/login";
            }
        }
    }
);

for (const component in mdbvue) {
    Vue.component(component, mdbvue[component]);
}
Vue.prototype.$http = axios;
Vue.prototype.$httpApi = axiosApi;
Vue.prototype.$httpNew = axiosNew;

Vue.filter("money", function (value) {
    if (typeof value !== "number") {
        return value;
    }
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
    });
    return formatter.format(value);
});

Vue.filter("date", function (value) {
    const formatted = moment(value).utc().format("YYYY-MM-DD");
    return formatted;
});

Vue.component("downloadCsv", JsonCSV);
Vue.component("vuejs-datepicker", Datepicker);

Vue.config.productionTip = false;
Vue.use(VueShepherd);

/********************
 Mixins
 /********************/
//import pmoMixins from '@pmo/mixins/common'
//Vue.mixin(pmoMixins)

import common from "@/mixins/common";
import { createPinia, PiniaVuePlugin } from "pinia";
import { useTSWebsocketStore } from "@/store/pinia";
Vue.mixin(common);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();
// Vuex is been deprecated for Pinia.
new Vue({
    router,
    store,
    pinia,
    render: (h) => h(App),
}).$mount("#app");

useTSWebsocketStore().disconnect();

<template>
    <div class="m-4">
        <h3>Locations</h3>
        <div class="w-100">
            <mdb-btn outlined color="orange" @click.native="inputForm = true" v-if="isTI">Add Location</mdb-btn>
        </div>

        <mdb-modal :show="inputForm" persistent max-width="50vw" class="mt-10" @close="inputForm = false">
            <mdb-modal-header>
                <span class="headline"> Add Location </span>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-container grid-list-md>
                    <mdb-row>
                        <mdb-col col="4">
                            <mdb-input
                                type="text"
                                v-model="form.zipcode"
                                @keydown.enter.native="zipLookup"
                                label="Zip Code"
                                maxlength="5"
                                filled
                            />
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="danger" text @click.native="cancel">Cancel</mdb-btn>
                <mdb-btn color="success" text @click.native="add">Add</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>

        <vue-good-table
            class="w-100"
            :columns="headers"
            :rows="locationsComputed"
            :options.sync="pagination"
            :footer-props="{
                'items-per-page-options': arrPageOptions,
            }"
            :search-options="{
                enabled: true
            }"
            item-key="zipcode"
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field === 'zipcode'">
                    <b>{{ props.row.zipcode }}</b>
                </span>
                <span v-if="props.column.field === 'city'">{{ props.row.city }}</span>
                <span v-if="props.column.field === 'state'">{{ props.row.state }}</span>
                <span v-if="props.column.field === 'actions'">
                    <mdb-btn icon color="danger" @click.native.stop="confirm(props.row)" v-if="isTI" size="sm"
                        ><mdb-icon icon="trash"></mdb-icon
                    ></mdb-btn>
                </span>
            </template>
        </vue-good-table>

        <mdb-modal :show="confirmation" max-width="25vw" class="mt-10" @close="confirmation = false">
            <mdb-modal-header>Delete?</mdb-modal-header>
            <mdb-modal-body v-if="selectedLocation">
                <span class="headline">
                    Delete {{ selectedLocation.address.city
                    }}<span v-if="selectedLocation.address.state">, {{ selectedLocation.address.state }}</span
                    >?
                </span>
            </mdb-modal-body>
            <mdb-modal-footer>
                <mdb-btn color="danger" text @click.native="cancel">No</mdb-btn>
                <mdb-btn color="success" text @click.native="remove">Yes</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";

export const ACTION_CREATE = 1;
export const ACTION_DELETE = 2;
export const API_POST_DATA = {
    zipcode: "",
    master_client_id: "",
    delete: false,
};

export default {
    name: "Locations",

    data() {
        return {
            actionCreate: ACTION_CREATE,
            actionDelete: ACTION_DELETE,

            headers: [
                { label: "Zip Code", field: "zipcode", sortable: false, class: "grey lighten-4" },
                { label: "City", field: "city", sortable: false, class: "grey lighten-4" },
                { label: "State", field: "state", sortable: false, class: "grey lighten-4" },
                { label: "", field: "actions", sortable: false, class: "grey lighten-4" },
            ],

            pagination: {
                itemsPerPage: -1,
                page: 1,
                sortBy: ["zipcode"],
            },

            arrPageOptions: [10, 15, 25, { text: "All", value: -1 }],

            inputForm: false,
            form: { zipcode: "", city: "", state: "" },

            confirmation: false,

            strResponse: "",

            selectedLocation: null,

        };
    },

    created: function () {
        this.searchText = "";
    },
    props: {
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
        getLocations: Function,
    },

    computed: {
        isTI() {
            return this.$store.getters.userDisplayName.includes('@transimpact.com')
        },
        locationsComputed() {
            return this.locations;
        },
    },

    methods: {
        async add() {
            const response = await this.zipLookup();
            if(!response) {
                return false;
            }
            try {
                await this.$httpNew.post(`locations`, { zipcode: this.form.zipcode });
                this.$notify.success({ message: "Zipcode successfully added.", timeOut: 9000 });
            } catch (e) {
                this.$notify.error({ message: "Zipcode could not be added.", timeOut: 9000 });
            }

            this.form = { zipcode: "", city: "", state: "" };
            this.inputForm = false;
            this.getLocations();
        },

        async remove() {
            try {
                await this.$httpNew.delete(`locations/${this.selectedLocation.zipcode}`);
                this.$notify.success({ message: "Zipcode successfully deleted.", timeOut: 9000 });
            } catch (e) {
                this.$notify.error({ message: "Zipcode could not be deleted.", timeOut: 9000 });
            }
            this.confirmation = false;
            this.getLocations();
        },

        confirm(location) {
            this.selectedLocation = location;
            this.confirmation = true;
        },

        cancel() {
            this.form = { zipcode: "", city: "", state: "" };
            this.inputForm = false;

            this.selectedLocation = null;
            this.confirmation = false;
        },

        async zipLookup() {
            if (!this.form.zipcode || this.form.zipcode.length < 5) {
                return;
            }

            for(const zip of this.locations) {
                if(zip.zipcode === this.form.zipcode) {
                    this.$notify.error({ message: `${this.form.zipcode} - Zipcode already exists.`, timeOut: 9000 });
                    this.form.zipcode = null;
                    return null;
                }
            }

            try {
                const apiResponse = await this.$httpNew.get(`zipcode-lookup/${this.form.zipcode}`);
                if(apiResponse && apiResponse.data) {
                    this.form.city = apiResponse.data.city;
                    this.form.state = apiResponse.data.state;
                    return apiResponse.data;
                }
                else {
                    this.$notify.error({ message: "Could not find data for zipcode entered", timeOut: 9000 });
                    this.form.zipcode = null;
                    return null;
                }
            } catch (err) {
                this.$notify.error({ message: "Could not find data for zipcode entered", timeOut: 9000 });
                this.form.zipcode = null;
                return null;
            }
        },

        showStatusResponse: function (p_strInResponse) {
            this.strResponse = p_strInResponse;

            this.showSBLocations = true;
        },
    },
};
</script>

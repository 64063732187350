<template>
    <div class="p-4 h-100">
        <h3>Analysis</h3>
        <hr />
        <mdb-modal
            side
            size="lg"
            position="right"
            fullHeight
            direction="right"
            class="mt-10"
            scrollable
            :show="displaySummary"
            @close="displaySummary = false"
        >
            <mdb-modal-header>
                <mdb-modal-title>Savings &amp; Profit Summary</mdb-modal-title>
            </mdb-modal-header>
            <mdb-modal-body>
                <mdb-row class="w-100" v-if="isLoading">
                    <div class="w-100 text-center">
                        <mdb-spinner multicolor></mdb-spinner>
                        Loading Summary Data...
                    </div>
                </mdb-row>
                <div></div>
                <mdb-tbl>
                    <mdb-tbl-head>
                        <tr>
                            <th class="font-weight-bold" v-for="header in mappedLocations" :key="header.zip">
                                {{ header.label }}
                            </th>
                        </tr>
                    </mdb-tbl-head>

                    <mdb-tbl-body>
                        <tr v-for="summ in summaryTableHeaders" :key="summ.field">
                            <td>{{ summ.label }}</td>
                            <td
                                v-for="(item, key) in analysisSavings"
                                :class="{
                                    selected: summ.highlight,
                                    'font-weight-bold': summ.highlight,
                                    'text-success': summ.highlight && item[`${summ.field}`] > 0,
                                    'text-danger': summ.highlight && item[`${summ.field}`] < 0,
                                }"
                            >
                                <span v-if="!summ.stringFormat"
                                    >{{ formatValue(summ.type, item[`${summ.field}`]) }} {{ summ.suffix }}</span
                                >
                                <span v-else>{{ item[`${summ.field}`] }}</span>
                            </td>
                        </tr>
                    </mdb-tbl-body>
                </mdb-tbl>
                <mdb-btn outlined color="orange" :disabled="isLoading" @click.native="getAnalysisDetailRpt()"
                    >Download Analysis Detail Report</mdb-btn
                >
            </mdb-modal-body>
        </mdb-modal>
        <div class="d-flex">
            <div class="flex-fill">
                <mdb-btn outlined color="orange" @click.native="showSubClientForm = true"> ADD NEW PROSPECT </mdb-btn>
                <mdb-modal
                    :show="showSubClientForm"
                    persistent
                    class="mt-10"
                    @close="showSubClientForm = false"
                    scrollable
                >
                    <mdb-modal-header>
                        <span class="headline">
                            <span>Add New Prospect</span>
                        </span>
                    </mdb-modal-header>
                    <SubClientMaint
                        :defaultToProspect="true"
                        :listSubclient="entityList"
                        :subclient="{ account_type: 'prospect' }"
                        :prospectOnly="true"
                        :discardForm="closeSubClientForm"
                    />
                </mdb-modal>
            </div>
            <FileUpload
                class="flex-fill"
                :disabled="!selectedEntity || !selectedCarrier"
                :entityID="selectedEntity ? selectedEntity.client_id : null"
                :carrier="selectedCarrier ? selectedCarrier.value : null"
                v-model="isImporting"
            />
            <div class="d-flex flex-fill">
                <div v-if="loadingCarrierData" class="flex-fill">
                    <mdb-progress indeterminate color="orange"></mdb-progress> Loading Carrier Data Summary...
                </div>
                <mdb-card class="w-100 d-flex flex-column align-items-center" v-if="summaryUpload">
                    <mdb-card-header class="w-100">
                        <h4>Carrier Data Upload Summary</h4>
                    </mdb-card-header>
                    <mdb-card-body
                        class="w-100"
                        v-if="
                            (this.summaryUpload.startDate === 'Invalid date') |
                                (this.summaryUpload.packages.count === 0)
                        "
                        ><p>No data has been uploaded yet for this prospect.</p></mdb-card-body
                    >
                    <mdb-card-body class="w-100" v-else>
                        <div>
                            <div d-flex pl-2>
                                <div class="flex-fill">
                                    <mdb-row>
                                        <mdb-col col="3">
                                            <span style="font-weight: bold">Shipments</span>
                                        </mdb-col>
                                        <mdb-col col="5" class="text-lg-right">
                                            <span>
                                                {{ summaryUpload.packages.startdate | date }} -
                                                {{ summaryUpload.packages.enddate | date }}
                                            </span>
                                            <span class="font-weight-bold d-block">
                                                {{
                                                    summaryUpload.shipmentWeeks !== 0 ? summaryUpload.shipmentWeeks : 1
                                                }}
                                                {{ summaryUpload.shipmentWeeks >= 2 ? "Weeks" : "Week" }}
                                            </span>
                                        </mdb-col>
                                        <mdb-col col="4" class="text-lg-right">
                                            <span style="font-weight: bold">{{
                                                Number(summaryUpload.packages.count).toLocaleString()
                                            }}</span>
                                            Pkgs
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row>
                                        <mdb-col col="3">
                                            <span style="font-weight: bold">Total Charges (Net/Gross)</span>
                                        </mdb-col>
                                        <mdb-col col="5" class="text-lg-right">
                                            ${{
                                                (
                                                    Number(summaryUpload.packages.netcharge) +
                                                    Number(summaryUpload.accessorials.netcharge)
                                                ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                            }}
                                            / ${{
                                                (
                                                    Number(summaryUpload.packages.grosscharge) +
                                                    Number(summaryUpload.accessorials.grosscharge)
                                                ).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                            }}
                                        </mdb-col>
                                        <mdb-col col="4" class="text-lg-right">
                                            <span @click="toggleExpandIcon()" class="cursor-pointer">
                                                <mdb-icon class="cursor toggle-down" :icon="expandIcon"></mdb-icon>
                                            </span>
                                        </mdb-col>
                                    </mdb-row>
                                    <mdb-row v-if="expandSummary">
                                        <mdb-col col="6">
                                            <span style="font-weight: bold">Packages:&nbsp;</span>
                                            ${{
                                                Number(summaryUpload.packages.netcharge).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                            }}
                                            / ${{
                                                Number(summaryUpload.packages.grosscharge).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                            }}
                                        </mdb-col>
                                        <mdb-col col="6">
                                            <span style="font-weight: bold">Accessorials:&nbsp;</span>
                                            ${{
                                                Number(summaryUpload.accessorials.netcharge).toLocaleString(undefined, {
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                })
                                            }}
                                            / ${{
                                                Number(summaryUpload.accessorials.grosscharge).toLocaleString(
                                                    undefined,
                                                    { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                                                )
                                            }}
                                        </mdb-col>
                                    </mdb-row>
                                </div>
                            </div>
                        </div>
                    </mdb-card-body>
                </mdb-card>
            </div>
        </div>

        <mdb-row class="w-100 d-flex mt-4 mx-1 align-conter-center">
            <mdb-dropdown v-if="entityList" class="flex-fill">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedEntity ? selectedEntity.sub_client_name : "Select Prospect"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="force-scroll">
                    <mdb-dropdown-item v-if="!entityList.length"
                        >No prospects found for this customer.</mdb-dropdown-item
                    >
                    <mdb-dropdown-item
                        v-for="entity in entityList"
                        :key="entity.sub_client_name"
                        :active="selectedEntity && entity.sub_client_name === selectedEntity.sub_client_name"
                        @click.native="changeEntity(entity)"
                        >{{ entity.sub_client_name }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-dropdown v-if="carriers" class="flex-fill mx-2">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedCarrier ? selectedCarrier.text : "Select Carrier"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange">
                    <mdb-dropdown-item v-if="!carriers.length">Loading Carriers</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="carrier in carriers"
                        :key="carrier.value"
                        :active="selectedCarrier && carrier.value === selectedCarrier.value"
                        @click.native="changeCarrier(carrier)"
                        >{{ carrier.text }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-dropdown
                v-if="selectedCarrier && threeplCarriers && threeplCarriers.length > 0"
                class="flex-fill mx-2"
            >
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedThreePLCarrier ? `3Pl Carrier: ${selectedThreePLCarrier}` : "Select 3PL Carrier"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange">
                    <mdb-dropdown-item v-if="!threeplCarriers">Loading Carriers</mdb-dropdown-item>
                    <mdb-dropdown-item @click.native="changeThreePLCarrier(null)"
                        >De-select 3PL Carrier</mdb-dropdown-item
                    >
                    <mdb-dropdown-item
                        v-for="carrier in threeplCarriers"
                        :key="carrier"
                        :active="selectedThreePLCarrier && carrier === selectedThreePLCarrier"
                        @click.native="changeThreePLCarrier(carrier)"
                        >{{ carrier }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>

            <mdb-dropdown style="min-width: 400px" multiLevel class="flex-fill">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedLocations && selectedLocations.length ? selectedLocationsAsText : "Select Locations"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu class="force-scroll" color="orange">
                    <form>
                        <mdb-dropdown-item v-for="location in locations" :key="location.zipcode" class="d-flex">
                            <mdb-input
                                type="checkbox"
                                :name="'cb' + location.zipcode"
                                :id="'cb' + location.zipcode"
                                v-model="location.selected"
                                @change="selectLocs"
                                color="success"
                                :disabled="!location.selected && selectedLocations.length > 2"
                            />
                            <span>{{ location.address.city }} - {{ location.zipcode }}</span>
                        </mdb-dropdown-item>
                    </form>
                </mdb-dropdown-menu>
            </mdb-dropdown>

            <mdb-btn
                outlined
                color="orange"
                class="flex-fill my-0"
                @click.native="showSummaryResults()"
                :disabled="!allowSummary"
            >
                <span v-if="!allowSummary && loadingCarrierData"> Loading Summary Data... </span>
                <span v-else> SHOW SUMMARY RESULTS </span>
            </mdb-btn>
        </mdb-row>
        <div class="d-flex mt-4">
            <div class="flex-fill" v-if="!selectedEntity || (selectedEntity && selectedEntity.is_markup !== true)">
                <ProfileRates
                    v-bind:propSubClient="validSelectedEntity"
                    v-bind:propCarrier="selectedCarrier ? selectedCarrier.value : null"
                    v-bind:propAnalysis="true"
                    v-bind:prop3plCarrier="selectedThreePLCarrier ? selectedThreePLCarrier : null"
                    v-bind:prop3plClient_id="
                        validSelectedEntity_client_obj ? validSelectedEntity_client_obj.master_client_id : null
                    "
                />
            </div>
            <div class="flex-fill" v-else>
                <ProfileRatesMarkup
                    v-bind:propSubClient="validSelectedEntity"
                    v-bind:propCarrier="selectedCarrier ? selectedCarrier.value : null"
                    v-bind:propAnalysis="true"
                    v-bind:prop3plCarrier="selectedThreePLCarrier ? selectedThreePLCarrier : null"
                    v-bind:prop3plClient_id="
                        validSelectedEntity_client_obj ? validSelectedEntity_client_obj.master_client_id : null
                    "
                />
            </div>
        </div>
    </div>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";
import moment from "moment";
// import _translator from "@/assets/js/translate";

import _objFileUpload from "@/components/pmo/FileUpload";
import _objSubClientMaint from "@/components/common/objSubClientMaint.vue";
import _objProfileMarkup from "@/components/common/objProfileMarkup.vue";
import _objProfileRates from "@/components/common/objProfileRates.vue";
import MultiSelect from "@/components/common/MultiSelect.vue";
import PMO_Reports from "@/assets/js/PMO_Reports.js";
import jsFileSaver from "file-saver";
// import { useAnalysisStore, useAuthenticationStore, useWebsocketStore } from "@/store/pinia";

export default {
    name: "Analysis",

    components: {
        FileUpload: _objFileUpload,
        SubClientMaint: _objSubClientMaint,
        ProfileRates: _objProfileRates,
        ProfileRatesMarkup: _objProfileMarkup,
        MultiSelect: MultiSelect,
    },
    props: {
        threeplCarriers: Array,
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
    },

    data() {
        return {
            tooltip: false,
            rateHeaders: [
                { text: "Price Group", value: "cpgName", sortable: false },
                { text: "Weight Break", value: "cpgWghtBrk", sortable: false },
                {
                    text: "Proposed Client Discount",
                    value: "discPropose",
                    sortable: false,
                },
                { text: "3PL Discount", value: "discContract", sortable: false },
                {
                    text: "Current Client Discount",
                    value: "discSubClient",
                    sortable: false,
                },
            ],

            hasError: false,
            errMsgArray: [],
            carriers: [],
            categories: [],
            uploadFiles: [],

            selectedLocations: [],
            selectedCarrier: null,
            selectedThreePLCarrier: null,
            selectedEntity: null,
            validSelectedEntity: null,
            validSelectedEntity_client_obj: null,
            selectedCategory: "",

            displaySummary: false,
            isLoading: false,
            showResults: false,
            showUploadDialog: false,
            showSubClientForm: false,
            startDate: "",
            endDate: "",

            analysisSavings: [],

            summaryUpload: null,
            loadingCarrierData: false,
            expandSummary: false,
            expandIcon: "angle-double-down",

            isImporting: false,
            summaryTableHeaders: [
                { label: "Prospect Current ($)", field: "origSpendSubClient", type: "$" },
                { label: "Prospect New ($)", field: "newSpendSubClient", type: "$" },
                {
                    label: "Prospect Savings ($)",
                    field: "savingsSubClientAmt",
                    type: "$",
                    highlight: true,
                },
                {
                    label: "Prospect Savings (%)",
                    field: "savingsSubClientPct",
                    type: "%",
                },
                { label: "3PL Cost ($)", field: "pmoCost", type: "$" },
                {
                    label: "3PL Margin ($)",
                    field: "pmoRevenue",
                    type: "$",
                    highlight: true,
                },
                {
                    label: "3PL Margin (%)",
                    field: "pmoMargin",
                    type: "%",
                },
                { label: "Packages", field: "packageCount" },
                { label: "Revenue/Package", field: "packageRevenueAvg", type: "$" },
                {
                    label: "US Ground Avg TNT",
                    field: "newAvgTNT",
                    suffix: "days",
                    highlight: true,
                },
            ],
        };
    },
    // setup() {
    //
    //     let analysis = useAnalysisStore();
    //
    //     return {
    //         analysis,
    //     };
    // },
    // mounted() {
    //
    // },

    watch: {
        isImporting() {
            if (this.isImporting) {
                this.selectedEntity = null;
                this.validSelectedEntity = null;
            }
        },
    },

    computed: {
        mappedLocations() {
            return this.selectedLocations
                ? [
                      { label: "", field: "" },
                      ...this.selectedLocations.map((c) => {
                          c.label = `${c.address.city} - ${c.zipcode}`;
                          return c;
                      }),
                  ]
                : [];
        },

        selectedLocationsAsText() {
            return this.selectedLocations ? this.selectedLocations.map((c) => c.address.city).join(", ") : [];
        },
        selectedLocationsAsZips() {
            return this.selectedLocations ? this.selectedLocations.map((c) => c.zipcode) : [];
        },
        entityList() {
            return this.prospects;
        },
        allowSummary() {
            return (
                this.selectedLocations.length > 0 &&
                !!this.selectedEntity &&
                this.summaryUpload &&
                this.summaryUpload["packages"]["count"] > 0 &&
                (this.selectedThreePLCarrier || this.threeplCarriers.length <= 0)
            );
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
    },

    methods: {
        selectLocs() {
            const mappedLocs = [];
            this.locations.filter((l) => {
                if (l.selected) {
                    mappedLocs.push(l);
                }
            });
            this.selectedLocations = mappedLocs;
        },
        async changeEntity(entity) {
            this.selectedEntity = entity;
            this.selectedCarrier = null;

            this.summaryUpload = null;
            this.locations = this.locations.map((c) => {
                return {
                    ...c,
                    selected: false,
                };
            });

            const prospects = this.entityList.filter((e) => e.sub_client_name === this.selectedEntity.sub_client_name);
            if (prospects) {
                let carriers = prospects;
                if (prospects && prospects[0] && Array.isArray(prospects[0].carrier)) {
                    carriers = prospects[0].carrier;
                }

                this.carriers = carriers.map((c) => {
                    return {
                        text: c.carrier ? c.carrier : c,
                        value: c.carrier ? c.carrier : c,
                    };
                });
                if (this.isImporting) {
                    this.selectedCarrier = null;
                } else {
                    this.changeCarrier(this.carriers[0]);
                }

                this.selectedLocations = [];

                this.postSelectTasks(this.selectedEntity.client_id, this.selectedCarrier.value);
            }
        },
        changeCarrier(carrier) {
            this.selectedCarrier = carrier;
            this.threeplCarriers.find((c) => {
                if (c === carrier.value) {
                    this.selectedThreePLCarrier = c;
                }
            });
            this.postSelectTasks(this.selectedEntity.client_id, this.selectedCarrier.value);
        },
        changeThreePLCarrier(carrier) {
            this.selectedThreePLCarrier = carrier;
            this.postSelectTasks(this.selectedEntity.client_id, this.selectedCarrier.value);
        },
        // NOTE this updates the summary table
        async postSelectTasks(p_strInPMOEntityID, p_strInPMOCarrier) {
            // var _apiUploadStatus = new Object();
            this.validSelectedEntity = this.selectedEntity.client_id;
            this.validSelectedEntity_client_obj = this.selectedEntity;
            try {
                this.summary = new Object();

                this.summary = this.summaryUpload;

                const _apiUploadStatus = await this.getSubClientStatus(p_strInPMOEntityID, p_strInPMOCarrier);

                if (!_apiUploadStatus.isActive) {
                    this.loadingCarrierData = true;
                    await this.getCarrierDataSummary(p_strInPMOEntityID, p_strInPMOCarrier);
                    this.loadingCarrierData = false;
                } else {
                    if (_apiUploadStatus.isActive) {
                        this.handleInfo(
                            `Uploaded data is still being processed - Status: ${_apiUploadStatus.statusMsg}`,
                            true
                        );

                        this.selectedEntity = null;
                        this.selectedCarrier = null;
                    } else {
                        this.handleError(_apiUploadStatus.statusMsg);
                    }
                }
            } catch (err) {
                this.handleError(err.message);
            }
        },
        async getCarrierDataSummary(subclientId, carrier) {
            this.loadingCarrierData = true;
            try {
                const url = `carrier-data-summary?client_id=${subclientId}&carrier=${carrier}`;
                const apiResult = await this.$httpNew.get(url);
                if (apiResult) {
                    this.summaryUpload = apiResult.data;
                    if (this.summaryUpload && Number(this.summaryUpload["packages"]["count"]) === 0) {
                        this.$notify.warn({ message: 'No packages found for prospect"', type: "warning" });
                    }
                    this.summaryUpload.shipmentWeeks = moment(this.summaryUpload.packages.enddate).diff(
                        this.summaryUpload.packages.startdate,
                        "weeks"
                    );
                    this.startDate = apiResult.data.startDate;
                    this.endDate = apiResult.data.endDate;
                }
            } catch (err) {
                let message = err.message;
                if (err && err.response && err.response.data) {
                    message = err.response.data.message;
                }
                this.$notify.error({ message: "Carrier Summary Errors:" + message, timeOut: 9000 });
            }
            this.loadingCarrierData = false;
        },
        async showSummaryResults() {
            this.isLoading = true;
            const cross_carrier =
                this.threeplCarriers.length > 0 && this.selectedThreePLCarrier !== this.selectedCarrier.value;
            try {
                this.displaySummary = !this.displaySummary;
                this.analysisSavings = [];
                let arrRecalcQuote = [];
                if (cross_carrier) {
                    arrRecalcQuote = await this.crossCarrierQuote(
                        this.selectedEntity.client_id,
                        this.selectedCarrier.value,
                        this.selectedThreePLCarrier,
                        this.selectedLocationsAsZips
                    );
                } else {
                    arrRecalcQuote = await this.recalcQuote(
                        this.selectedEntity.client_id,
                        this.selectedCarrier.value,
                        this.selectedLocationsAsZips
                    );
                }
                this.analysisSavings = arrRecalcQuote;
            } catch (err) {
                this.handleError(err.message);
            }

            this.isLoading = false;
        },
        async crossCarrierQuote(prospectID, prospectCarrier, threePLCarrier, locations) {
            const postData = {
                sub_client_id: prospectID,
                start_date: this.startDate,
                end_date: this.endDate,
                locations: locations,
                sub_client_carrier: prospectCarrier.toUpperCase().trim(),
                threepl_carrier: threePLCarrier.toUpperCase().trim(),
            };
            const url = "RerateByZipcodeSummaryCrossCarrier/";
            let apiRecalcQuote;
            try {
                apiRecalcQuote = (await this.$http.post(url, postData)).data;
            } catch (e) {
                this.$notify.error({ message: e, timeOut: 5000 });
            }
            return apiRecalcQuote.map((m) => {
                m.locationZip = m.zipcode;
                m.packageCount = Number(m.rerate_count);
                m.origSpendSubClient = Number(m.data_net);
                m.newSpendSubClient = Number(m.subclient_net);
                m.pmoCost = Number(m.threepl_net);
                m.savingsSubClientAmt = m.origSpendSubClient - m.newSpendSubClient;
                m.savingsSubClientPct = ((m.savingsSubClientAmt / m.origSpendSubClient) * 100).toFixed(2);
                m.pmoRevenue = m.newSpendSubClient - m.pmoCost;
                m.pmoMargin = (((Number(m.newSpendSubClient) - Number(m.pmoCost)) / Number(m.pmoCost)) * 100).toFixed(
                    2
                );
                m.packageRevenueAvg = m.pmoRevenue / m.packageCount;
                m.origAvgTNT = Number(m.orig_avg_tnt).toFixed(2);
                m.newAvgTNT = Number(m.avg_tnt).toFixed(2);
                return m;
            });
        },
        async recalcQuote(p_strInPMOProspectID, p_strInCarrier, p_arrInLocations) {
            const postData = {
                sub_client_id: p_strInPMOProspectID,
                start_date: this.startDate,
                end_date: this.endDate,
                locations: p_arrInLocations,
                sub_client_carrier: p_strInCarrier.toUpperCase().trim(),
                threepl_carrier: p_strInCarrier.toUpperCase().trim(),
            };
            //
            const url = "RerateByZipcodeSummaryCrossCarrier/";
            let apiRecalcQuote;
            try {
                apiRecalcQuote = (await this.$http.post(url, postData)).data;
            } catch (e) {
                this.$notify.error({ message: e, timeOut: 9000 });
            }
            // const postData = `client_id=${p_strInPMOProspectID}&start_date=${this.startDate}&end_date=${
            //     this.endDate
            // }&locations=${p_arrInLocations}&carrier=${p_strInCarrier.toUpperCase().trim()}`;

            // const url = "rerate-by-zip";
            // let apiRecalcQuote;
            // try {
            //     apiRecalcQuote = (await this.$httpNew.get(url, { params: postData })).data;
            //
            return apiRecalcQuote.map((m) => {
                m.locationZip = m.zipcode;
                m.packageCount = Number(m.rerate_count);
                m.origSpendSubClient = Number(m.data_net);
                m.newSpendSubClient = Number(m.subclient_net);
                m.pmoCost = Number(m.threepl_net);
                m.savingsSubClientAmt = Number(m.origSpendSubClient - m.newSpendSubClient);
                m.savingsSubClientPct = ((m.savingsSubClientAmt / m.origSpendSubClient) * 100).toFixed(2);
                m.pmoRevenue = m.newSpendSubClient - m.pmoCost;
                m.pmoMargin = (((Number(m.newSpendSubClient) - Number(m.pmoCost)) / Number(m.pmoCost)) * 100).toFixed(
                    2
                );
                m.packageRevenueAvg = m.pmoRevenue / m.packageCount;
                m.origAvgTNT = Number(m.orig_avg_tnt).toFixed(2);
                m.newAvgTNT = Number(m.avg_tnt).toFixed(2);
                return m;
            });
        },
        async getSubClientStatus(p_strInPMOProspectID, p_strInPMOCarrier) {
            var _blnResult = Boolean;
            var _strStatus = "";

            try {
                const url = `GetSubclientStatus/${p_strInPMOProspectID}/${p_strInPMOCarrier}`;
                const _apiResult = await this.$http.get(url);

                if (_apiResult.data.error_msg === undefined) {
                    _blnResult = _apiResult.data.process_flag;

                    _strStatus = _apiResult.data.status;
                } else {
                    throw new Error(_apiResult.data.error_msg);
                }
            } catch (err) {
                _blnResult = !(err.message.trim().length > 0);
                _strStatus = err.message;
            }

            return { isActive: _blnResult, statusMsg: _strStatus };
        },
        async closeSubClientForm() {
            this.showSubClientForm = false;
            await this.getClients();
            this.selectedEntity = {
                client_id: this.$store.state.pmo.pmo.sessPMOProspectID,
            };
            this.changeEntity();
        },
        async getAnalysisDetailRpt() {
            const url = "AnalysisDetailReport";
            const body = {
                subclient_id: this.selectedEntity.client_id,
                carrier: this.selectedCarrier.value,
                start_date: this.startDate,
                end_date: this.endDate,
            };
            if (this.selectedThreePLCarrier && this.selectedThreePLCarrier !== this.selectedCarrier.value) {
                body["cross_carrier"] = this.selectedThreePLCarrier;
            }
            const _apiResult = await this.$http
                .post(url, body, { responseType: "blob" })
                .then((res) => {
                    const url = window.URL.createObjectURL(new Blob([res.data]));
                    const a = document.createElement("a");
                    a.href = url;
                    const filename = `${body.subclient_id} Analysis Detail ${body.start_date}-${body.end_date} - ${body.carrier}.xlsx`;
                    a.setAttribute("download", filename);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
                .catch((error) => console.log(error));
        },
        toggleExpandIcon() {
            const upClass = "angle-double-up";
            const downClass = "angle-double-down";
            this.expandSummary = !this.expandSummary;

            if (this.expandSummary) {
                this.expandIcon = upClass;
            } else {
                this.expandIcon = downClass;
            }
        },
        formatValue(parmInType, parmInValue) {
            let strResult = "";

            try {
                switch (parmInType) {
                    case "$":
                        strResult = "$" + " " + PMO_Global.formatMoney(parmInValue);

                        break;

                    case "%":
                        strResult = parmInValue + " " + "%";

                        break;

                    default:
                        strResult = PMO_Global.formatNumber(parmInValue);

                        break;
                }
            } catch (err) {
                strResult = "";
            }
            return strResult;
        },
    },
};
</script>

<style lang="scss" scoped>
.loaders-container {
    padding: 20px;
    margin: 0 auto;
    width: 700px;
    text-align: center;

    .container {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        padding: 30px;
        width: 25%;
        height: 140px;
    }
}

.circle {
    box-sizing: border-box;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    border: 10px solid rgba(25, 25, 25, 0.2);
    border-top-color: #bbb;
    animation: spin 1s infinite linear;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}

.toggle-down {
    transform: rotate(0deg);
}

.toggle-up {
    transform: rotate(-180deg);
}
.force-scroll {
    overflow-y: scroll;

    height: 200px;
}
.cursor-pointer {
    cursor: pointer;
}
</style>

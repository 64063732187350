<template>
    <div class="m-4">
        <h3>Reports</h3>
        <hr />
        <div class="d-flex align-items-center">
            <mdb-dropdown v-if="reportSetup" class="flex-fill mr-2">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    selectedReport ? selectedReport.reportName : "Select Report"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item
                        v-for="report in reportSetup"
                        :key="report.reportID"
                        :active="selectedReport && report.reportID === selectedReport.reportID"
                        @click.native="changeReport(report)"
                        >{{ report.reportName }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>

            <template v-if="this.selectedReport && this.selectedReport.reportID === 0">
                <div class="flex-fill">
                    <mdb-date-picker-2
                        no-title
                        :max="selectedEndDate"
                        v-model="selectedBeginDate"
                        @input="mnuBeginDate = false"
                    ></mdb-date-picker-2>
                </div>
                <div class="flex-fill">
                    <mdb-date-picker-2
                        no-title
                        :min="selectedBeginDate"
                        v-model="selectedEndDate"
                        @input="mnuEndDate = false"
                    ></mdb-date-picker-2>
                </div>
            </template>

            <mdb-dropdown v-if="subclients" class="flex-fill ml-4">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    selectedSubClient ? selectedSubClient.sub_client_name : "Select Client"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!subclients.length">No clients found for this customer.</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="entity in subclients"
                        :key="entity.client_id"
                        :active="selectedSubClient && entity.sub_client_name === selectedSubClient.sub_client_name"
                        @click.native="changeSubClient(entity)"
                        >{{ entity.sub_client_name }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>

            <mdb-dropdown v-if="carriers" class="mx-2 flex-fill">
                <mdb-dropdown-toggle slot="toggle" outline="orange" class="w-100">{{
                    selectedCarrier ? selectedCarrier.text : "Select Carrier"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!carriers.length">Loading Carriers</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="carrier in carriers"
                        :key="carrier.value"
                        :active="selectedCarrier && carrier.value === selectedCarrier.value"
                        @click.native="changeCarrier(carrier)"
                        >{{ carrier.text }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
        </div>

        <mdb-row class="m-4">
            <mdb-col col="8">
                <mdb-row v-if="isLoading && hasResults">
                    <mdb-col text-md-center>
                        <mdb-spinner color="blue"></mdb-spinner>

                        <span class="md-headline6"> Producing {{ reportName }} report </span>
                    </mdb-col>
                </mdb-row>

                <mdb-row v-if="!isLoading && hasResults">
                    <div v-show="isDisplayResults">
                        <h3 class="md-headline4">
                            {{ reportName }}
                        </h3>

                        <div v-show="isGenerateOutput || downloading">
                            Please wait ...

                            <mdb-spinner color="blue"> </mdb-spinner>
                        </div>

                        <mdb-btn
                            outlined
                            color="orange"
                            v-on:click="downloadZip"
                            v-show="
                                isDisplayResults &&
                                (selectedReport.reportID === 2 || selectedReport.reportID === 1) &&
                                selectedDownloads &&
                                selectedDownloads.length > 0 &&
                                !downloading
                            "
                            >DOWNLOAD SELECTED</mdb-btn
                        >
                        <vue-good-table
                            :columns="headers"
                            :rows="outputData"
                            @on-selected-rows-change="selectionChanged"
                            item-key="client_id"
                            show-select
                            hide-default-footer
                            ref="datatable"
                            :select-options="{
                                enabled: true,
                                selectOnCheckboxOnly: true,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- <span v-if="props.column.field === 'selected'" width="1%">
                                    <mdb-input
                                        type="checkbox"
                                        :name="'selected_' + props.row.originalIndex"
                                        :id="'selected_' + props.row.originalIndex"
                                        v-model="props.row.selected"
                                        hide-details
                                    ></mdb-input>
                                </span> -->
                                <span v-if="props.column.field === 'clientId'">
                                    <b>{{ props.row.clientId }}</b>
                                </span>
                                <span v-if="props.column.field === 'clientName'">{{ props.row.clientName }}</span>
                                <span v-if="props.column.field === 'externalID'">{{ props.row.externalID }}</span>
                                <span v-if="props.column.field === 'threePLCost'">{{ props.row.threePLCost }}</span>
                                <span v-if="props.column.field === 'clientPrice'">{{ props.row.clientPrice }}</span>
                                <span v-if="props.column.field === 'threePLProfit'">{{ props.row.threePLProfit }}</span>
                                <span v-if="props.column.field === 'threePLMargin'">{{ props.row.threePLMargin }}</span>
                                <span v-if="props.column.field === 'pkgs'">{{ props.row.pkgs }}</span>
                                <span v-if="props.column.field === 'pkgRevenue'">{{ props.row.pkgRevenue }}</span>
                                <span v-if="props.column.field === 'avgTNT'">{{ props.row.avgTNT }}</span>
                                <span v-if="props.column.field === 'startDate'">{{ props.row.startDate }}</span>
                                <span v-if="props.column.field === 'endDate'">{{ props.row.endDate }}</span>
                                <span v-if="props.column.field === 'actions'">
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 0"
                                        icon="table"
                                        color="orange"
                                        @click.native.stop="downloadExcel(props.row.originalIndex)"
                                    >
                                    </mdb-btn>
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 2 && !props.row.is_link"
                                        color="orange"
                                        icon="save"
                                        @click.native.stop="downloadFile(props.row.originalIndex)"
                                    >
                                    </mdb-btn>
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 2 && props.row.is_link"
                                        color="orange"
                                        icon="save"
                                        @click="openLink(props.row.link)"
                                    >
                                    </mdb-btn>
                                    <mdb-btn
                                        v-if="selectedReport.reportID === 1"
                                        color="orange"
                                        icon="save"
                                        @click.native.stop="downloadExcel(props.row.originalIndex)"
                                    ></mdb-btn>
                                </span>
                                <span v-if="props.column.field === 'waitNotice'" width="15%">{{
                                    props.row.waitNotice
                                }}</span>
                            </template>
                        </vue-good-table>

                        <div v-show="isDisplayResults && selectedReport === -1">
                            <mdb-container>
                                <mdb-row col="8">
                                    <h3 class="md-headline4">
                                        {{ reportName }}
                                    </h3>

                                    <mdb-container>
                                        <mdb-row>
                                            <mdb-col col="10">
                                                <span class="md-headline6"> New report coming soon. </span>
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>
                                </mdb-row>
                            </mdb-container>
                        </div>
                    </div>
                </mdb-row>
                <mdb-row v-if="!isLoading && !hasResults">
                    <p>No results found in this date range for this report/client/carrier combo.</p>
                </mdb-row>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import PMO_Reports from "@/assets/js/PMO_Reports";
import axios from "axios";
import moment from "moment";
import jsFileSaver from "file-saver";

import PMO_Global from "@/assets/js/PMO_Global";
import { formRow } from "@aws-amplify/ui";

export default {
    name: "Reports",
    props: {
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
    },
    data() {
        return {
            headers: [],
            downloading: false,
            reportSetup: [
                { reportID: 0, reportName: "Package Level Detail" },
                { reportID: 1, reportName: "Invoice Summary" },
                { reportID: 2, reportName: "Carrier Data Regeneration" },
            ],

            dateRanges: [7, 30, 60, 90],

            isLoading: false,
            isDisplayResults: false,
            isGenerateOutput: false,
            hasResults: true,

            subClientID: "",
            subClientName: "",
            externalID: "",
            carriers: [],

            selectedSubClient: null,
            selectedReport: null,
            selectedBeginDate: moment().subtract(2, "weeks").startOf("week").format("YYYY-MM-DD"),
            selectedEndDate: moment().subtract(2, "weeks").endOf("week").format("YYYY-MM-DD"),
            selectedCarrier: null,

            reportName: "",

            beginRptDte: Date,
            endRptDte: Date,

            outputData: [],

            selectedDownloads: [],

            mnuBeginDate: false,
            mnuEndDate: false,
            currentRequest: null,
        };
    },
    computed: {
        subclients() {
            return this.clients;
        },
    },
    watch: {
        selectedBeginDate() {
            if (
                this.validParameters(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                )
            ) {
                this.reportRequest(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                );
            }
        },

        selectedEndDate() {
            if (
                this.validParameters(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                )
            ) {
                this.reportRequest(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                );
            }
        },
    },

    methods: {
        resetSelection(keepClient = false) {
            this.isDisplayResults = false;
            this.hasResults = true;

            if (!keepClient) {
                this.subClientID = null;
                this.subClientName = null;
                this.externalID = null;
                this.selectedSubClient = null;
                this.selectedCarrier = null;
            }

            this.selectedReport = null;
            this.endDate = null;

            this.headers = [];
            this.outputData = [];

            this.selectedDownloads = [];
        },

        changeReport(report) {
            // var _objReportType = Object;
            this.hasResults = true;
            try {
                this.resetSelection(true);

                this.selectedReport = report;
                this.reportName = report.reportName;
            } catch (err) {
                this.resetSelection();
            } finally {
                if (
                    this.validParameters(
                        this.selectedReport,
                        this.selectedBeginDate,
                        this.selectedEndDate,
                        this.selectedSubClient,
                        this.selectedCarrier
                    )
                ) {
                    this.reportRequest(
                        this.selectedReport,
                        this.selectedBeginDate,
                        this.selectedEndDate,
                        this.selectedSubClient,
                        this.selectedCarrier
                    );
                }
            }
        },

        changeSubClient(subclient) {
            this.hasResults = true;
            try {
                this.selectedCarrier = null;

                this.selectedSubClient = subclient;
                if (typeof subclient.carrier !== "string") {
                    this.carriers = subclient.carrier.map((c) => {
                        c.text = c;
                        c.value = c;
                        return c;
                    });
                } else {
                    this.carriers = [
                        {
                            text: subclient.carrier,
                            value: subclient.carrier,
                        },
                    ];
                }
                this.selectedCarrier = this.carriers[0];

                this.subClientID = subclient.client_id;
                this.subClientName = subclient.sub_client_name;
                this.externalID = subclient.external_id;
            } catch (err) {
                this.resetSelection();
            } finally {
                if (
                    this.validParameters(
                        this.selectedReport,
                        this.selectedBeginDate,
                        this.selectedEndDate,
                        this.selectedSubClient,
                        this.selectedCarrier
                    )
                ) {
                    this.reportRequest(
                        this.selectedReport,
                        this.selectedBeginDate,
                        this.selectedEndDate,
                        this.selectedSubClient,
                        this.selectedCarrier
                    );
                }
            }
        },

        changeCarrier() {
            this.hasResults = true;
            if (
                this.validParameters(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                )
            ) {
                this.reportRequest(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                );
            }
        },

        changeOutput() {
            this.hasResults = true;
            if (
                this.validParameters(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                )
            ) {
                this.reportRequest(
                    this.selectedReport,
                    this.selectedBeginDate,
                    this.selectedEndDate,
                    this.selectedSubClient,
                    this.selectedCarrier
                );
            }
        },

        validParameters(parmInReportID, parmInBeginDate, parmInEndDate, parmInSubClient, parmInCarrier) {
            return (
                parmInReportID !== null &&
                parmInBeginDate !== null &&
                parmInEndDate !== null &&
                parmInSubClient !== null &&
                parmInCarrier !== null
            );
        },

        async reportRequest(report, parmInBeginDate, parmInEndDate, subclient, carrier) {
            const parmInReportID = report.reportID;
            const parmInSubClient = subclient.client_id;
            const parmInCarrier = carrier.value;
            this.hasResults = true;
            if (this.currentRequest) {
                await this.currentRequest.cancel();
                this.currentRequest = {};
            }
            try {
                this.isLoading = true;
                this.beginRptDate = new Date(this.selectedBeginDate.replace(/-/g, "\/"));
                this.endRptDate = new Date(this.selectedEndDate.replace(/-/g, "\/"));

                this.outputData = null;
                this.isDisplayResults = false;

                // this.beginRptDte.setDate(this.beginRptDte.getDate() + 1);
                // this.endRptDte.setDate(this.endRptDte.getDate() + 1);

                this.currentRequest = axios.CancelToken.source();
                let response;
                switch (parmInReportID) {
                    case 0:
                        try {
                            response = await this.$http.get(
                                `BarrettPLDReport/${parmInSubClient}/${parmInCarrier}/${parmInBeginDate}/${parmInEndDate}`,
                                { cancelToken: this.currentRequest.token }
                            );
                        } catch (e) {
                            this.currentRequest = null;
                            this.isLoading = false;
                            throw new Error(e.message);
                        }

                        if (
                            response &&
                            response.data &&
                            (!response.data.error || response.data.error === undefined) &&
                            (!response.data.error_msg || response.data.error_msg === undefined)
                        ) {
                            this.headers = [
                                { label: "3RO ID", field: "clientId" },
                                { label: "External ID", field: "externalID" },
                                { label: "Client Name", field: "clientName" },
                                { label: "Packages", field: "pkgs" },
                                { label: "Download", field: "actions" },
                            ];
                            let numPkgs = 0;
                            response.data.forEach((row) => {
                                if (row[18] !== "") {
                                    numPkgs++;
                                }
                            });
                            this.outputData = [
                                {
                                    clientId: parmInSubClient,
                                    clientName: this.selectedSubClient.sub_client_name,
                                    externalID: this.externalID,
                                    pkgs: numPkgs.toLocaleString("en-US"),
                                    pkgCnt: numPkgs,
                                    waitNotice: "",
                                    data: response.data,
                                },
                            ];
                        } else {
                            this.currentRequest = null;
                            this.isLoading = false;
                            throw new Error(response.data.error);
                        }

                        break;

                    case 1:
                        try {
                            response = await this.$http.get(
                                `RerateFor3PLRatesSummaryArchive/${parmInSubClient}/${parmInCarrier}`,
                                { cancelToken: this.currentRequest.token }
                            );
                        } catch (e) {
                            this.currentRequest = null;
                            this.isLoading = false;
                            throw new Error(e.message);
                        }
                        if (
                            response &&
                            response.data &&
                            (!response.data.error || response.data.error === undefined) &&
                            (!response.data.error_msg || response.data.error_msg === undefined)
                        ) {
                            this.headers = [
                                // { label: "", field: "selected", sortable: false },
                                { label: "3RO ID", field: "clientId", sortable: false },
                                { label: "Client Name", field: "clientName", sortable: false, width: "250px" },
                                { label: "3RO ID", field: "externalID", sortable: false },
                                { label: "3PL Cost", field: "threePLCost", sortable: false },
                                { label: "Client Price", field: "clientPrice", sortable: false },
                                { label: "3PL Profit", field: "threePLProfit", sortable: false },
                                { label: "3PL Margin", field: "threePLMargin", sortable: false },
                                { label: "Packages", field: "pkgs", sortable: false },
                                { label: "Revenue/Pkg", field: "pkgRevenue", sortable: false },
                                // { label: "Avg TNT", field: "avgTNT", sortable: false },
                                { label: "Start Date", field: "startDate", sortable: false, width: "100px" },
                                { label: "End Date", field: "endDate", sortable: false, width: "100px" },
                                { label: "Download", field: "actions", sortable: false },
                            ];
                            let invoiceOutput = [];
                            for (const row of response.data) {
                                let thisRow = {
                                    // selected: false,
                                    clientId: parmInSubClient,
                                    clientName: this.selectedSubClient.sub_client_name,
                                    startDate: row.start_date,
                                    endDate: row.end_date,
                                    createdDate: row.created_date,
                                    carrier: row.carrier,
                                    externalID: this.externalID,
                                    pkgs: row.report.rerate_count.toLocaleString("en-US"),
                                    clientPrice: "$" + PMO_Global.formatMoney(row.report.subclient_net.toFixed(2)),
                                    threePLCost: "$" + PMO_Global.formatMoney(row.report.data_net.toFixed(2)),
                                    threePLProfit: "$" + PMO_Global.formatMoney(row.report.threepl_profit.toFixed(2)),
                                    threePLMargin:
                                        (row.report.rerate_count > 0
                                            ? PMO_Global.formatMoney(
                                                  ((row.report.threepl_profit / row.report.threepl_net) * 100).toFixed(
                                                      2
                                                  )
                                              )
                                            : "0") + " %",
                                    pkgRevenue:
                                        "$" +
                                        PMO_Global.formatMoney(
                                            (row.report.threepl_profit / row.report.rerate_count).toFixed(2)
                                        ),
                                    avgTNT: parseFloat(row.report.avg_tnt.toFixed(2)),
                                    data: row.report,
                                };
                                invoiceOutput.push(thisRow);
                            }
                            this.outputData = invoiceOutput;
                        } else {
                            this.currentRequest = null;
                            this.isLoading = false;
                            this.hasResults = false;
                            if (
                                response &&
                                response.data &&
                                (response.data.error !== undefined || response.data.error_msg !== undefined)
                            ) {
                                throw new Error(response.data.error);
                            }
                        }

                        break;

                    case 2:
                        try {
                            this.hasResults = true;
                            response = await this.$http.get(
                                `GetArchivedCarrierInvoiceRpt/${parmInSubClient}/${parmInCarrier}`,
                                { cancelToken: this.currentRequest.token }
                            );
                        } catch (e) {
                            this.currentRequest = null;
                            this.isLoading = false;
                            throw new Error(e.message);
                        }
                        if (
                            response &&
                            response.data &&
                            (!response.data.error || response.data.error === undefined) &&
                            (!response.data.error_msg || response.data.error_msg === undefined)
                        ) {
                            this.headers = [
                                // { label: "", field: "selected", sortable: false },
                                { label: "3RO ID", field: "clientId", sortable: false },
                                { label: "Client Name", field: "clientName", sortable: false, width: "250px" },
                                { label: "External ID", field: "externalID", sortable: false },
                                { label: "Records", field: "pkgs", sortable: false },
                                { label: "Start Date", field: "startDate", sortable: false, width: "100px" },
                                { label: "End Date", field: "endDate", sortable: false, width: "100px" },
                                { label: "Download", field: "actions", sortable: false },
                            ];

                            this.outputData = [];
                            for (const row of response.data) {
                                this.outputData.push({
                                    // selected: false,
                                    startDate: row.start_date,
                                    endDate: row.end_date,
                                    clientId: parmInSubClient,
                                    clientName: this.selectedSubClient.sub_client_name,
                                    externalID: this.externalID,
                                    pkgs: row.is_link ? row.total_pkgs : row.data.length.toLocaleString("en-US"),
                                    waitNotice: "",
                                    is_link: row.is_link,
                                    link: row.data,
                                    data: row.data,
                                });
                            }

                        } else {
                            this.currentRequest = null;
                            this.isLoading = false;
                            this.hasResults = false;
                            throw new Error(response.data.error);
                        }

                        break;

                    default:
                        break;
                }
                if (this.outputData[0]["pkgs"] === 0) {
                    this.hasResults = false;
                } else {
                    this.hasResults = true;
                }

                this.currentRequest = null;
                this.isLoading = false;

                this.isDisplayResults = !response.error;
            } catch (err) {
                this.$notify.error({
                    message: `Unable to produce ${this.reportName} report.`,
                    timeOut: 9000,
                });
                console.log(err.message)

                
                if (!this.currentRequest) {
                    this.isLoading = false;

                    this.isDisplayResults = !err.message;
                }
            }
        },
        openLink(link) {
            window.open(link, "_blank");
        },
        async downloadPDF() {
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);
            this.isGenerateOutput = true;

            setTimeout(() => {
                PMO_Reports.buildPDF(
                    _objReportSetup,
                    this.outputData,
                    this.subClientID,
                    this.subClientName,
                    this.selectedCarrier.value,
                    this.headers,
                    this.beginRptDte,
                    this.endRptDte
                ).finally(() => {
                    this.isGenerateOutput = false;
                });
            }, 100);
        },

        async downloadZip() {
            this.downloading = true;
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);
            // If for some reason we dont have any thing to download we will name it report
            // It should never happen but just in case
            let zip_name = "Report";
            const reports = [];
            zip_name = `${_objReportSetup.reportName} - ${this.subClientName}.zip`;
            for (let report of this.selectedDownloads) {
                if (_objReportSetup.reportName == 'Invoice Summary') {
                    reports.push(
                        await PMO_Reports.buildExcelFile(_objReportSetup, [report], 
                            this.externalID, this.subClientName, this.selectedCarrier.value,
                            this.selectedBeginDate, this.selectedEndDate, false)
                    )
                }
                else {
                    reports.push(
                        PMO_Reports.create_carrier_data_flat_file(
                            `${_objReportSetup.reportName} - ${this.subClientName} - ${this.selectedCarrier.value} - ${report.startDate} - ${report.endDate}`,
                            this.selectedCarrier.value,
                            report.data
                        )
                    );
                }
                
            }
            PMO_Reports.saveFileWithObject(await PMO_Reports.zip_files(reports, zip_name));

            this.downloading = false;
        },

        async downloadFile(recordIdx) {
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);

            // update parent data waitNotice
            const record = this.outputData[recordIdx];
            record.waitNotice = "Please wait ...";
            const file = PMO_Reports.create_carrier_data_flat_file(
                `${_objReportSetup.reportName} - ${this.subClientName} - ${this.selectedCarrier.value} - ${record.startDate} - ${record.endDate}`,
                this.selectedCarrier.value,
                record.data
            );
            await PMO_Reports.saveFileWithObject(file);
        },

        async downloadExcel(reportIdx) {
            const _objReportSetup = this.reportSetup.find((report) => report.reportID === this.selectedReport.reportID);
            this.isGenerateOutput = true;
            await PMO_Reports.buildExcelFile(
                _objReportSetup,
                [this.outputData[reportIdx]],
                this.externalID,
                this.subClientName,
                this.selectedCarrier.value,
                this.selectedBeginDate,
                this.selectedEndDate,
                this.headers
            );
            this.isGenerateOutput = false;
        },

        selectionChanged() {
            this.selectedDownloads = this.$refs["datatable"].selectedRows;
        },
    },
};
</script>

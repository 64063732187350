import jsPDF from "jspdf";

import jsZip from "jszip";
import jsFileSaver from "file-saver";
import * as XLSX from "xlsx";
import * as config from "../../aws-exports";
import papaparse from "papaparse";
import PMO_Global from "@/assets/js/PMO_Global";
// import { GlobalAccelerator } from "aws-sdk";
// import { ConsoleLogger } from "@aws-amplify/core";

const carrierSetup = [
    {
        carrierID: "UPS",
        use_quotes: false,
        delimiter: ",",
        extension: ".csv",
        dataType: "text/csv;charset=utf-8",
        hasHeader: true,
        header: null,
    },
    {
        carrierID: "FDX",
        use_quotes: true,
        delimiter: ";",
        extension: ".txt",
        dataType: "text/plain;charset=utf-8",
        hasHeader: false,
        header: null,
    },
];

/* Carrier Data File Header */
const tplHeaderCarrierData = [
    {
        carrierID: "UPS",
        header:
            "Version,Recipient Number,Account Number,Account Country/Territory,Invoice Date,Invoice Number,Invoice Type Code,Invoice Type Detail Code," +
            "Account Tax ID,Invoice Currency Code,Invoice Amount,Transaction Date,Pickup Record Number,Lead Shipment Number,World Ease Number," +
            "Shipment Reference Number 1,Shipment Reference Number 2,Bill Option Code,Package Quantity,Oversize Quantity,Tracking Number,Package Reference Number 1," +
            "Package Reference Number 2,Package Reference Number 3,Package Reference Number 4,Package Reference Number 5,Entered Weight,Entered Weight Unit of Measure," +
            "Billed Weight,Billed Weight Unit of Measure,Container Type,Billed Weight Type,Package Dimensions,Zone,Charge Category Code,Charge Category Detail Code," +
            "Charge Source,Type Code 1,Type Detail Code 1,Type Detail Value 1,Type Code 2,Type Detail Code 2,Type Detail Value 2,Charge Classification Code," +
            "Charge Description Code,Charge Description,Charged Unit Quantity,Basis Currency Code,Basis Value,Tax Indicator,Transaction Currency Code,Incentive Amount," +
            "Net Amount,Miscellaneous Currency Code,Miscellaneous Incentive Amount,Miscellaneous Net Amount,Alternate Invoicing Currency Code,Alternate Invoice Amount," +
            "Invoice Exchange Rate,Tax Variance Amount,Currency Variance Amount,Invoice Level Charge,Invoice Due Date,Alternate Invoice Number,Store Number," +
            "Customer Reference Number,Sender Name,Sender Company Name,Sender Address Line 1,Sender Address Line 2,Sender City,Sender State,Sender Postal," +
            "Sender Country/Territory,Receiver Name,Receiver Company Name,Receiver Address Line 1,Receiver Address Line 2,Receiver City,Receiver State,Receiver Postal," +
            "Receiver Country/Territory,Third Party Name,Third Party Company Name,Third Party Address Line 1,Third Party Address Line 2,Third Party City,Third Party State," +
            "Third Party Postal,Third party Country/Territory,Sold To Name,Sold To Company Name,Sold To Address Line 1,Sold To Address Line 2,Sold To City,Sold To State," +
            "Sold To Postal,Sold to Country/Territory,Miscellaneous Address Qual 1,Miscellaneous Address 1 Name,Miscellaneous Address 1 Company Name," +
            "Miscellaneous Address 1 Address Line 1,Miscellaneous Address 1 Address Line 2,Miscellaneous Address 1 City,Miscellaneous Address 1 State," +
            "Miscellaneous Address 1 Postal,Miscellaneous address 1 Country/Territory,Miscellaneous Address Qual 2,Miscellaneous Address 2 Name," +
            "Miscellaneous Address 2 Company Name,Miscellaneous Address 2 Address Line 1,Miscellaneous Address 2 Address Line 2,Miscellaneous Address 2 City," +
            "Miscellaneous Address 2 State,Miscellaneous Address 2 Postal,Miscellaneous address 2 Country/Territory,Shipment Date,Shipment Export Date,Shipment Import Date," +
            "Entry Date,Direct Shipment Date,Shipment Delivery Date,Shipment Release Date,Cycle Date,EFT Date,Validation Date,Entry Port,Entry Number,Export Place," +
            "Shipment Value Amount,Shipment Description,Entered Currency Code,Customs Number,Exchange Rate,Master Air Waybill Number,EPU,Entry Type,CPC Code," +
            "Line Item Number,Goods Description,Entered Value,Duty Amount,Weight,Unit of Measure,Item Quantity,Item Quantity Unit of Measure,Import Tax ID," +
            "Declaration Number,Carrier Name/Clinical Trial Identification Number/SDS ID ,CCCD Number,Cycle Number,Foreign Trade Reference Number,Job Number,Transport Mode," +
            "Tax Type,Tariff Code,Tariff Rate,Tariff Treatment Number,Contact Name,Class Number,Document Type,Office Number,Document Number,Duty Value,Total Value for Duty," +
            "Excise Tax Amount,Excise Tax Rate,GST Amount,GST Rate,Order In Council,Origin Country/Territory,SIMA Access,Tax Value,Total Customs Amount,Miscellaneous Line 1," +
            "Miscellaneous Line 2,Miscellaneous Line 3,Miscellaneous Line 4,Miscellaneous Line 5,Payor Role Code,Miscellaneous Line 7,Miscellaneous Line 8," +
            "Miscellaneous Line 9,Miscellaneous Line 10,Miscellaneous Line 11,Duty Rate,VAT Basis Amount,VAT Amount,VAT Rate,Other Basis Amount,Other Amount,Other Rate," +
            "Other Customs Number Indicator,Other Customs Number,Customs Office Name,Package Dimension Unit Of Measure,Original Shipment Package Quantity,Corrected Zone," +
            "Tax Law Article Number,Tax Law Article Basis Amount,Original tracking number,Scale weight quantity,Scale Weight Unit of Measure,Raw dimension unit of measure," +
            "Raw dimension length,BOL # 1,BOL # 2,BOL # 3,BOL # 4,BOL # 5,PO # 1,PO # 2,PO # 3,PO # 4,PO # 5,PO # 6,PO # 7,PO # 8,PO # 9,PO # 10,NMFC,Detail Class," +
            "Freight Sequence Number,Declared Freight Class,EORI Number,Detail Keyed Dim,Detail Keyed Unit of Measure,Detail Keyed Billed Dimension," +
            "Detail Keyed Billed Unit of Measure,Original Service Description,Promo Discount Applied Indicator,Promo Discount Alias,SDS Match Level Cd,SDS RDR Date," +
            "SDS Delivery Date,SDS Error Code,Place Holder 46,Place Holder 47,Place Holder 48,SCC Scale Weight,Place Holder 50,Place Holder 51,Place Holder 52," +
            "Place Holder 53,Place Holder 54,Place Holder 55,Place Holder 56,Place Holder 57,Place Holder 58,Place Holder 59",
    },
    { carrierID: "FDX", header: undefined },
];

const PDF_EXTENSION = ".pdf";
const ZIP_EXTENSION = ".zip";
const XLSX_EXTENSION = ".xlsx";

function buildPDF_InvoiceSummary(
    parmInStrReportName,
    parmInStrSubClientID,
    parmInStrCarrier,
    parmInDteBeginRptDate,
    parmInDteEndRptDate,
    arrColumns,
    objData
) {
    var _errMessage = undefined;

    var _objPDF = new jsPDF("p", "pt");
    var _strFilename = undefined;

    try {
        var _imgLogo = new Image();
        var _objCurrentDate = new Date();
        var _strCurrentDate =
            _objCurrentDate.getFullYear() +
            "_" +
            PMO_Global.pad(_objCurrentDate.getMonth() + 1, 2) +
            "_" +
            PMO_Global.pad(_objCurrentDate.getDate(), 2);

        _imgLogo.src = config.default.BASE_URL + "img/TransImpact_logo.49ff624c.png";

        _objPDF.addImage(_imgLogo, "PNG", 5, 5, 50, 25);

        _objPDF.setFont("helvetica");

        _objPDF.setFontSize("18");
        _objPDF.text(parmInStrReportName, 75, 15);
        _objPDF.text(
            `(${PMO_Global.formatDateDisplay(parmInDteBeginRptDate, "/")} - ${PMO_Global.formatDateDisplay(
                parmInDteEndRptDate,
                "/"
            )})`,
            128,
            15
        );

        _objPDF.setFontSize("14");
        _objPDF.setFontType("bold");
        _objPDF.text("Customer:", 75, 23);
        _objPDF.setFontType("normal");
        _objPDF.text(`${objData[0].clientId} - ${objData[0].clientName}`, 102, 23);

        _objPDF.setFontSize("12");
        _objPDF.setFontType("bold");
        var _intHit = 0;
        for (var _intCnt = 3; _intCnt < arrColumns.length; _intCnt++) {
            _objPDF.text(arrColumns[_intCnt].label, 10 + _intHit * 30, 40);
            _intHit++;
        }

        _objPDF.setFontType("normal");
        _objPDF.text(objData[0].threePLCost, 10, 47);
        _objPDF.text(objData[0].clientPrice, 40, 47);
        _objPDF.text(objData[0].threePLProfit, 70, 47);
        _objPDF.text(objData[0].threePLMargin, 100, 47);
        _objPDF.text(objData[0].pkgCnt.toString(), 130, 47);
        _objPDF.text(objData[0].pkgRevenue, 160, 47);
        _objPDF.text(objData[0].avgTNT.toString(), 190, 47);
        _strFilename = `${parmInStrReportName} ${_strCurrentDate} - ${parmInStrSubClientID
            .toUpperCase()
            .trim()} - ${parmInStrCarrier.toUpperCase().trim()} [${PMO_Global.formatDateDisplay(
            parmInDteBeginRptDate
        )} - ${PMO_Global.formatDateDisplay(parmInDteEndRptDate)}] (${objData[0].externalID})${PDF_EXTENSION}`;
    } catch (err) {
        _errMessage = err.message;
    }
    return { outputData: _objPDF, filename: _strFilename, dataError: _errMessage };
}

//TODO: Make sure that this function is not needed anymore...
async function buildZip_InvoiceSummary(parmInStrReportName, parmInStrSubClientID, parmInStrCarrier, parmInObjData) {
    var _errMessage = undefined;

    var _objCurrentDate = Date;
    var _objCarrierSetup = Object;

    var _strCurrentDate = "";

    var _objZip = Object;

    try {
        _objCurrentDate = new Date();

        _objCarrierSetup = carrierSetup.find(
            (carrier) => carrier.carrierID.toUpperCase().trim() === parmInStrCarrier.toUpperCase().trim()
        );

        if (_objCarrierSetup !== undefined) {
            _objZip = new jsZip();

            for (var _intCnt = 0; _intCnt < parmInObjData.length; _intCnt++) {
                let valAsArr = [];
                valAsArr.push(parmInObjData[_intCnt]);
                let _objFile = buildExcel_InvoiceSummary(
                    parmInStrReportName,
                    parmInStrSubClientID,
                    _objCarrierSetup.carrierID,
                    valAsArr
                );

                // _objZip.file(`${parmInObjData[_intCnt].externalID}${_objCarrierSetup.extension}`, _objFile.outputData);
                _objZip.file(_objFile.fileName, _objFile.outputData);
            }

            _strCurrentDate =
                _objCurrentDate.getFullYear() +
                "_" +
                PMO_Global.pad(_objCurrentDate.getMonth() + 1, 2) +
                "_" +
                PMO_Global.pad(_objCurrentDate.getDate(), 2);

            const blob = _objZip.generateAsync({ type: "blob" });
            const file_name = `${_strCurrentDate} - ${parmInStrSubClientID.toUpperCase().trim()} - ${parmInStrCarrier
                .toUpperCase()
                .trim()} ${parmInStrReportName}${ZIP_EXTENSION}`;
            return { blob: blob, filename: file_name };
        } else {
            throw new Error(`No carrier configuration found for '${parmInStrCarrier}'`);
        }
    } catch (err) {
        throw err;
    }
}
function buildExcel_InvoiceSummary(parmInStrReportName, parmInStrExternalID, parmInStrCarrier, parmInObjData) {
    var _errMessage = undefined;

    var _objCurrentDate = Date;

    var _strCurrentDate = undefined;

    var _strFilename = undefined;

    var _arrOutput = undefined;
    var _arrItem = undefined;

    var _objBLOB = Object;

    var _objWorkBook = Object;

    var _objRowItem = undefined;

    try {
        _objCurrentDate = new Date();

        _strCurrentDate =
            _objCurrentDate.getFullYear() +
            "_" +
            PMO_Global.pad(_objCurrentDate.getMonth() + 1, 2) +
            "_" +
            PMO_Global.pad(_objCurrentDate.getDate(), 2);

        var earlyDate = parmInObjData[0].startDate;
        var lateDate = parmInObjData[0].endDate;

        if (Array.isArray(parmInObjData) && parmInObjData.length > 0) {
            _objWorkBook = XLSX.utils.book_new();

            for (var _intCnt = 0; _intCnt < parmInObjData.length; _intCnt++) {
                var _arrOutput = [];
                const _arrItem = parmInObjData[_intCnt];

                if (_arrItem.startDate < earlyDate) {
                    earlyDate = _arrItem.startDate;
                }
                if (_arrItem.endDate > lateDate) {
                    lateDate = _arrItem.endDate;
                }

                if (Object.keys(_arrItem).length > 0) {
                    let currRpt = _arrItem.data;
                    //building main report section
                    const _objReportData = {
                        // "Report Start Date": new Date(_arrItem.startDate.trim().replace(/-/g, "/")),
                        "Report End Date": new Date(_arrItem.endDate.trim().replace(/-/g, "/")),
                        Carrier: _arrItem.carrier,
                        "3PL Gross Spend": currRpt.subclient_gross,
                        "3PL Net Spend": currRpt.data_net,
                        "Subclient Net Spend": currRpt.subclient_net,
                        "3PL Profit": currRpt.threepl_profit,
                        "Subclient Savings": currRpt.subclient_savings,
                        "Rerate Count": currRpt.rerate_count,
                        "Profit Per Package": (currRpt.threepl_profit / currRpt.rerate_count).toFixed(2),
                    };
                    // _arrOutput.push(Object.assign({}, _objHeaderData))
                    _arrOutput.push(Object.assign({}, _objReportData));
                }

                if (_arrOutput.length > 0) {
                    XLSX.utils.book_append_sheet(_objWorkBook, XLSX.utils.json_to_sheet(_arrOutput));
                }
            }
        }
        if (_arrOutput.length > 0) {
            _objBLOB = new Blob([XLSX.write(_objWorkBook, { bookType: "xlsx", bookSST: false, type: "array" })], {
                type: "application/octet-stream",
            });
        }
    } catch (err) {
        _errMessage = err.message;
    }

    _strFilename =
        parmInStrReportName + " - " + earlyDate + " - " + lateDate + " - " + parmInStrCarrier + XLSX_EXTENSION;
    _arrItem = null;
    _arrOutput = null;

    _objRowItem = null;

    _objWorkBook = null;
    return { outputData: _objBLOB, fileName: _strFilename, dataError: _errMessage };
}

function buildExcel_PackageLevelDetail(
    parmInStrReportName,
    parmInStrExternalID,
    parmInStrCarrier,
    parmInObjData,
    parmInDteBeginRptDate,
    parmInDteEndRptDate
) {
    var _errMessage = undefined;

    var _objCurrentDate = Date;

    var _strCurrentDate = undefined;

    var _strFilename = undefined;

    var _arrOutput = undefined;
    var _arrItem = undefined;

    var _objBLOB = Object;

    var _objWorkBook = Object;

    var _objRowItem = undefined;
    var _tplRowData = {
        "Client ID": "",
        "Tracking Number": "",
        "Price Group": "",
        Reference: "",
        "PO Number": "",
        "Ship Date": "",
        "Actual Weight": 0,
        "Billed Weight": 0,
        Length: 0,
        Width: 0,
        Height: 0,
        Zone: "",
        Receiver: "",
        "Receiver Address": "",
        "Receiver City": "",
        "Receiver State": "",
        "Receiver Postal Code": "",
        "Receiver Country": "",
        "Freight Charge Gross": 0,
        "Freight Charge Net": 0,
        "Fuel Surcharge Gross": 0,
        "Fuel Surcharge Net": 0,
        "Residential Gross": 0,
        "Residential Net": 0,
        "DAS Charge Gross": 0,
        "DAS Charge Net": 0,
        "Misc 1 Gross": 0,
        "Misc 1 Net": 0,
        "Misc 2 Gross": 0,
        "Misc 2 Net": 0,
        "Misc 3 Gross": 0,
        "Misc 3 Net": 0,
        "Duties & Taxes": 0,
        "Total Discount": 0,
        "Total Gross": 0,
        "Total Net": 0,
    };

    var _objShipDate = undefined;

    try {
        _objCurrentDate = new Date();

        _strCurrentDate =
            _objCurrentDate.getFullYear() +
            "_" +
            PMO_Global.pad(_objCurrentDate.getMonth() + 1, 2) +
            "_" +
            PMO_Global.pad(_objCurrentDate.getDate(), 2);

        _strFilename =
            parmInStrReportName +
            " - " +
            `${parmInDteBeginRptDate} - ${parmInDteEndRptDate}` +
            " - " +
            parmInStrCarrier +
            XLSX_EXTENSION;

        if (Array.isArray(parmInObjData) && parmInObjData.length > 0) {
            _objWorkBook = XLSX.utils.book_new();

            for (var _intCnt = 0; _intCnt < parmInObjData.length; _intCnt++) {
                _arrOutput = [];

                for (var _intItem = 0; _intItem < parmInObjData[_intCnt].data.length; _intItem++) {
                    _arrItem = parmInObjData[_intCnt].data[_intItem];

                    if (_arrItem.length > 0) {
                        _objShipDate = new Date(_arrItem[6].trim());

                        _objRowItem = Object.assign({}, _tplRowData);

                        _objRowItem["Client ID"] = parmInStrExternalID;
                        _objRowItem["Tracking Number"] = _arrItem[1].toString().toUpperCase().trim();
                        _objRowItem["Price Group"] = _arrItem[2].toString().toUpperCase().trim();
                        _objRowItem.Reference = _arrItem[4].toString().toUpperCase().trim();
                        _objRowItem["PO Number"] = _arrItem[5].toString().toUpperCase().trim();
                        _objRowItem["Ship Date"] =
                            PMO_Global.pad(_objShipDate.getMonth() + 1, 2) +
                            "/" +
                            PMO_Global.pad(_objShipDate.getDate() + 1, 2) +
                            "/" +
                            _objShipDate.getFullYear();

                        _objRowItem["Actual Weight"] = _arrItem[7];
                        _objRowItem["Billed Weight"] = _arrItem[8];

                        _objRowItem.Length = !isNaN(parseInt(_arrItem[9]))
                            ? parseInt(_arrItem[9]).toString().trim()
                            : 0;
                        _objRowItem.Width = !isNaN(parseInt(_arrItem[10]))
                            ? parseInt(_arrItem[10]).toString().trim()
                            : 0;
                        _objRowItem.Height = !isNaN(parseInt(_arrItem[11]))
                            ? parseInt(_arrItem[11]).toString().trim()
                            : 0;

                        _objRowItem.Zone = _arrItem[12].toString().trim();
                        _objRowItem.Receiver = _arrItem[13].toString().toUpperCase().trim();
                        _objRowItem["Receiver Address"] = _arrItem[14].toString().toUpperCase().trim();
                        _objRowItem["Receiver City"] = _arrItem[15].toString().toUpperCase().trim();
                        _objRowItem["Receiver State"] = _arrItem[16].toString().toUpperCase().trim();
                        _objRowItem["Receiver Postal Code"] = _arrItem[17].toString().toUpperCase().trim();
                        _objRowItem["Receiver Country"] = _arrItem[18].toString().toUpperCase().trim();
                        // _objRowItem["Freight Charge"] = "$" + PMO_Global.formatMoney(_arrItem[19]);
                        // _objRowItem["Fuel Surcharge"] = "$" + PMO_Global.formatMoney(_arrItem[20]);
                        // _objRowItem["Freight and Fuel"] = "$" + PMO_Global.formatMoney(_arrItem[21]);
                        // _objRowItem["Residential"] = "$" + PMO_Global.formatMoney(_arrItem[22]);
                        // _objRowItem["DAS Charge"] = "$" + PMO_Global.formatMoney(_arrItem[23]);
                        // _objRowItem["Misc 1"] = "$" + PMO_Global.formatMoney(_arrItem[24]);
                        // _objRowItem["Misc 2"] = "$" + PMO_Global.formatMoney(_arrItem[25]);
                        // _objRowItem["Misc 3"] = "$" + PMO_Global.formatMoney(_arrItem[27]);
                        // _objRowItem["Duties & Taxes"] = "$" + PMO_Global.formatMoney(_arrItem[26]);
                        // _objRowItem.Discount =
                        //     parseInt(_arrItem[28]) !== 0
                        //         ? "(" + "$" + PMO_Global.formatMoney(_arrItem[28]) + ")"
                        //         : "$" + PMO_Global.formatMoney(_arrItem[28]);
                        // _objRowItem.Gross = "$" + PMO_Global.formatMoney(_arrItem[29]);
                        // _objRowItem.Net = "$" + PMO_Global.formatMoney(_arrItem[30]);
                        _objRowItem["Freight Charge Gross"] = _arrItem[19];
                        _objRowItem["Freight Charge Net"] = _arrItem[31];
                        _objRowItem["Fuel Surcharge Gross"] = _arrItem[32];
                        _objRowItem["Fuel Surcharge Net"] = _arrItem[20];
                        _objRowItem["Residential Gross"] = _arrItem[33];
                        _objRowItem["Residential Net"] = _arrItem[22];
                        _objRowItem["DAS Charge Gross"] = _arrItem[34];
                        _objRowItem["DAS Charge Net"] = _arrItem[23];
                        _objRowItem["Misc 1 Gross"] = _arrItem[24];
                        _objRowItem["Misc 1 Net"] = _arrItem[35];
                        _objRowItem["Misc 2 Gross"] = _arrItem[25];
                        _objRowItem["Misc 2 Net"] = _arrItem[36];
                        _objRowItem["Misc 3 Gross"] = _arrItem[27];
                        _objRowItem["Misc 3 Net"] = _arrItem[37];
                        _objRowItem["Duties & Taxes"] = _arrItem[26];
                        _objRowItem["Total Discount"] = _arrItem[28];
                        _objRowItem["Total Gross"] = _arrItem[29];
                        _objRowItem["Total Net"] = _arrItem[30];
                        _arrOutput.push(_objRowItem);
                    }
                }

                if (_arrOutput.length > 0) {
                    XLSX.utils.book_append_sheet(_objWorkBook, XLSX.utils.json_to_sheet(_arrOutput));
                }
            }
        }

        if (_arrOutput.length > 0) {
            _objBLOB = new Blob([XLSX.write(_objWorkBook, { bookType: "xlsx", bookSST: false, type: "array" })], {
                type: "application/octet-stream",
            });
        }
    } catch (err) {
        _errMessage = err.message;
    }
    _arrItem = null;
    _arrOutput = null;

    _objRowItem = null;

    _objWorkBook = null;
    return { outputData: _objBLOB, fileName: _strFilename, dataError: _errMessage };
}
export default {
    async buildPDF(
        parmInObjReportInfo,
        parmInObjData,
        parmInStrSubClientID,
        parmInStrSubClientName = "",
        parmInStrCarrier = undefined,
        parmInArrColumns = undefined,
        parmInDteBeginRptDate = undefined,
        parmInDteEndRptDate = undefined
    ) {
        const { outputData: pdf, filename } = buildPDF_InvoiceSummary(
            `${parmInStrSubClientName} - ${parmInObjReportInfo.reportName}`,
            parmInStrSubClientID,
            parmInStrCarrier,
            parmInDteBeginRptDate,
            parmInDteEndRptDate,
            parmInArrColumns,
            parmInObjData
        );

        pdf.save(filename, { returnPromise: true })
            .then((result) => {
                console.log("pdf saved", result);
            })
            .catch((err) => {
                console.log(err);
                throw new Error(err.message);
            });
    },

    saveFile(fileName, blob) {
        jsFileSaver.saveAs(blob, fileName);
    },
    // Helper function that allows us to use the other function's outputs as inputs.
    saveFileWithObject(blobAndNameObject){
        this.saveFile( blobAndNameObject.fileName,blobAndNameObject.blob);
    },
    create_carrier_data_flat_file(file_name, carrier, report_data) {

        const carrrier_metadata = carrierSetup.find(
            (_carrier) => _carrier.carrierID.toUpperCase().trim() === carrier.toUpperCase().trim()
        );
        // convert "raw" data into string and convert into proper objects.
        const temp = report_data.join('\n');
        // This allows us to sanitize the file better rather than relying on homebrewed solutions.
        const parsed_data = papaparse.parse(temp);
        // Convert back into CSV string with settings found from the carrier
        const stringified_data = papaparse.unparse(parsed_data.data, {
            quotes: carrrier_metadata.use_quotes,
            delimiter: carrrier_metadata.delimiter,
        });
        return {
            blob: new Blob([stringified_data], { type: carrrier_metadata.dataType }),
            fileName: `${file_name} - ${carrier}${carrrier_metadata.extension}`,
        };
    },
    async zip_files(files, zip_file_name) {
        const zip = new jsZip();
        files.forEach((file) => {
            zip.file(file.fileName, file.blob);
        });
        const blob = await zip.generateAsync({ type: "blob" });
        return { blob, filename: zip_file_name };
    },

    async buildExcelFile(
        parmInObjReportInfo,
        parmInObjData,
        parmInStrExternalID,
        parmInStrSubClientName = "",
        parmInStrCarrier = undefined,
        parmInDteBeginRptDate = undefined,
        parmInDteEndRptDate = undefined,
        returnObj = true
    ) {
        var _objOutput = undefined;
        if (parmInObjReportInfo.reportID === 0) {
            _objOutput = buildExcel_PackageLevelDetail(
                `${parmInStrSubClientName} - ${parmInObjReportInfo.reportName}`,
                parmInStrExternalID,
                parmInStrCarrier,
                parmInObjData,
                parmInDteBeginRptDate,
                parmInDteEndRptDate
            );
        } else if (parmInObjReportInfo.reportID === 1) {
            _objOutput = buildExcel_InvoiceSummary(
                `${parmInStrSubClientName} - ${parmInObjReportInfo.reportName}`,
                parmInStrExternalID,
                parmInStrCarrier,
                parmInObjData
            );
        }

        if (_objOutput.dataError !== undefined) {
            alert(_objOutput.dataError);
        }

        if (_objOutput.outputData !== undefined && returnObj) {
            jsFileSaver.saveAs(_objOutput.outputData, _objOutput.fileName);
        }
        else if (_objOutput.outputData !== undefined && !returnObj) {
            const carrier_metadata = carrierSetup.find(
                (_carrier) => _carrier.carrierID.toUpperCase().trim() === parmInStrCarrier.toUpperCase().trim()
            );
            return { blob: _objOutput.outputData,
                     fileName: _objOutput.fileName}
        }
    },
};

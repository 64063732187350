import Vue from "vue";
import Router from "vue-router";
import Login from "@/components/Login";
import PMOBase from "@/components/PMO";
import Analysis from "@/components/pmo/Analysis";
import SubClientAdmin from "@/components/pmo/SubClientAdmin";
import ClientAdmin from "@/components/pmo/ClientAdmin";
import Profiles from "@/components/pmo/Profiles";
import Alerts from "@/components/pmo/Alerts";
import Users from "@/components/Users";
import Reports from "@/components/pmo/Reports";
import Locations from "@/components/pmo/Locations";
import NotAuthorized from "@/components/NotAuthorized";

Vue.use(Router);

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/login",
            component: Login,
        },
        {
            path: "/",
            name: "3PL Revenue Optimizer",
            component: PMOBase,
            meta: {
                permission: "PMO",
                title: "3PL Revenue Optimizer",
            },
            children: [
                {
                    path: "",
                    name: "Analysis",
                    component: Analysis,
                    meta: {
                        permission: "PMO",
                        title: "Analysis",
                    },
                },
                {
                    path: "/clients",
                    name: "Clients",
                    component: SubClientAdmin,
                    meta: {
                        permission: "PMO",
                        title: "Clients",
                    },
                },
                {
                    path: "/profiles",
                    name: "Profiles",
                    component: Profiles,
                    meta: {
                        permission: "PMO",
                        title: "Profiles",
                    },
                },
                {
                    path: "/alerts",
                    name: "Alerts",
                    component: Alerts,
                    meta: {
                        name: "Alerts",
                        permission: "PMO",
                        title: "Alerts",
                    },
                },
                {
                    path: "/reports",
                    name: "Reports",
                    component: Reports,
                    meta: {
                        permission: "PMO",
                        title: "Reports",
                    },
                },
                {
                    path: "/locations",
                    name: "Locations",
                    component: Locations,
                    meta: {
                        permission: "PMO",
                        title: "Locations",
                    },
                },
                {
                    path: "/setup",
                    name: "3PL Info",
                    component: ClientAdmin,
                    meta: {
                        permission: "Administrators",
                        title: "3PL Info",
                    },
                },
                {
                    path: "/users",
                    name: "Users",
                    component: Users,
                    meta: {
                        permission: "Administrators",
                        title: "Users",
                    },
                }
            ],
        },
        // Catch all for 401's
        { path: "/not-authorized", component: NotAuthorized },
        { path: "*", component: NotAuthorized }
    ],
});

router.beforeResolve((to, from, next) => {
    const matched = to.meta;
    if (matched && matched.permission) {
        Vue.prototype.$Amplify.Auth.currentAuthenticatedUser()
            .then((data) => {
                if (data && data.signInUserSession) {
                    if (matched.permission === "requiresAuth") {
                        next();
                    } else {
                        const groups = data.signInUserSession.accessToken.payload["cognito:groups"];
                        if (groups && groups.includes(matched.permission)) {
                            if (matched.notAllowed && groups.includes(matched.notAllowed)) {
                                next({
                                    path: "/",
                                });
                            }
                            next();
                        } else {
                            next({
                                path: "/",
                            });
                        }
                    }
                } else {
                    next({
                        path: "/login",
                    });
                }
            })
            .catch((e) => {
                console.error(e);
                next({
                    path: "/login",
                    query: { redirect: to.fullPath },
                });
            });
    } else {
        next();
    }

    document.title = "3PL Revenue Optimizer - TransImpact";

    updateTitle(to.meta.title);
});

function updateTitle(inTitle) {
    if (inTitle != undefined) {
        document.title = document.title + " - " + inTitle;
    }
}

export default router;

// eslint-disable
export default {
    pad: function (n, width, z) {
        z = z || "0";
        n = n + "";

        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },

    formatDateDisplay: function (objInDate, strSeparator = "-") {
        var _strOutput = undefined;

        var _strYear = undefined;
        var _strMonth = undefined;
        var _strDay = undefined;

        try {
            _strYear = objInDate.getFullYear().toString();
            _strMonth = ((objInDate.getMonth() + 1 < 10 ? "0" : "") + (objInDate.getMonth() + 1)).toString();
            _strDay = ((objInDate.getDate() < 10 ? "0" : "") + objInDate.getDate()).toString();

            _strOutput = `${_strMonth}${strSeparator}${_strDay}${strSeparator}${_strYear}`;
        } catch (err) {
            _strOutput = null;
        }
        return _strOutput;
    },

    formatNumber: function (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    formatMoney: function (n, c, d, t) {
        (c = isNaN((c = Math.abs(c))) ? 2 : c), (d = d === undefined ? "." : d), (t = t === undefined ? "," : t);

        let s = n < 0 ? "-" : "",
            i = String(parseInt((n = Math.abs(Number(n) || 0).toFixed(c))));
        let j = i.length;
        j = j > 3 ? j % 3 : 0;

        return (
            s +
            (j ? i.substr(0, j) + t : "") +
            i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) +
            (c
                ? d +
                  Math.abs(n - i)
                      .toFixed(c)
                      .slice(2)
                : "")
        );
    },
};

<template>
    <div class="m-4">
        <h3>User Maintenance</h3>
        <mdb-btn color="orange" @click.native="addDialog = true">Add New User</mdb-btn>
        <mdb-modal
            transition="dialog-bottom-transition"
            :show="addDialog"
            class="mt-10"
            size="xl"
            @close="addDialog = false"
        >
            <mdb-modal-header>Add New User</mdb-modal-header>
            <mdb-modal-body>
                <div class="text-h2 pa-12">
                    <mdb-form v-model="valid">
                        <mdb-container>
                            <mdb-row>
                                <mdb-col cols="12">
                                    <mdb-input
                                        type="text"
                                        v-model="newEmail"
                                        label="User Name (autogenerated from email)"
                                        disabled
                                    ></mdb-input>
                                </mdb-col>
                                <mdb-col cols="12">
                                    <mdb-input v-model="newEmail" label="Email" required></mdb-input>
                                </mdb-col>

                                <mdb-col cols="12">
                                    <mdb-input
                                        v-model="newPhoneNumber"
                                        label="Phone Number"
                                        required
                                        placeholder="555 555 5555"
                                    ></mdb-input>
                                </mdb-col>

                                <mdb-col cols="12">
                                    <mdb-input v-model="newClientId" label="TI Client ID" required></mdb-input>
                                </mdb-col>

                                <mdb-col cols="12">
                                    <mdb-input
                                        type="text"
                                        v-model="temporaryPass"
                                        label="Temporary Password"
                                        required
                                    ></mdb-input>
                                </mdb-col>
                            </mdb-row>
                        </mdb-container>
                    </mdb-form>
                </div>
            </mdb-modal-body>
            <mdb-modal-footer class="justify-end">
                <mdb-btn text color="success" @click.native="createUser">Create User</mdb-btn>
                <mdb-btn text color="danger" @click.native="addDialog = false">Close</mdb-btn>
            </mdb-modal-footer>
        </mdb-modal>
        <vue-good-table
            :rows="users"
            :columns="headers"
            :items-per-page="itemsPerPage"
            :loading="isLoading"
            loading-text="Loading... Please wait"
            :footer-props="{
                'items-per-page-options': rowsPerPage,
            }"
            :sort-options="{
                enabled: true,
            }"
        >
            class="elevation-1" >
            <div slot="table-row" slot-scope="props" class="w-100">
                <span v-if="props.column.field === 'username'">
                    {{ props.row.Username }}
                </span>
                <span v-if="props.column.field === 'clientId'">
                    <mdb-input
                        type="text"
                        large
                        v-model="props.row['custom:TIClientID']"
                        @keydown.native.enter="save(props.row)"
                        @blur="save(props.row, 'custom:TIClientID')"
                    >
                    </mdb-input>
                </span>
                <span v-if="props.column.field === 'email'">
                    <mdb-input
                        type="text"
                        large
                        v-model="props.row.email"
                        @keydown.native.enter="save(props.row)"
                        @blur="save(props.row, 'email')"
                    >
                    </mdb-input>
                </span>
                <span v-if="props.column.field === 'phone_number'">
                    <mdb-input
                        type="text"
                        large
                        v-model="props.row.phone_number"
                        @keydown.native.enter="save(props.row)"
                        @blur="save(props.row, 'phone_number')"
                    >
                    </mdb-input>
                </span>
                <span v-if="props.column.field === 'groups'">
                    <mdb-select
                        :options="props.row.groups"
                        :menu-props="{ maxHeight: '400' }"
                        label="Select"
                        multiple
                        attach
                        chips
                        @getValue="changeUserGroups(props.row)"
                    ></mdb-select>
                </span>
                <span v-if="props.column.field === 'actions'">
                    <mdb-icon
                        size="2x"
                        v-if="props.row.Enabled"
                        @click.native="disableUser(props.row)"
                        color="green"
                        icon="user"
                    ></mdb-icon>
                    <mdb-icon
                        size="2x"
                        v-if="!props.row.Enabled"
                        @click.native="enableUser(props.row)"
                        color="red"
                        icon="ban"
                    ></mdb-icon>
                </span>
            </div>
            <div slot="emptystate">
                <mdb-btn color="primary" @click.native="initialize"> Reset </mdb-btn>
            </div>
        </vue-good-table>
    </div>
</template>

<script>
// import Alert from "@/components/common/Alert";
import AWS from "aws-sdk";
import * as config from "../aws-exports";

export default {
    components: {
        // Alert,
    },

    data() {
        return {
            users: [],
            headers: [
                { label: "Username", field: "username", sortable: true },
                { label: "Phone #", field: "phone_number", sortable: true },
                { label: "Client ID", field: "clientId", sortable: true },
                { label: "Email", field: "email", sortable: true },
                { label: "Groups", field: "groups", sortable: false },
                { label: "Disable/Enable", field: "actions", sortable: false },
            ],
            itemsPerPage: 50,
            rowsPerPage: [20, 50, 100, 150, -1],
            isLoading: false,
            idServiceProvider: null,
            groups: [],
            snack: false,
            snackColor: "",
            snackText: "",
            addDialog: false,
            newUserName: "",
            temporaryPass: "",
            newEmail: "",
            newPhoneNumber: "",
            newClientId: "",
            loadingAddUser: false,
            valid: false,
        };
    },

    methods: {
        async createUser() {
            this.loadingAddUser = true;
            const params = {
                UserPoolId: config.default.userPoolId /* required */,
                Username: this.newEmail /* required */,
                DesiredDeliveryMediums: ["EMAIL"],
                ForceAliasCreation: false,
                MessageAction: "SUPPRESS",
                TemporaryPassword: this.temporaryPass,
                UserAttributes: [
                    {
                        Name: "email",
                        Value: this.newEmail,
                    },
                    {
                        Name: "custom:TIClientID",
                        Value: this.newClientId,
                    },
                ],
            };
            const userGroupParams = {
                UserPoolId: config.default.userPoolId,
                Username: this.newEmail,
                GroupName: "PMO",
            };
            if (this.newPhoneNumber) {
                params.UserAttributes.push({
                    Name: "phone_number",
                    Value: `+1${this.newPhoneNumber
                        .replace("(", "")
                        .replace(")", "")
                        .replace(/ /g, "")
                        .replace(/-/g, "")}`,
                });
            }
            // eslint-disable-next-line no-unused-vars
            this.idServiceProvider.adminCreateUser(params, async (err, data) => {
                if (err) {
                    this.$notify.error({ message: "Unable to create user due to input issue.", timeOut: 9000 });
                }
                // an error occurred
                else {
                    this.idServiceProvider.adminAddUserToGroup(userGroupParams, async (err, data) => {
                        if (err) {
                            this.$notify.error({
                                message: "Unable to add user to PMO user group. Please try again.",
                                timeOut: 9000,
                            });
                        }
                    });
                    await this.getUsers();
                    this.loadingAddUser = false;
                    this.addDialog = false;
                    this.$notify.success({ message: "Successfully added user.", timeOut: 9000 });
                }
            });
        },
        save(item, checkField = null) {
            if (checkField) {
                if (item[checkField] === this.baseUsers[item.originalIndex][checkField]) {
                    return false;
                }
            }
            let phone_number;
            let customId;
            const attributes = [
                {
                    Name: "email",
                    Value: item.email,
                },
            ];
            if (item.phone_number) {
                phone_number = {
                    Name: "phone_number",
                    Value: item.phone_number,
                };
                attributes.push(phone_number);
            }
            if (item["custom:TIClientID"]) {
                customId = {
                    Name: "custom:TIClientID",
                    Value: item["custom:TIClientID"],
                };
                attributes.push(customId);
            }
            const params = {
                UserAttributes: [...attributes],
                UserPoolId: config.default.userPoolId /* required */,
                Username: item.Username /* required */,
            };
            // eslint-disable-next-line no-unused-vars
            this.idServiceProvider.adminUpdateUserAttributes(params, (err, data) => {
                if (err) {
                    this.$notify.error({ message: `User could not be saved.`, timeOut: 9000 });
                } else {
                    this.$notify.success({ message: "User saved", timeOut: 9000 });
                    this.baseUsers[item.originalIndex] = Object.assign({}, item);
                }
            });
        },
        disableUser(item) {
            const params = {
                UserPoolId: config.default.userPoolId /* required */,
                Username: item.Username /* required */,
            };
            // eslint-disable-next-line no-unused-vars
            this.idServiceProvider.adminDisableUser(params, (err) => {
                if (err) {
                    this.$notify.error({ message: `User could not be disabled.`, timeOut: 9000 });
                    item.Enabled = true;
                } else {
                    this.$notify.success({ message: "User disabled", timeOut: 9000 });
                    item.Enabled = false;
                }
            });
        },
        enableUser(item) {
            const params = {
                UserPoolId: config.default.userPoolId /* required */,
                Username: item.Username /* required */,
            };
            this.idServiceProvider.adminEnableUser(params, (err) => {
                if (err) {
                    this.$notify.error({ message: `User could not be enabled.`, timeOut: 9000 });
                    item.Enabled = false;
                } else {
                    this.$notify.success({ message: "User enabled", timeOut: 9000 });
                    item.Enabled = true;
                }
            });
        },
        addUserToGroup(item, group) {
            const params = {
                UserPoolId: config.default.userPoolId /* required */,
                Username: item.Username /* required */,
                GroupName: group.value,
            };
            this.idServiceProvider.adminAddUserToGroup(params, (err) => {
                if (err) {
                    this.$notify.error({ message: `User could not be added to group.`, timeOut: 9000 });
                } else {
                    this.$notify.success({ message: "User added to group", timeOut: 9000 });
                    item.oldGroups.push(group.value);
                }
            });
        },
        removeUserFromGroup(item, group) {
            const params = {
                UserPoolId: config.default.userPoolId /* required */,
                Username: item.Username /* required */,
                GroupName: group.value,
            };
            this.idServiceProvider.adminRemoveUserFromGroup(params, (err) => {
                if (err) {
                    this.$notify.error({
                        message: `User could not be removed from group.`,
                        timeOut: 9000,
                    });
                } else {
                    item.oldGroups = item.groups;
                    this.$notify.success({ message: "User removed from group", timeOut: 9000 });
                }
            });
        },
        async changeUserGroups(item) {
            for (const i of item.groups) {
                if (!item.oldGroups.includes(i.value) && i.selected) {
                    await this.addUserToGroup(item, i);
                }
            }
            for (const i of item.groups) {
                if (item.oldGroups.includes(i.value) && !i.selected) {
                    await this.removeUserFromGroup(item, i);
                }
            }
        },
        cancel() {
            this.$notify.error({ message: "Cancelled", timeOut: 9000 });
        },
        async getCreds() {
            await AWS.config.update({
                region: config.default.region,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: `${config.default.region}:7d24138f-b8f3-4584-9877-804af9b8ebe4`,
                }),
            });
            AWS.config.credentials.refresh();
            AWS.config.getCredentials((err) => {
                if (err) console.log(err.stack);
                // credentials not loaded
                else {
                    // console.log("Access key:", AWS.config.credentials.accessKeyId);
                }
            });

            this.idServiceProvider = new AWS.CognitoIdentityServiceProvider({
                region: config.default.region,
                credentials: new AWS.CognitoIdentityCredentials({
                    IdentityPoolId: `${config.default.region}:7d24138f-b8f3-4584-9877-804af9b8ebe4`,
                }),
            });
        },
        async getGroups() {
            this.idServiceProvider.listGroups({ UserPoolId: config.default.userPoolId }, (err, data) => {
                this.groups = data.Groups.map((g) => {
                    g.text = g.GroupName;
                    g.value = g.groupName;
                    return g;
                });
                this.$forceUpdate();
            });
        },
        async getUsers() {
            this.isLoading = true;

            this.idServiceProvider.listUsers({ UserPoolId: config.default.userPoolId }, (err, data) => {
                if (err) {
                    //console.log(err);
                } else {
                    data.Users.map(async (u) => {
                        u.groups = await new Promise((resolve) => {
                            this.idServiceProvider.adminListGroupsForUser(
                                { UserPoolId: config.default.userPoolId, Username: u.Username },
                                (err, data) => {
                                    let groupsArr = [];
                                    for (const grp of this.groups) {
                                        let pushed = false;
                                        data.Groups.forEach((g) => {
                                            if (grp.GroupName === g.GroupName) {
                                                pushed = true;
                                                groupsArr.push({
                                                    text: g.GroupName,
                                                    value: g.GroupName,
                                                    selected: true,
                                                });
                                            }
                                        });
                                        if (!pushed) {
                                            groupsArr.push({ text: grp.GroupName, value: grp.GroupName });
                                        }
                                    }
                                    resolve(groupsArr);
                                }
                            );
                        });
                        u.oldGroups = u.groups.map((g) => {
                            if (g.selected) return g.value;
                        });
                        u.clientId = u["custom:TIClientID"];
                        u.Attributes.map((a) => {
                            u[a.Name] = a.Value;
                            u[a.Name.toLowerCase()] = a.Value;
                            return u;
                        });
                        return u;
                    });
                    setTimeout(() => {
                        this.isLoading = false;
                        this.users = data.Users;
                        this.baseUsers = data.Users;
                        this.$forceUpdate();
                    }, 50 * data.Users.length);
                }
            });
        },
    },
    async mounted() {
        await this.getCreds();
        this.getUsers();
        this.getGroups();
    },
};
</script>

<template>
    <mdb-select
        ref="multiselect"
        v-model="selection"
        :options="selections"
        :label="label"
        :prepend-inner-icon="prepend"
        :error-messages="errorMessages"
        :disabled="disabled"
        append-icon="add"
        solo
        filled
        multiple
        chips
        deletable-chips
    >
        <template v-slot:append-item>
            <mdb-row align-center>
                <mdb-col col="6" pl-3>
                    <span v-if="hint" class="text-primary md-caption">{{ hint }}</span>
                </mdb-col>
                <mdb-col col="6" text-lg-right>
                    <mdb-btn outlined color="green" @click.native="done"> Done </mdb-btn>
                </mdb-col>
            </mdb-row>
        </template>
    </mdb-select>
</template>

<script>
export default {
    name: "MultiSelect",
    props: {
        value: Array,
        label: String,
        items: Array,
        itemText: String,
        itemValue: String,
        prepend: String,
        limit: Number,
        errorMessages: [String, Array],
    },
    data() {
        return {
            selection: [],
            disabled: false,
            hint: "",
        };
    },
    watch: {
        value() {
            this.selection = this.value;
        },
        selection() {
            this.$emit("input", this.selection);
            this.limiter();
        },
    },
    computed: {
        selections() {
            const selections = [];
            this.items.forEach((item) => {
                var text, value;
                if (typeof item === "string" || item instanceof String) {
                    text = item;
                    value = item;
                } else {
                    text = item[this.itemText];
                    value = item[this.itemValue];
                }
                selections.push({
                    text: text,
                    value: value,
                });
            });

            let arrDuplicates = this.getDuplicates(selections);

            arrDuplicates.forEach((dupItem) => {
                if (dupItem.count > 0) {
                    selections.forEach((entry) => {
                        if (entry.text.toUpperCase().trim() === dupItem.location.toUpperCase().trim()) {
                            entry.text = entry.text + " " + "(" + entry.value + ")";
                        }
                    });
                }
            });

            return selections;
        },
    },
    methods: {
        done() {
            this.$refs.multiselect.blur();
        },

        getDuplicates(parmInArrLookup) {
            var _arrResult = undefined;
            var _blnIsFound = false;

            try {
                _arrResult = [];

                for (var _intCnt = 0; _intCnt < parmInArrLookup.length; _intCnt++) {
                    _blnIsFound = false;

                    for (var _intLookup = 0; _intLookup < _arrResult.length; _intLookup++) {
                        _blnIsFound =
                            parmInArrLookup[_intCnt].text.toUpperCase().trim() ===
                            _arrResult[_intLookup].location.toUpperCase().trim();

                        if (_blnIsFound) {
                            _arrResult[_intLookup].count += 1;
                        }
                    }

                    if (!_blnIsFound) {
                        _arrResult.push({ location: parmInArrLookup[_intCnt].text, count: +_blnIsFound });
                    }
                }
            } catch (err) {
                _arrResult = [];
            }
            return _arrResult;
        },

        limiter() {
            if (this.limit && this.selection.length >= this.limit) {
                this.selections.forEach((item) => {
                    if (!~this.selection.indexOf(item.value)) {
                        item.disabled = true;
                        this.hint = `Max ${this.limit} selections allowed`;
                    }
                });
            } else {
                this.selections.forEach((item) => (item.disabled = false));
                this.hint = "";
            }
        },
    },
};
</script>

import { render, staticRenderFns } from "./AppTabs.vue?vue&type=template&id=d1e826a2&scoped=true"
import script from "./AppTabs.vue?vue&type=script&lang=js"
export * from "./AppTabs.vue?vue&type=script&lang=js"
import style0 from "./AppTabs.vue?vue&type=style&index=0&id=d1e826a2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d1e826a2",
  null
  
)

export default component.exports
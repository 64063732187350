<template>
    <div>
        <mdb-alert :value="alert.msg" :color="alert.type" outlined class="mb-4">
            {{ alert.msg }}
        </mdb-alert>

        <form v-if="forgot">
            <mdb-input label="Username" v-model="username" @keydown.native.enter="resetPassword" autofocus />

            <mdb-row row justify-space-between>
                <mdb-btn color="primary" @click="reset"> Cancel </mdb-btn>

                <mdb-btn color="primary" @click="resetPassword"> Reset Password </mdb-btn>
            </mdb-row>
        </form>

        <form v-else-if="confirm">
            <mdb-input
                v-model="code"
                mask="######"
                label="Confirmation Code"
                @keydown.native.enter="confirmPassword"
                autofocus
            />

            <mdb-input
                v-model="newPassword"
                label="New Password"
                type="password"
                @keydown.native.enter="confirmPassword"
            />

            <mdb-input
                v-model="verifyPassword"
                label="Verify Password"
                type="password"
                @keydown.native.enter="confirmPassword"
            />

            <mdb-row row justify-space-between>
                <mdb-btn color="primary" @click.native="reset"> Cancel </mdb-btn>

                <mdb-btn color="primary" @click.native="confirmPassword">Change Password</mdb-btn>
            </mdb-row>
        </form>

        <form v-else>
            <mdb-input
                v-model="username"
                label="Username"
                autofocus
                @keydown.native.enter="signinClick(initialLogin)"
            />
            <mdb-input
                v-model="password"
                label="Password"
                :append-icon="showPassword ? 'visibility_off' : 'visibility'"
                @click:append="showPassword = !showPassword"
                :type="showPassword ? 'text' : 'password'"
                @keydown.native.enter="signinClick(initialLogin)"
            />

            <mdb-row>
                <mdb-col md6>
                    <mdb-row column justify-center align-start fill-height>
                        <mdb-btn
                            v-if="!initialLogin"
                            class="forgotPassword"
                            type="button"
                            role="text"
                            color="primary"
                            @click.native="switchForm('forgot')"
                        >
                            Forgot Password?
                        </mdb-btn>
                    </mdb-row>
                </mdb-col>

                <mdb-col md12 class="text-md-right">
                    <mdb-btn color="success" @click.native="signIn" v-if="!initialLogin">Sign In</mdb-btn>
                    <mdb-btn color="success" @click.native="changePassword" v-else>Change Password</mdb-btn>
                </mdb-col>
            </mdb-row>
        </form>
    </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { useAuthenticationStore } from "@/store/pinia";

export default {
    name: "SignIn",
    data() {
        return {
            form: "login",
            userInfo: Object,
            username: null,
            password: null,
            newPassword: null,
            verifyPassword: null,
            defaultPassword: null,
            code: null,
            alert: {
                type: null,
                msg: null,
            },
            showPassword: false,
            initialLogin: false,
        };
    },
    mounted() {
        let auth = useAuthenticationStore();

        return {
            auth,
        };
    },
    computed: {
        forgot() {
            return this.form === "forgot";
        },
        confirm() {
            return this.form === "confirm";
        },
    },
    methods: {
        switchForm(form) {
            this.form = form;
            this.alert.msg = null;
            this.alert.type = null;
        },

        reset() {
            this.form = null;
            this.info = null;
            this.code = null;
            this.password = null;
            this.defaultPassword = null;
            this.newPassword = null;
            this.verifyPassword = null;
            this.userInfo = null;
            this.initialLogin = false;
            this.alert.msg = null;
            this.alert.type = null;
        },

        validPassword(strInPassword) {
            var _blnResult = false;

            _blnResult =
                strInPassword.search(/[A-Z]/) >= 0 &&
                strInPassword.search(/[0-9]/) >= 0 &&
                strInPassword.search(/[!@#$%^&*.]/) >= 0 &&
                strInPassword.trim().length >= 8;

            return _blnResult;
        },

        comparePassword(strInNewPassword, strInVerifyPassword) {
            var _blnResult = false;

            var _strMessage = undefined;

            try {
                if (strInNewPassword && strInVerifyPassword) {
                    _blnResult = strInNewPassword === strInVerifyPassword;

                    if (_blnResult) {
                        _blnResult = this.validPassword(strInNewPassword);

                        if (!_blnResult) {
                            throw new Error(
                                "Password must be 8 characters or longer and contain a number, uppercase character and special character"
                            );
                        }
                    } else {
                        throw new Error("Passwords do not match");
                    }
                } else {
                    throw new Error("New password and password verification not supplied");
                }
            } catch (err) {
                _strMessage = err.message;
            }
            return { result: _blnResult, message: _strMessage };
        },

        signIn() {
            this.defaultPassword = null;
            this.initialLogin = false;
            this.userInfo = null;

            Auth.signIn({ username: this.username, password: this.password })
                .then(async (authUser) => {
                    if (authUser.challengeName === "NEW_PASSWORD_REQUIRED") {
                        this.alert.type = "error";
                        this.alert.msg =
                            "You are required to change your default password. Please enter your new password.";

                        this.defaultPassword = this.password;
                        this.password = "";
                        this.initialLogin = true;
                        this.userInfo = authUser;
                    }
                    const user = await Auth.currentAuthenticatedUser();
                    if (user && user.signInUserSession) {
                        const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
                        if (groups && groups.includes("PMO")) {
                            authUser.groups = groups;
                            this.$store.dispatch("signIn", authUser);
                            if (this.auth) {
                                this.auth.signIn(authUser);
                            }
                            this.$router.push("/");
                        } else {
                            this.alert.type = "error";
                            this.alert.msg = "User does not have permission to access this application.";
                        }
                    } else {
                        this.alert.type = "error";
                        this.alert.msg = "User does not have permission to access this application.";
                    }
                    // this.$store.dispatch("signIn", user);
                    // this.$router.push("/");
                })
                .catch((err) => {
                    console.log(err);
                    this.alert.type = "error";
                    this.alert.msg = err.message;
                });
        },

        signinClick() {
            if (this.username && this.password) {
                if (!this.initialLogin) {
                    this.signIn();
                } else {
                    this.changePassword();
                }
            }
        },

        resetPassword() {
            Auth.forgotPassword(this.username)
                .then((data) => {
                    this.switchForm("confirm");
                    this.alert.type = "info";
                    this.alert.msg = `Please check your ${data.CodeDeliveryDetails.AttributeName} for your confirmation code.`;
                })
                .catch((err) => {
                    console.log(err);
                    this.alert.msg = err.message;
                });
        },

        confirmPassword() {
            var _blnResult = false;
            var _strMessage = "";

            var _objPasswordCompare = {};

            try {
                _objPasswordCompare = this.comparePassword(this.newPassword, this.verifyPassword);

                _blnResult = this.code && _objPasswordCompare.result;

                if (_blnResult) {
                    Auth.forgotPasswordSubmit(this.username, this.code, this.newPassword)
                        .then(() => {
                            let username = this.username;
                            this.reset();
                            this.username = username;
                            this.alert.type = "success";
                            this.alert.msg = "Your password has been reset. Please login with your new password.";
                        })
                        .catch((err) => {
                            if (err.code === "ExpiredCodeException") {
                                this.switchForm("forgot");
                            }

                            this.alert.type = "error";
                            this.alert.msg = err.message;
                        });
                } else {
                    if (!this.code) {
                        _strMessage = "Missing code";
                    } else if (!_objPasswordCompare.result) {
                        _strMessage = _objPasswordCompare.message;
                    } else if (this.newPassword !== this.verifyPassword) {
                        _strMessage = "Passwords do not match";
                    }
                }
            } catch (err) {
                _blnResult = !err;
                _strMessage = err.message;
            } finally {
                this.alert.type = _blnResult ? "success" : "error";
                this.alert.msg = _strMessage;
            }
        },

        async changePassword() {
            var _blnResult = false;
            var _strMessage = "";

            if (this.initialLogin) {
                try {
                    if (this.password) {
                        _blnResult = this.validPassword(this.password);

                        if (_blnResult) {
                            await Auth.completeNewPassword(this.userInfo, this.password).then(async (user) => {
                                this.reset();

                                _blnResult = !user;
                                _strMessage = "Password has been successfully changed. Please login again.";
                            });
                        } else {
                            throw new Error(
                                "Password must be 8 characters or longer and contain a number, uppercase character and special character"
                            );
                        }
                    } else {
                        throw new Error("New password must be keyed in");
                    }
                } catch (err) {
                    console.log(err);

                    _blnResult = !err;
                    _strMessage = err.message;
                } finally {
                    this.alert.type = _blnResult ? "success" : "error";
                    this.alert.msg = _strMessage;
                }
            }
        },
    },
};
</script>

import { defineStore, mapStores } from "pinia";
import { io } from "socket.io-client";
export const useAuthenticationStore = defineStore("authentication", {
    state: () => {
        return { signedIn: false, user: null };
    },
    actions: {
        user: () => this.user,
        userDisplayName: () => (this.user ? this.user.username : ""),
        signedIn: () => this.signedIn,
        isAdmin: () => {
            if (this.user && this.user.signInUserSession.idToken.payload["cognito:groups"]) {
                return this.user.signInUserSession.idToken.payload["cognito:groups"].includes("Administrators");
            } else {
                return false;
            }
        },
        signIn(user) {
            this.user = user;
            this.signedIn = true;
            useTSWebsocketStore().connect();
        },

        signOut() {
            this.signedIn = false;
            this.user = null;
            useTSWebsocketStore().disconnect();
        },
    },
});

export const useMessagingStore = defineStore("messaging", {
    state: () => {
        return {
            alertMessage: null,
            alertType: null,
            alertDuration: 5000,
        };
    },
    actions: {
        clearAlertMessage() {
            this.alertMessage = null;
            this.alertType = null;
        },

        setAlertMessage(alert) {
            // TODO break existing alert if exists
            this.alertMessage = alert.message;
            this.alertType = alert.type;
            this.alertDuration = alert.duration;
            if (!alert.persist) {
                setTimeout(() => {
                    this.clearAlertMessage();
                }, this.alertDuration);
            }
        },
    },
});

export const useEventsStore = defineStore("events", {
    state: () => {
        return {
            events: [],
        };
    },
    actions: {
        addEvent(event) {
            this.events.push(event);
        },
    },
});

export const useAnalysisStore = defineStore("analysis", {
    state: () => {
        return {
            events: [],
        };
    },
    actions: {
        addEvent(event) {
            this.events.push(event);
        },
    },
});
export const useTSWebsocketStore = defineStore("tswebsocket", {
    state: () => {
        return {
            socket: null,
            connected: false,
            connecting: false,
        };
    },

    actions: {
        connect() {
            if (!this.connected || !this.socket || !this.connecting) {
                this.socket = io({ path: "/pmo-api/socket.io/" });
                this.bind_events();
                this.connecting = true;
            }
        },
        disconnect() {
            if (this.connected) {
                this.socket.disconnect();
                this.connected = false;
                this.connecting = false;
                this.socket = null;
            }
        },
        restart() {
            this.disconnect();
            this.connect();
        },

        bind_events() {
            this.socket.on("connect", () => {
                this.connected = true;
            });
            this.socket.on("disconnect", () => {
                this.connected = false;
            });
            this.socket.on("events", (data) => {
                data["tms"] = Date.now();
                useEventsStore().addEvent(data);
            });
            this.socket.on("analysis", (data) => {
                data["tms"] = Date.now();
                useAnalysisStore().addEvent(data);
            });
        },
    },
});

export const usePMOStore = defineStore("pmo", {
    state: () => {
        return {
            sessPMOClientID: "",
            sessPMOClientName: "",
            sessPMOClientCarriers: [],
            sessPMOProspectID: "",
            sessPMOSubClientID: "",
            sessPMOSubClientName: "",
            sessPMOProspects: [],
            sessPMOSubClients: [],
            sessPMOEntities: [],
            sessPMOCarrierAccts: [],
            sessPMOLocations: [],
            sessAnalysisBeginDate: "",
            sessAnalysisEndDate: "",
        };
    },
    actions: {
        getSessPMOLocations: () => this.sessPMOLocations,
        getSessPMOProspects: () => this.sessPMOProspects,

        savePMOClientID(PMOClientID) {
            this.sessPMOClientID = PMOClientID;
        },
        savePMOClientName(PMOClientName) {
            this.sessPMOClientName = PMOClientName;
        },
        savePMOSubClientID(PMOSubClientID) {
            this.sessPMOSubClientID = PMOSubClientID;
        },
        savePMOProspectID(PMOProspectID) {
            this.sessPMOProspectID = PMOProspectID;
        },

        // Carriers
        resetPMOClientCarriers() {
            this.sessPMOClientCarriers.length = 0;
        },

        addPMOClientCarrier(PMOClientCarrier) {
            this.sessPMOClientCarriers.push(PMOClientCarrier);
        },

        // Prospects
        resetPMOProspectList() {
            this.sessPMOProspects.length = 0;
        },
        addPMOProspect(PMOProspect) {
            this.sessPMOProspects.push(PMOProspect);
        },
        removePMOProspect(prospect) {
            this.sessPMOProspects.splice(
                this.sessPMOProspects.findIndex((p) => p.sub_client_id === prospect.sub_client_id),
                1
            );
        },

        // Sub-Clients
        resetPMOSubClientList() {
            this.sessPMOSubClients.length = 0;
        },
        addPMOSubClient(PMOSubClient) {
            this.sessPMOSubClients.push(PMOSubClient);
        },
        removePMOSubClient(subclient) {
            this.sessPMOSubClients.splice(
                this.sessPMOSubClients.findIndex((sc) => sc.sub_client_id === subclient.sub_client_id),
                1
            );
        },

        // TODO deprecate: leave concatenation of lists to UI
        // Sub-Clients + Prospects
        resetPMOEntityList() {
            this.sessPMOEntities.length = 0;
        },

        addPMOEntity(PMOEntity) {
            this.sessPMOEntities.push(PMOEntity);
        },

        // Locations
        resetPMOLocationList() {
            this.sessPMOLocations.length = 0;
        },
        addPMOLocation(PMOLocation) {
            this.sessPMOLocations.push(PMOLocation);
        },

        // Analysis
        saveAnalysisBeginDate(AnalysisBeginDate) {
            this.sessAnalysisBeginDate = AnalysisBeginDate;
        },
        saveAnalysisEndDate(AnalysisEndDate) {
            this.sessAnalysisEndDate = AnalysisEndDate;
        },

        savePMOCarrierAccounts(carrier, carrier_accounts) {
            this.sessPMOCarrierAccts.push({ carrier, carrier_accounts });
        },
    },
});

export default {
    computed: {
        ...mapStores(
            usePMOStore,
            useMessagingStore,
            useAuthenticationStore,
            useEventsStore,
            useAnalysisStore,
            useTSWebsocketStore
        ),
    },

    methods: {},
};

<template>
    <div row justify-center align-center>
        <div class="d-flex">
            <mdb-card class="text-xs-center">
                <mdb-card-title class="md-overline justify-center">No Line Items</mdb-card-title>

                <mdb-card-text class="md-body1">Add a few lines so we can calculate stuff.</mdb-card-text>

                truck image

                <mdb-card-footer class="justify-center" v-if="inputform != undefined">
                    <mdb-btn color="primary" dark @click="toggleForm"> + Add a new account</mdb-btn>
                </mdb-card-footer>
            </mdb-card>
        </div>
    </div>
</template>

<script>
export default {
    name: "Empty",
    props: ["inputform"],
    methods: {
        toggleForm() {
            console.log("toggling", this.inputform);

            this.$emit("update:inputform", !this.inputform);
        },
    },
};
</script>

<template>
    <div class="m-2 p-4">
        <div>
            <h3>Clients</h3>
            <hr />
            <mdb-btn outlined class="mb-4" color="orange" @click.native="addSubClient"> ADD NEW CLIENT </mdb-btn>
            <mdb-modal
                :show="showSubClientForm"
                persistent
                max-width="50vw"
                class="mt-10"
                scrollable
                @close="showSubClientForm = false"
            >
                <mdb-modal-header>
                    <span class="headline">
                        <span v-if="subclient">Edit</span>
                        <span v-else>Add New</span>
                        <span> Client </span>
                    </span>
                </mdb-modal-header>
                <SubClientMaint
                    :listSubclient="entities"
                    :defaultToProspect="false"
                    :subclient="subclient"
                    :isActivate="isActivate"
                    :isChangeStatus="isChangeStatus"
                    :discardForm="closeSubClientForm"
                />
            </mdb-modal>

            <mdb-input
                type="checkbox"
                v-model="showProspects"
                id="showprospects"
                name="showprospects"
                label="Show Prospects"
            ></mdb-input>

            <vue-good-table
                v-if="showData"
                v-model="selectedSubClients"
                :columns="headers"
                :rows="entities"
                :options.sync="pagination"
                :row-style-class="rowStyleClassFn"
                :footer-props="{
                    'items-per-page-options': arrPageOptions,
                }"
                item-key="client_id"
            >
                <template slot="table-row" slot-scope="props">
                    <span v-if="props.column.field === 'external_id'">
                        <b>{{ props.row.external_id }}</b>
                    </span>
                    <span v-if="props.column.field === 'sub_client_name'">
                        <b>{{ props.row.sub_client_name }}</b>
                    </span>
                    <span v-if="props.column.field === 'client_id'">{{ props.row.client_id }}</span>
                    <span v-if="props.column.field === 'carrier'" width="10%">
                        <div class="d-flex">
                            <span v-if="Array.isArray(props.row.carrier)">
                                <span v-for="(carrier, idx) in props.row.carrier" :key="idx">
                                    <mdb-avatar style="width: 2.5em" color="grey lighten-1">
                                        <img
                                            class="img-fluid"
                                            v-if="carrier.toUpperCase().trim() == 'FDX'"
                                            :src="require('@/assets/fedex-express-6.svg')"
                                        />
                                        <img
                                            class="img-fluid"
                                            v-if="carrier.toUpperCase().trim() == 'UPS'"
                                            :src="require('@/assets/ups.svg')"
                                        />
                                    </mdb-avatar>
                                </span>
                            </span>
                            <span v-else>
                                <mdb-avatar style="width: 2.5em" color="grey lighten-1">
                                    <img
                                        class="img-fluid"
                                        v-if="props.row.carrier.toUpperCase().trim() == 'FDX'"
                                        :src="require('@/assets/fedex-express-6.svg')"
                                    />
                                    <img
                                        class="img-fluid"
                                        v-if="props.row.carrier.toUpperCase().trim() == 'UPS'"
                                        :src="require('@/assets/ups.svg')"
                                    />
                                </mdb-avatar>
                            </span>
                        </div>
                    </span>
                    <span v-if="props.column.field === 'account'">
                        <div class="d-flex">
                            <span v-if="typeof props.row.carrier !== 'string'">
                                <span v-for="(carrier, idx) in props.row.carrier" :key="idx">
                                    <div class="d-flex">
                                        <span
                                            v-for="(carrierAccts, idxCarrier) in props.row.carrier_accounts"
                                            :key="idxCarrier"
                                        >
                                            <span v-if="idx == idxCarrier">
                                                <span
                                                    ><b>{{ carrier }}:</b></span
                                                >
                                                <span> {{ carrierAccts.carrier_account_num }}&nbsp; </span>
                                            </span>
                                        </span>
                                    </div>
                                </span>
                            </span>
                            <span v-else>
                                <span v-for="(carrier, idx) in props.row.carrier_accounts" :key="idx">
                                    <span> {{ carrier.carrier_account_num }} </span>
                                </span>
                            </span>
                        </div>
                    </span>
                    <span v-if="props.column.field === 'contact_name'">{{ props.row.contact_name }}</span>
                    <span v-if="props.column.field === 'contact_phone'">{{ props.row.contact_phone }}</span>
                    <span v-if="props.column.field === 'contact_email'">{{ props.row.contact_email }}</span>
                    <span v-if="props.column.field === 'active'">
                        <mdb-switch
                            v-if="!props.row.prospect"
                            label="Active?"
                            :checked="props.row.active"
                            onLabel=""
                            offLabel=""
                            @getValue="changeSubClientStatus(props.row)"
                        />
                    </span>
                    <span v-if="props.column.field === 'prospect'">
                        <mdb-btn
                            v-if="props.row.prospect"
                            color="outline-success"
                            dark
                            outlined
                            size="sm"
                            @click.native="activateSubClientModal(props.row)"
                            >Activate Prospect</mdb-btn
                        >
                    </span>
                    <span v-if="props.column.field === 'notes'">{{ props.row.notes }}</span>
                    <span v-if="props.column.field === 'actions'">
                        <mdb-icon
                            icon="edit"
                            class="mb-2"
                            color="success"
                            size="lg"
                            @click.native="editSubClient(props.row)"
                            title="Edit Client"
                            style="cursor: pointer"
                        ></mdb-icon>

                        <mdb-icon
                            icon="trash"
                            color="danger"
                            size="lg"
                            @click.native="confirmDelete(props.row)"
                            title="Delete Client"
                            style="cursor: pointer"
                        ></mdb-icon>
                    </span>
                </template>
            </vue-good-table>
            <mdb-modal
                :show="dialogDelete"
                persistent
                max-width="290"
                class="mt-10"
                @close="dialogDelete = false"
                v-if="deleting"
            >
                <mdb-modal-header class="headline"> Delete Client? </mdb-modal-header>
                <mdb-modal-body
                    >Are you sure you want to delete this client?
                    <span>{{ deleting.sub_client_name }}</span>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="red darken-1" text @click.native="dialogDelete = false"> Cancel </mdb-btn>
                    <mdb-btn color="green darken-1" text @click.native="deleteSubClient(deleting)"> Continue </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal
                :show="dialogActivate"
                persistent
                max-width="290"
                class="mt-10"
                @close="dialogActivate = false"
                v-if="activating && activating.external_id !== ''"
            >
                <mdb-modal-header class="headline"> Activate Prospect </mdb-modal-header>
                <mdb-modal-body>
                    Enter a client ID for the client.
                    <mdb-input
                        type="text"
                        v-model="activating.external_id"
                        id="enterClientId"
                        name="enterClientId"
                        label="Client ID"
                    ></mdb-input>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="red darken-1" text @click.native="dialogActivate = false"> Cancel </mdb-btn>
                    <mdb-btn color="green darken-1" text @click.native="changeSubClientStatus(activating, true)">
                        Continue
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
            <mdb-modal
                :show="dialogDeactivate"
                persistent
                max-width="290"
                class="mt-10"
                @close="dialogDeactivate = false"
                v-if="activating && activating.external_id !== ''"
            >
                <mdb-modal-header class="headline"> Move to prospect</mdb-modal-header>
                <mdb-modal-body>
                    <p>Are you sure you want to move this client back as a prospect?</p>
                    <span>{{ activating.sub_client_name }}</span>
                </mdb-modal-body>
                <mdb-modal-footer>
                    <mdb-btn color="red darken-1" text @click.native="dialogDeactivate = false"> Cancel </mdb-btn>
                    <mdb-btn color="green darken-1" text @click.native="changeSubClientStatus(activating, false)">
                        Continue
                    </mdb-btn>
                </mdb-modal-footer>
            </mdb-modal>
        </div>
    </div>
</template>

<script>
import SubClientMaint from "@/components/common/objSubClientMaint.vue";
import Empty from "@/components/common/Empty.vue";
import PMO_Global from "@/assets/js/PMO_Global";

export default {
    name: "SubClientAdmin",
    props: {
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
    },

    components: {
        SubClientMaint,
        Empty,
    },
    data: () => {
        return {
            deleting: null,
            dialogDeactivate: false,
            activating: {
                external_id: "",
            },
            headers: [
                { label: "Client ID", field: "external_id", sortable: false, class: "grey lighten-3" },
                { label: "Client Name", field: "sub_client_name", sortable: false, class: "grey lighten-3" },
                { label: "3RO ID", field: "client_id", sortable: false, class: "grey lighten-3" },
                { label: "Carriers", field: "carrier", sortable: false, class: "grey lighten-3" },
                { label: "Carrier Account Numbers", field: "account", sortable: false, class: "grey lighten-3" },
                { label: "Contact Information", field: "contact_name", sortable: false, class: "grey lighten-3" },
                { label: "Phone", field: "contact_phone", sortable: false, class: "grey lighten-3" },
                { label: "Email Address", field: "contact_email", sortable: false, class: "grey lighten-3" },
                { label: "Active?", field: "active", sortable: false, class: "grey lighten-3" },
                { label: "Client/Prospect", field: "prospect", sortable: false, class: "grey lighten-3" },
                { label: "Notes", field: "notes", sortable: false, class: "grey lighten-3" },
                { label: "", field: "actions", sortable: false, class: "grey lighten-3" },
            ],

            dialogDelete: false,
            dialogActivate: false,
            pagination: {
                itemsPerPage: -1,
                page: 1,
            },

            arrPageOptions: [10, 15, 25, { text: "All", value: -1 }],

            // SubClient Maintenance Component Actions
            isActivate: false,
            isChangeStatus: false,
            isDelete: false,
            // SubClient Maintenance Component Actions

            selectedSubClients: [],

            showProspects: false,

            showSubClientForm: false,
            showData: false,
            subclient: null,

            hasError: false,
            msgText: null,
        };
    },

    computed: {
        entities() {
            let entities = [];
            if (this.showProspects) {
                // eslint-disable-next-line
                entities = this.clients.map((ele) => {
                    if (ele.mapping_reference_field) {
                        ele.carrier_accounts = [{ carrier_account_num: "Not Applicable" }];
                    }
                    return ele;
                });

                return this.prospects
                    .sort(this.propertyComparator("sub_client_name"))
                    .concat(entities)
                    .sort(this.propertyComparator("is_prospect"));
            } else {
                // eslint-disable-next-line
                if (this.clients) {
                    entities = this.clients.map((ele) => {
                        if (ele.mapping_reference_field) {
                            ele.carrier_accounts = [{ carrier_account_num: "Not Applicable" }];
                        }
                        return ele;
                    });
                    this.calculateReports();
                    return entities.sort(this.propertyComparator("sub_client_name"));
                } else return [];
            }
        },
    },

    methods: {
        async calculateReports() {
            for (const ele of this.clients) {
                if (!ele.prospect) {
                    let result = await this.hasPostedReports(ele.client_id, ele.carrier);
                    ele.has_reports = result.data;
                } else {
                    ele.has_reports = false;
                }
            }
        },
        addSubClient() {
            this.subclient = null;
            this.showSubClientForm = true;
        },
        rowStyleClassFn(row) {
            return row.prospect ? "prospect-row" : "";
        },
        confirmDelete(row) {
            this.dialogDelete = true;
            this.deleting = row;
        },
        closeSubClientForm() {
            this.isActivate = false;
            this.isChangeStatus = false;
            this.isDelete = false;

            this.showSubClientForm = false;
            this.getClients();
        },

        editSubClient(client) {
            this.subclient = Object.assign({}, this.subclient, client);

            if (!Array.isArray(client.carrier)) {
                this.subclient.carrier = [];

                this.subclient.carrier.push(client.carrier);
            }

            this.showSubClientForm = true;
        },

        activateSubClientModal(client) {
            this.activating = client;
            this.dialogActivate = true;
        },
        deactivateSubClientModal(client) {
            this.activating = client;
            this.dialogDeactivate = true;
        },

        async deleteSubClient(client) {
            try {
                await this.$httpNew.delete("client", {
                    data: {
                        client_id: client.client_id,
                    },
                });
                this.$notify.success({ message: `${client.sub_client_name} has been deleted`, timeOut: 9000 });
                await this.getClients();
            } catch (e) {
                this.$notify.error({ message: `Client was not deleted ${e}`, timeOut: 9000 });
            }
            this.dialogDelete = false;
        },

        async changeSubClientStatus(client, activate) {
            this.subclient = client;
            this.subclient.active = !this.subclient.active;
            let carriers = Array.isArray(this.subclient.carrier) ? this.subclient.carrier : [this.subclient.carrier];
            let prospect = false;
            if (this.subclient.prospect) prospect = this.subclient.prospect;
            for (const c of carriers) {
                try {
                    let data = {
                        carrier: c,
                        active: this.subclient.prospect ? true : this.subclient.active,
                        client_id: this.subclient.client_id,
                        external_id: this.subclient.external_id,
                        prospect,
                        activate,
                    };
                    await this.$httpNew.patch("client", data);

                    this.$notify.success({
                        message: `${this.subclient.sub_client_name} has been ${
                            this.subclient.active ? "activated" : "deactivated"
                        }`,
                        timeOut: 9000,
                    });
                } catch (e) {
                    this.$notify.error({ message: e.message, timeOut: 9000 });
                }
            }
            await this.getClients();
            this.showProspects = false;
            this.dialogActivate = false;
            this.dialogDeactivate = false;
        },
        async hasPostedReports(client_id, carrier) {
            let startDate = "2023-01-01";
            let endDate = new Date().toISOString().slice(0, 10);
            let body = {
                subclient_id: client_id,
                carrier: carrier,
                start_date: startDate,
                end_date: endDate,
            };
            return await this.$http.post(`/CheckPostedReports3RO`, body);
        },
    },
    async mounted() {
        this.showData = true;
    },
};
</script>

<style>
th {
    text-align: left;
}
.prospect-row {
    background-color: #e5f6eb;
    color: rgba(0, 0, 0, 0.38);
}
.switch label input[type="checkbox"]:checked + .lever:after {
    background-color: #ff9800 !important;
}
.switch label input[type="checkbox"]:checked + .lever {
    background-color: #fac679 !important;
}
</style>

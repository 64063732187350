<template>
    <div class="h-100 overflow-hidden">
        <div v-mdb-resize:start="checkWidth" v-if="isAuthenticated">
            <mdb-side-nav-2
                v-model="show"
                color="grey"
                dark
                :over="over"
                :sidenav-class="['elegant-color', 'white-text']"
            >
                <div slot="header" class="mt-10"></div>

                <mdb-navbar
                    slot="nav"
                    tag="div"
                    position="top"
                    color="elegant-color"
                    class="mt-0 pt-0"
                    dark
                    :toggler="false"
                    :style="{ zIndex: '1061' }"
                    ><mdb-navbar-brand href="/">
                        <img class="mt-2 ml-4" :src="require('@/assets/TransImpact_logo.png')" height="45" />
                    </mdb-navbar-brand>
                    <mdb-breadcrumb class="mt-4">
                        <mdb-breadcrumb-item v-for="crumb in breadcrumbs" v-if="crumb.text" :key="crumb.text">{{
                            crumb.text
                        }}</mdb-breadcrumb-item>
                    </mdb-breadcrumb>
                    <mdb-navbar-nav right class="mt-4">
                        <mdb-dropdown tag="li" class="nav-item">
                            <mdb-dropdown-toggle tag="a" navLink color="light-grey" slot="toggle" waves-fixed
                                >{{ companyName }}</mdb-dropdown-toggle
                            >
                            <mdb-dropdown-menu right>
                                <mdb-dropdown-item v-on:click="logout()">
                                    <mdb-icon icon="power-off" class="mr-2"></mdb-icon>Sign Out &nbsp;
                                </mdb-dropdown-item>
                                <div class="dropdown-divider"></div>
                                <mdb-dropdown-item class="disabled font-italic font-tiny">Logged in as {{ userName }}
                                </mdb-dropdown-item>
                            </mdb-dropdown-menu>
                        </mdb-dropdown>
                    </mdb-navbar-nav>
                </mdb-navbar>
                <div slot="main">
                    <mdb-container app>
                        <Alert></Alert>
                        <router-view ></router-view>
                    </mdb-container>
                    <mdb-footer app fixed inset> </mdb-footer>
                </div>
            </mdb-side-nav-2>
        </div>
        <div v-else>
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
import { mdbResize } from "mdbvue";
import { AmplifyEventBus } from "aws-amplify-vue";
import { Auth } from "aws-amplify";
import Alert from "@/components/common/Alert";

export default {
    components: {
        Alert,
    },
    directives: {
        mdbResize,
    },
    data: function () {
        return {
            breadcrumbs: null,
            show: false,
            over: false,
        };
    },

    created() {
        AmplifyEventBus.$on("authState", (info) => {
            console.log("AuthState", info);
            if (info === "signedOut") {
                this.$store.dispatch("signOut");
                this.$router.push("/login");
            }
        });
    },
    methods: {
        checkWidth() {
            this.over = window.innerWidth < 900;
        },
        logout() {
            Auth.signOut()
                .then(
                    this.$store.dispatch("signOut").then((response) => {
                        this.$router.push("/login");
                    })
                )
                .catch((err) => {
                    this.alert.type = "error";
                    this.alert.msg = err.message;
                });
        },
        updateBreadCrumbs() {
            this.breadcrumbs = this.$router.currentRoute.matched.map((r) => {
                r.text = r.meta.title;
                return r;
            });
        },
    },
    mounted() {
        this.updateBreadCrumbs();
    },
    watch: {
        $route(to, from) {
            this.updateBreadCrumbs();
        },
    },

    computed: {
        userName() {
            return this.$store.getters.userDisplayName;
        },
        companyName() {
          return this.$store.state.pmo.pmo.sessPMOClientName
        },
        isAuthenticated() {
            return this.$store.getters.signedIn;
        },
    },
};
</script>

<style>
.mt-10 {
    margin-top: 72px;
}
.h-100vh {
    height: 100vh;
}
.overflow-scroll {
    overflow: scroll !important;
}
.font-tiny {
  font-size: 8px;
}
</style>

<template>
    <div class="m-4 h-100">
        <h3>Profiles</h3>
        <!--        <button @mouseup="showHistories">Get histories</button>-->
        <div v-if="selectedSubClient">
            <mdb-modal
                :show="show_histories_modal"
                persistent
                class="mt-10"
                size="fluid"
                @close="show_histories_modal = false"
            >
                <mdb-modal-header>
                    <h2>Historical Changes</h2>
                </mdb-modal-header>
                <mdb-modal-body class="m-sm">
                    <ProfileHistory
                        :msg="'Hellp'"
                        :is_markup="is_markup"
                        :packages="packages_histories"
                        :accessorials="acc_histories"
                    ></ProfileHistory>
                </mdb-modal-body>
            </mdb-modal>

            <mdb-modal
                :show="show_reports_modal"
                persistent
                class="mt-10"
                size="fluid"
                @close="show_reports_modal = false"
            >
                <mdb-modal-header>
                    <h2 class="modal-title">
                        Reports Posting
                        <br />
                        <small>Preview, update, and finalize pending reports.</small>
                    </h2>
                </mdb-modal-header>
                <mdb-modal-body class="m-sm">
                    <ReportPosting
                        :selectedSubclient="selectedSubClient"
                        :selectedCarrier="selectedCarrier"
                        :startDate="startDate"
                        :endDate="endDate"
                    />
                </mdb-modal-body>
            </mdb-modal>

            <mdb-modal
                :show="show_profile_version_modal && is_markup"
                persistent
                class="mt-10"
                size="fluid"
                @close="show_profile_version_modal = false"
            >
                <mdb-modal-header></mdb-modal-header>
                <mdb-modal-body class="m-sm">
                    <ProfileVersionMarkup

                        :propSubClient="selectedSubClient.client_id"
                        :propCarrier="selectedCarrier ? selectedCarrier.value : null"
                        :propAnalysis="false"

                    />
                </mdb-modal-body>
            </mdb-modal>
            <mdb-modal
                :show="show_profile_version_modal && !is_markup"
                persistent
                class="mt-10"
                size="fluid"
                @close="show_profile_version_modal = false"
            >
                <mdb-modal-header></mdb-modal-header>
                <mdb-modal-body class="m-sm">
                    <ProfileVersionDiscount

                        :propSubClient="selectedSubClient.client_id"
                        :propCarrier="selectedCarrier ? selectedCarrier.value : null"
                        :propAnalysis="false"

                    />
                </mdb-modal-body>
            </mdb-modal>
            <mdb-modal :show="show_delete_profile_confirmation" persistent class="mt-10" max-width="50vw">
                <mdb-modal-header>
                    <h2 class="modal-title">Delete Profile Version?</h2>
                </mdb-modal-header>
                <mdb-modal-body class="m-sm">
                    <p>You you sure you want to delete this profile version?</p>
                    <br />

                    <mdb-btn color="danger" class="float-right" @click.native="applyDelete()"
                        >Delete Profile Version</mdb-btn
                    >
                    <mdb-btn
                        color="primary"
                        class="float-right mr-2"
                        @click.native="show_delete_profile_confirmation = false"
                        >Cancel</mdb-btn
                    >
                </mdb-modal-body>
            </mdb-modal>
        </div>

        <em v-if="!selectedSubClient">**Select a client to view a profile</em>
        <hr />
        <div class="d-flex">
            <mdb-dropdown v-if="subclients">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedSubClient ? selectedSubClient.sub_client_name : "Select Client"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!subclients.length">Loading Clients</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="entity in subclients"
                        :key="entity.client_id"
                        :active="selectedSubClient && entity.sub_client_name === selectedSubClient.sub_client_name"
                        @click.native="changeSubClient(entity)"
                        >{{ entity.sub_client_name }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-dropdown v-if="carriers" class="mx-2">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">{{
                    selectedCarrier ? selectedCarrier.text : "Select Carrier"
                }}</mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!carriers.length">Loading Carriers</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="carrier in carriers"
                        :key="carrier.value"
                        :active="selectedCarrier && carrier.value === selectedCarrier.value"
                        @click.native="changeCarrier(carrier)"
                        >{{ carrier.text }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
            <mdb-btn
                outlined
                class="mt-0"
                color="orange"
                v-if="selectedSubClient && selectedCarrier"
                @click.native="showHistories()"
            >
                Show Past Changes
            </mdb-btn>
            <mdb-btn
                outlined
                class="mt-0"
                color="orange"
                v-if="selectedSubClient && selectedCarrier && !has_posted_reports"
                @click.native="showClientReports()"
            >
                Show Reports
            </mdb-btn>
            <mdb-btn
                outlined
                class="mt-0"
                color="orange"
                v-if="selectedSubClient && selectedCarrier"
                @click.native="newProfileVersionModal()"
            >
                Create Profile Version
            </mdb-btn>
        </div>
        <div class="d-flex">
            <div
                class="w-100"
                v-if="
                    selectedSubClient && (selectedSubClient.is_markup === false || selectedSubClient.is_markup === null)
                "
            >
                <ProfileRates
                    :propSubClient="selectedSubClient.client_id"
                    :propCarrier="selectedCarrier ? selectedCarrier.value : null"
                    :propAnalysis="false"
                />
            </div>
            <div class="flex-fill" v-else-if="selectedSubClient && selectedSubClient.is_markup === true">
                <ProfileMarkup
                    v-bind:propSubClient="selectedSubClient.client_id"
                    v-bind:propCarrier="selectedCarrier ? selectedCarrier.value : null"
                    v-bind:propAnalysis="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
// import PMO_Global from "@/assets/js/PMO_Global";
import ProfileRates from "@/components/common/objProfileRates.vue";
import ProfileMarkup from "@/components/common/objProfileMarkup.vue";
import ProfileHistory from "@/components/common/ProfileHistory.vue";
import ReportPosting from "@/components/common/ReportPosting.vue";
import ProfileVersionMarkup from "@/components/common/objProfileVersionMarkup.vue";
import ProfileVersionDiscount from "@/components/common/objProfileVersionDiscount.vue"

export default {
    name: "Profiles",

    props: {
        prospects: Array,
        clients: Array,
        combined: Array,
        locations: Array,
        getClients: Function,
    },
    components: {
        ProfileHistory,
        ProfileRates,
        ProfileMarkup,
        ReportPosting,
        ProfileVersionMarkup,
        ProfileVersionDiscount
    },

    data() {
        return {
            show_delete_profile_confirmation: false,
            packages_histories: [],
            acc_histories: [],
            pld_hold: null,
            carrier_data_hold: null,
            invoice_summary_hold: null,
            hasError: false,
            errMsgArray: [],
            carriers: [],
            selectedSubClient: null,
            selectedCarrier: null,
            startDate: null,
            endDate: null,
            show_histories_modal: false,
            show_reports_modal: false,
            show_profile_version_modal: false,
            has_posted_reports: false,
            is_markup: false,
        };
    },

    computed: {
        subclients() {
            return this.clients;
        },
        isAdmin() {
            return this.$store.getters.isAdmin;
        },
    },
    mounted() {
        this.$root.$on("profile-version-delete-confirmation-request", (thing) => {
            this.show_delete_profile_confirmation = true;
        });
    },
    methods: {
        applyDelete() {
            this.$root.$emit("profile-version-delete", "delete");
            this.show_delete_profile_confirmation = false;
        },
        async satToSun() {
            var curr = new Date();
            var firstDay = new Date(curr.setDate(curr.getDate() - curr.getDay() - 7));
            var lastDay = new Date(curr.setDate(curr.getDate() - curr.getDay() + 6));
            var firstDayStr = [
                firstDay.getFullYear(),
                ("0" + (firstDay.getMonth() + 1)).slice(-2),
                ("0" + firstDay.getDate()).slice(-2),
            ].join("-");
            var lastDayStr = [
                lastDay.getFullYear(),
                ("0" + (lastDay.getMonth() + 1)).slice(-2),
                ("0" + lastDay.getDate()).slice(-2),
            ].join("-");
            return {
                firstDay: firstDayStr,
                lastDay: lastDayStr,
            };
        },
        async showHistories() {
            this.packages_histories = this.getPackageHistories();
            this.acc_histories = this.getAccHistories();
            this.show_histories_modal = true;
        },
        async getAccHistories() {
            return this.$httpNew.get(
                `/profileAccessorialsHistory?sub_client=${this.selectedSubClient.client_id}&carrier=${this.selectedCarrier.value}`
            );
        },
        async getPackageHistories() {
            return this.$httpNew.get(
                `/profilePackageHistory?sub_client=${this.selectedSubClient.client_id}&carrier=${this.selectedCarrier.value}`
            );
        },
        async showClientReports() {
            let dates = await this.satToSun();
            this.startDate = dates.firstDay;
            this.endDate = dates.lastDay;
            this.show_reports_modal = true;
        },
        async hasPostedReports() {
            let dates = await this.satToSun();
            this.startDate = dates.firstDay;
            this.endDate = dates.lastDay;
            let body = {
                subclient_id: this.selectedSubClient.client_id,
                carrier: this.selectedCarrier.value,
                start_date: this.startDate,
                end_date: this.endDate,
            };
            return await this.$http.post(`/CheckPostedReports3RO`, body).data;
        },
        async getPLDHold() {
            return await this.$httpNew.get(
                `/pldReportHold?subclient_id=${this.selectedSubClient.client_id}&carrier=${this.selectedCarrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async getInvoiceSummaryHold() {
            return await this.$httpNew.get(
                `/invoiceSummaryReportHold?subclient_id=${this.selectedSubClient.client_id}&carrier=${this.selectedCarrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async getCarrierDataHold() {
            return await this.$httpNew.get(
                `/carrierDataReportHold?subclient_id=${this.selectedSubClient.client_id}&carrier=${this.selectedCarrier.value}&start_date=${this.startDate}&end_date=${this.endDate}`
            );
        },
        async changeSubClient(subclient) {
            this.selectedSubClient = subclient;
            if (typeof subclient.carrier !== "string") {
                this.carriers = subclient.carrier.map((c) => {
                    c.text = c;
                    c.value = c;
                    return c;
                });
            } else {
                this.carriers = [
                    {
                        text: subclient.carrier,
                        value: subclient.carrier,
                    },
                ];
            }
            this.selectedCarrier = this.carriers[0];
            this.is_markup = subclient.is_markup;

            this.has_posted_reports = await this.hasPostedReports();
        },
        async newProfileVersionModal() {
            this.show_profile_version_modal = true;
        },
    },
};
</script>

import Vue from "vue";
import Vuex from "vuex";
import pmo from "./pmo";
import messaging from "./modules/messaging";
import authentication from "./modules/authentication";

Vue.use(Vuex);

const debug = process.env.VUE_APP_NODE_ENV !== "production";

export default new Vuex.Store({
    namespaced: true,
    modules: {
        pmo: {
            namespaced: true,
            modules: {
                pmo,
            },
        },
        messaging,
        authentication,
    },
    strict: debug,
    state: {
        breadcrumbs: [],
    },
    mutations: {},
    actions: {},
});


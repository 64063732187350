<template>
    <div>
        <ValidationObserver ref="objs" v-slot="{ invalid }">
            <mdb-modal-body>
                <mdb-container grid-list-md>
                    <mdb-row wrap>
                        <mdb-col col="6">
                            <ValidationProvider
                                name="3PL Client ID"
                                :rules="
                                    !prospectOnly
                                        ? {
                                              clientExists: { isUpdate: isEditing, subClients: listSubclient },
                                          }
                                        : []
                                "
                                v-slot="{ errors, valid }"
                                v-if="!prospectOnly"
                            >
                                <mdb-input
                                    type="text"
                                    v-model="form.external_id"
                                    label="Client ID"
                                    :disabled="isEditing"
                                    :error-messages="errors"
                                    :success="valid"
                                    filled
                                />
                            </ValidationProvider>
                        </mdb-col>

                        <mdb-col col="6">
                            <ValidationProvider name="Name" rules="required" v-slot="{ errors, valid }">
                                <mdb-input
                                    type="text"
                                    v-model="form.sub_client_name"
                                    v-bind:label="`${prospectOnly ? 'Prospect' : 'Client'} Name*`"
                                    :error-messages="errors"
                                    :success="valid"
                                    filled
                                ></mdb-input>
                            </ValidationProvider>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="6">
                            <mdb-input
                                type="text"
                                v-model="form.contact_name"
                                label="Contact Information"
                                filled
                            ></mdb-input>
                        </mdb-col>

                        <mdb-col col="6">
                            <mdb-input type="text" v-model="form.contact_phone" label="Phone Number" filled />
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="6">
                            <ValidationProvider name="E-Mail" rules="email" v-slot="{ errors, valid }">
                                <mdb-input
                                    type="text"
                                    v-model="form.contact_email"
                                    label="E-mail"
                                    :error-messages="errors"
                                    :success="valid"
                                    filled
                                />
                            </ValidationProvider>
                        </mdb-col>

                        <mdb-col col="6">
                            <mdb-input type="text" v-model="form.notes" label="Notes" filled />
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!prospectOnly">
                        <mdb-col col=6>
                            <mdb-input type="text" v-model="form.psi_customer_name" label="PSI Customer ID" :disabled="form.is_psi_customer == undefined || !form.is_psi_customer" id = "psiClientID"/>  </mdb-col>
                        <mdb-col col="6">
                            <mdb-input type="checkbox" label = "Customer is PSI Client" v-model="form.is_psi_customer" id="isPSIClient"/>                  
                        </mdb-col>
                    </mdb-row>
                    <mdb-row>
                        <mdb-col col="6">
                            <mdb-select
                                v-model="selectedCarriers"
                                label="Carriers*"
                                :options="carriers"
                                selectAll
                                multiple
                            >
                            </mdb-select>
                        </mdb-col>
                        <mdb-col col="6">
                            <mdb-row>
                                <mdb-col col="12">
                                    <mdb-input
                                        type="checkbox"
                                        label="Customer uses fixed markup"
                                        v-model="form.is_markup"
                                        :name="'markup_checkbox'"
                                        :id="'markup_checkbox'"
                                    />
                                </mdb-col>
                                <mdb-col col="12" v-if="!prospectOnly">
                                    <mdb-input
                                        type="checkbox"
                                        label="Use Alternate Mapping"
                                        v-model="useAltMapping"
                                        name="alt_mapping"
                                        id="alt_mapping"
                                    />
                                </mdb-col>
                            </mdb-row>
                        </mdb-col>
                    </mdb-row>
                    <mdb-row v-if="!useAltMapping" class="w-100 max-height-300">
                        <mdb-col col="12">
                            <div v-for="(carrier, idx) in selectedCarriers" :key="idx">
                                <div v-if="carrier.selected" class="scrollable">
                                    <span class="headline3" v-if="!isAnalysis">
                                        {{ carrier.text.toUpperCase().trim() }} Carrier Accounts
                                    </span>
                                    <mdb-input
                                        type="checkbox"
                                        :id="'import-' + carrier.value"
                                        :name="'import-' + carrier.value"
                                        v-model="carrier.showImport"
                                        label="Show Import"
                                    />
                                    <div v-if="carrier.showImport">
                                        <mdb-input
                                            type="text-area"
                                            v-model="carrier.importedAccounts"
                                            label="Copy/Paste Account Numbers (space,comma, or line separated)"
                                        ></mdb-input>
                                        <mdb-btn
                                            color="success"
                                            v-if="carrier.importedAccounts && carrier.importedAccounts !== ''"
                                            @click="importCarrierAccts(carrier)"
                                            >Import
                                        </mdb-btn>
                                        <mdb-btn color="success" v-else disabled>Import </mdb-btn>
                                    </div>
                                    <div
                                        v-for="acct in carrier.carrier_accounts"
                                        class="p-1"
                                        v-bind:key="acct.carrier_account_num"
                                    >
                                        {{ acct.carrier_account_num }}
                                        <mdb-icon
                                            icon="times"
                                            color="danger"
                                            @click.native="removeAccount(acct)"
                                        ></mdb-icon>
                                    </div>
                                    <div class="input-group margin-10">
                                        <mdb-input type="text" placeholder="Add Account" v-model="carrier.addedAccount">
                                            <mdb-btn
                                                color="outline-primary"
                                                group
                                                slot="append"
                                                @click="addAccount(carrier)"
                                                icon="plus"
                                            ></mdb-btn>
                                        </mdb-input>
                                    </div>
                                </div>
                            </div>
                        </mdb-col>
                    </mdb-row>
                </mdb-container>
            </mdb-modal-body>

            <mdb-modal-footer>
                <mdb-spinner indeterminate color="blue" v-if="loadingSaveClient"></mdb-spinner>
                <mdb-btn color="orange" text @click="closeForm" v-if="!loadingSaveClient">Cancel</mdb-btn>
                <mdb-btn
                    color="primary"
                    text
                    @click="saveForm"
                    :disabled="invalid || (!prospectOnly && form.external_id === undefined) || loadingSaveClient"
                >
                    {{ isEditing ? "Save" : "Add" }}
                </mdb-btn>
            </mdb-modal-footer>
        </ValidationObserver>
    </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { rules } from "@/assets/js/vee-validate";

export const ACTION_CREATE = 1;
export const ACTION_UPDATE = 2;

export default {
    name: "SubClientMaintenance",
    props: {
        listSubclient: [],
        defaultToProspect: Boolean,
        subclient: {
            account_type: {
                type: String,
                // only acceptable non-0-length value is 'prospect'
                validator: (value) => !value || value.toLowerCase() === "prospect",
            },
            external_id: String,
            client_id: String || null,
            sub_client_name: String,
            carrier: [String, Array],
            carrier_accounts: [],
            notes: String,
            active: Boolean,
            contact_name: String,
            contact_phone: String,
            contact_email: String,
            is_psi_customer: Boolean,
            psi_customer_name: String,
        },

        prospectOnly: {
            type: Boolean,
            default: false,
        },

        isActivate: Boolean,
        isChangeStatus: Boolean,

        discardForm: Function,
    },

    data() {
        return {
            accountMapping: {},
            carrierAltFieldStates: {
                FDX: false,
                UPS: false,
            },
            actionCreate: ACTION_CREATE,
            actionUpdate: ACTION_UPDATE,

            rules,

            selectedCarriers: [
                {
                    text: "UPS",
                    value: "UPS",
                    importedAccounts: null,
                    showImport: false,
                    carrier_accounts: [],
                },
                {
                    text: "FDX",
                    value: "FDX",
                    importedAccounts: null,
                    showImport: false,
                    carrier_accounts: [],
                },
            ],

            subclientform: false,
            form: {},
            hasError: false,
            msgText: "",
            msgDuration: 3000,
            isEditing: false,
            loadingSaveClient: false,
            useAltMapping: false,
            altMapping: null,
        };
    },

    components: {
        ValidationObserver,
        ValidationProvider,
    },

    mounted() {
        this.listSubclient.forEach((sub) => {
            sub.accounts.forEach((acc) => {
                this.accountMapping[acc.carrier] = acc.ref_field;
            });
        });

        if (this.carriers && this.carriers.length === 1) {
            this.selectedCarriers = this.selectedCarriers.map((c) => {
                c.text = c;
                c.value = c;
                if (this.carriers.find((a) => a === c.value)) {
                    c.selected = true;
                }
                return c;
            });
        }

        if (this.subclient && this.subclient.account_type !== "prospect") {
            this.isEditing = true;
            this.populateSubclient();
            if (this.subclient.mapping_reference_field) {
                this.subclient.carrier.forEach((car) => {
                    this.carrierAltFieldStates[car] = true;
                    this.useAltMapping = true;
                });
            }
        }
    },

    computed: {
        carriers() {
            return this.$store.state.sessPMOClientCarriers;
        },

        isAnalysis() {
            return (
                (this.form.account_type && this.form.account_type.toLowerCase() === "prospect") || this.form.prospect
            );
        },
    },

    watch: {
        // copy prop to form on change
        subclient() {
            this.populateSubclient();
        },

        isActivate() {
            if (this.isActivate) {
                this.activateProspect();
            }
        },

        isChangeStatus() {
            if (this.isChangeStatus) {
                this.changeSubClientStatus();
            }
        },
    },

    methods: {
        importCarrierAccts(carrier) {
            let accounts;
            if (carrier.importedAccounts.match(" ")) {
                accounts = carrier.importedAccounts.split(" ");
            }
            if (carrier.importedAccounts.match("\t")) {
                accounts = carrier.importedAccounts.split("\t");
            }
            if (carrier.importedAccounts.match("\n")) {
                accounts = carrier.importedAccounts.split("\n");
            }
            if (carrier.importedAccounts.match(",")) {
                accounts = carrier.importedAccounts.split(",");
            }
            for (const acct of accounts) {
                if (acct !== "") {
                    carrier.carrier_accounts.push({
                        carrier_account_num: acct.trim(),
                        carrier: carrier.value,
                        subclient_id: this.form.client_id,
                    });
                }
            }
        },
        populateSubclient() {
            this.form = Object.assign({}, this.form, this.subclient);
            this.form.subclientID = this.subclient.client_id;
            this.form.threeplClientID = this.subclient.master_client_id;

            if (this.form.carrier !== undefined) {
                this.selectedCarriers = this.selectedCarriers.map((c) => {
                    let carrier = this.form.carrier.find((a) => a === c.value);
                    if (carrier) {
                        c.selected = true;
                        c.carrier_accounts = this.populatePMOCarrierAccountDropDownList(c.value);
                    }

                    return c;
                });
            }
        },
        populatePMOCarrierAccountDropDownList(carrier) {
            const accounts = this.form.carrier_accounts.filter((c) => c.carrier === carrier);
            if (accounts) {
                return accounts;
            } else return [];
        },

        async removeAccount(item, override = false) {
            for (const c of this.selectedCarriers) {
                let i = 0;
                for (const a of c.carrier_accounts) {
                    if (a.carrier_account_num === item.carrier_account_num) {
                        c.carrier_accounts.splice(i, 1);
                    }
                    i++;
                }
            }
            if (this.isEditing || override) {
                let data = {
                    carrier: item.carrier,
                    carrier_account_num: item.carrier_account_num,
                    subclient_id: this.form.client_id,
                };

                await this.$httpNew.delete("carrier-account", { data });
            }
        },
        addAccount(carrier) {
            if (carrier.addedAccount && carrier.addedAccount !== "") {
                carrier.carrier_accounts.push({
                    carrier_account_num: carrier.addedAccount,
                    carrier: carrier.value,
                    subclient_id: this.form.client_id,
                });
            } else {
                this.$notify.error({ message: `Cannot enter a blank carrier account number`, timeOut: 9000 });
            }
            carrier.addedAccount = null;
        },

        saveForm() {
            if (this.isEditing) {
                this.updateSubClient();
            } else {
                this.addSubClient();
            }
        },

        async closeForm() {
            this.form = null;
            this.selectedCarriers = null;

            this.$refs.objs.reset();
            this.discardForm();
        },

        async addSubClient() {
            this.loadingSaveClient = true;
            this.form.active = !this.isAnalysis;
            let mapping_reference_field = false;
            let selectedCount = 0;
            for (const c of this.selectedCarriers) {
                if (c.selected) {
                    selectedCount++;
                    if (this.useAltMapping) {
                        mapping_reference_field = true;
                    }
                    try {
                        let data = {
                            carrier: c.value,
                            active: true,
                            prospect: this.defaultToProspect,
                            carrier_accounts: c.carrier_accounts,
                            sub_client_name: this.form.sub_client_name ? this.form.sub_client_name.toUpperCase() : "",
                            external_id: this.form.external_id,
                            contact_name: this.form.contact_name ? this.form.contact_name.toUpperCase() : "",
                            contact_phone: this.form.contact_phone,
                            contact_email: this.form.contact_email ? this.form.contact_email.toUpperCase() : "",
                            notes: this.form.notes,
                            is_markup: this.form.is_markup,
                            processing_flag: false,
                            mapping_reference_field,
                            is_psi_customer: this.form.is_psi_customer,
                            psi_customer_name: this.form.psi_customer_name,
                        };

                        await this.$httpNew.post("client", data);

                        this.$notify.success({
                            message: `${this.prospectOnly || this.defaultToProspect ? "Prospect" : "Client"} ${
                                this.form && this.form.sub_client_name
                                    ? this.form.sub_client_name.toUpperCase().trim()
                                    : "Client"
                            } has been added`,
                            timeOut: 9000,
                        });
                    } catch (e) {
                        this.$notify.error({
                            message:
                                `Error adding ${this.prospectOnly || this.defaultToProspect ? "prospect" : "client"} ${
                                    this.form && this.form.sub_client_name
                                        ? this.form.sub_client_name.toUpperCase().trim()
                                        : "Client"
                                } due to: ` + e,
                            timeOut: 9000,
                        });
                    }
                }
            }
            if (!selectedCount) {
                this.loadingSaveClient = false;
                this.$notify.error({
                    message: `Carrier must be selected`,
                    timeOut: 9000,
                });
                return false;
            }

            this.loadingSaveClient = false;
            this.closeForm();
        },
        async updateSubClient() {
            this.loadingSaveClient = true;
            this.form.active = !this.isAnalysis;
            for (const c of this.selectedCarriers) {
                if (c.selected) {
                    let mapping_reference_field = false;
                    if (this.useAltMapping) {
                        mapping_reference_field = true;
                    }
                    try {
                        let data = {
                            carrier: c.value,
                            active: c.active,
                            carrier_accounts: c.carrier_accounts,
                            sub_client_name: this.form.sub_client_name,
                            client_id: this.form.client_id,
                            external_id: this.form.external_id,
                            contact_name: this.form.contact_name,
                            contact_phone: this.form.contact_phone,
                            contact_email: this.form.contact_email,
                            notes: this.form.notes,
                            is_markup: this.form.is_markup,
                            mapping_reference_field,
                            is_psi_customer: this.form.is_psi_customer,
                            psi_customer_name: this.form.psi_customer_name,
                        };

                        await this.$httpNew.patch("client", data);

                        this.$notify.success({ message: `${data.sub_client_name} has been updated`, timeOut: 9000 });
                    } catch (e) {
                        this.$notify.error({ message: e.message, timeOut: 9000 });
                    }
                }
            }

            this.loadingSaveClient = false;

            this.closeForm();
        },
    },
};
</script>

<style>
.scrollable {
    overflow: auto;
}
.max-height-300 {
    max-height: 300px;
}
.margin-10 {
    margin-bottom: 10px;
}
</style>

const config = {
    region: process.env.VUE_APP_COGNITO_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_CLIENT_ID,
    ssr: true,
    AES_Cipher:
        "Jlcv3w57dJEHSewsF0BYTXSt09C36IBhwCPKKAtDDkD1Eo1X7Szz1DvjnisRE65pLfr3PtvGlECNfwPPmInnubvR8K41Fx9GVvnzMP4hPWyuJDfkxg3Ef85HSn4Feno8vzxsIapQlEsyQy39c33VzRh3t1jIxYqyOR3EBJCAN4oSnCFfxBRlDbscmEiokcWzn47HUi7XyGB3buhhNXPrnNdlnUjQwlvZHVJghOGEtIxufwMoZ1x0oOAlAB2zL9ix",
    AWS_S3_Config: {
        uploadBucketName: "pmo-prospect-upload",
        bucketRegion: "us-east-1",
        identityPoolId: "us-east-1:7d24138f-b8f3-4584-9877-804af9b8ebe4",
    },
    APIKey: "AFGG2piXh0ht6dmXUxqv4nA1PU120r0yMAQhuc1418",

    BASE_URL: "/",
};

if ("VUE_APP_NODE_ENV" in process.env) {
    if (process.env.VUE_APP_NODE_ENV.toUpperCase() == "DEVELOPMENT") {
        config.AWS_S3_Config.uploadBucketName = "3ro-prospect-upload-qa";
        console.log(config.AWS_S3_Config.uploadBucketName);
    }
}

export default config;

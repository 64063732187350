<template>
    <div style="width: 100%">
        <!--    <mdb-alert-->
        <!--      v-model="active"-->
        <!--      :type="type"-->
        <!--      :style="{ width: width, position: 'absolute', 'z-index': 999 }"-->
        <!--      dismissible-->
        <!--      @input="dismiss"-->
        <!--    >-->
        <!--      {{ message }}-->
        <!--    </mdb-alert>-->
    </div>
</template>

<script>
export default {
    name: "Alert",
    props: {
        width: { default: "100%" },
    },
    data: () => ({
        active: false,
        buttonText: "OK",
    }),
    mounted() {
        if (this.message) this.active = true;
    },
    methods: {
        dismiss() {
            this.$store.dispatch("clearAlertMessage");
        },
    },
    computed: {
        message() {
            return this.$store.getters.alertMessage;
        },
        type() {
            return this.$store.getters.alertType;
        },
        duration() {
            return this.$store.getters.alertDuration;
        },
    },
    watch: {
        message() {
            if (this.message) {
                this.active = true;
                if (this.type === "success") this.$notify.success({ message: this.message, timeOut: 9000 });
                else if (this.type === "danger") this.$notify.error({ message: this.message, showDuration: 50000 });
                else this.$notify.info({ message: this.message, timeOut: 9000 });
            } else this.active = false;
        },
    },
};
</script>

<style lang="scss" scoped>
/*
  .info {
    background-color: $color-primary-050 !important;
    color: $color-black-med;
  }
*/
</style>

export default {
    state: {
        sessPMOClientID: "",
        sessPMOClientName: "",
        sessPMOClientCarriers: [],
        sessPMOProspectID: "",
        sessPMOSubClientID: "",
        sessPMOSubClientName: "",
        sessPMOProspects: [],
        sessPMOSubClients: [],
        sessPMOEntities: [],
        sessPMOCarrierAccts: [],
        sessPMOLocations: [],
        sessAnalysisBeginDate: "",
        sessAnalysisEndDate: "",
    },
    getters: {
        getSessPMOLocations: (state) => state.sessPMOLocations,
        getSessPMOProspects: (state) => state.sessPMOProspects,
    },
    mutations: {
        // Selections
        savePMOClientID(state, PMOClientID) {
            state.sessPMOClientID = PMOClientID;
        },
        savePMOClientName(state, PMOClientName) {
            state.sessPMOClientName = PMOClientName;
        },
        savePMOSubClientID(state, PMOSubClientID) {
            state.sessPMOSubClientID = PMOSubClientID;
        },
        savePMOProspectID(state, PMOProspectID) {
            state.sessPMOProspectID = PMOProspectID;
        },

        // Carriers
        resetPMOClientCarriers(state) {
            state.sessPMOClientCarriers.length = 0;
        },

        addPMOClientCarrier(state, PMOClientCarrier) {
            state.sessPMOClientCarriers.push(PMOClientCarrier);
        },

        // Prospects
        resetPMOProspectList(state) {
            state.sessPMOProspects.length = 0;
        },
        addPMOProspect(state, PMOProspect) {
            state.sessPMOProspects.push(PMOProspect);
        },
        removePMOProspect(state, prospect) {
            state.sessPMOProspects.splice(
                state.sessPMOProspects.findIndex((p) => p.sub_client_id === prospect.sub_client_id),
                1
            );
        },

        // Sub-Clients
        resetPMOSubClientList(state) {
            state.sessPMOSubClients.length = 0;
        },
        addPMOSubClient(state, PMOSubClient) {
            state.sessPMOSubClients.push(PMOSubClient);
        },
        removePMOSubClient(state, subclient) {
            state.sessPMOSubClients.splice(
                state.sessPMOSubClients.findIndex((sc) => sc.sub_client_id === subclient.sub_client_id),
                1
            );
        },

        // TODO deprecate: leave concatenation of lists to UI
        // Sub-Clients + Prospects
        resetPMOEntityList(state) {
            state.sessPMOEntities.length = 0;
        },

        addPMOEntity(state, PMOEntity) {
            state.sessPMOEntities.push(PMOEntity);
        },

        // Locations
        resetPMOLocationList(state) {
            state.sessPMOLocations.length = 0;
        },
        addPMOLocation(state, PMOLocation) {
            state.sessPMOLocations.push(PMOLocation);
        },

        // Analysis
        saveAnalysisBeginDate(state, AnalysisBeginDate) {
            state.sessAnalysisBeginDate = AnalysisBeginDate;
        },
        saveAnalysisEndDate(state, AnalysisEndDate) {
            state.sessAnalysisEndDate = AnalysisEndDate;
        },

        savePMOCarrierAccounts(state, { carrier, carrier_accounts }) {
            state.sessPMOCarrierAccts.push({ carrier, carrier_accounts });
        },
    },

    actions: {
        setPMOLists({ commit }, lists) {
            commit("resetPMOSubClientList");
            commit("resetPMOProspectList");
            commit("resetPMOEntityList");

            lists.client_list.forEach((client) => {
                commit("addPMOSubClient", client);
            });

            lists.prospect_list.forEach((prospect) => {
                commit("addPMOProspect", prospect);
            });

            lists.combined_list.forEach((entity) => {
                commit("addPMOEntity", entity);
            });
        },

        activateProspect({ commit }, prospect) {
            prospect.activate = true;
            commit("removePMOProspect", prospect);
            commit("addPMOSubClient", prospect);
        },
    },
};

<template>
    <mdb-container>
        <div class="classic-tabs mt-10 p-0">
            <mdb-navbar color="orange" class="p-0">
                <mdb-navbar-nav nav left>
                    <mdb-nav-item
                        :to="tab.to"
                        v-for="tab in allowedTabs"
                        :active="activeTab === tab.id"
                        @click="activeTab = tab.id"
                        :key="tab.id"
                        exact
                        >{{ tab.text }}</mdb-nav-item
                    >
                </mdb-navbar-nav>
            </mdb-navbar>
        </div>
        <div class="px-0 py-0 mh-500">
            <UserNotifications></UserNotifications>
            <router-view
                :key="$route.fullPath"
                :sections="allowedTabs"
                :prospects="prospects"
                :clients="clients"
                :combined="combined"
                :getClients="getClients"
                :getLocations="getLocations"
                :locations="locations"
                :threeplCarriers="threeplCarriers"
            ></router-view>
        </div>
    </mdb-container>
</template>

<script>
import UserNotifications from "@/components/common/UserNotifications";
import { useEventsStore } from "@/store/pinia";
export default {
    components: {
        UserNotifications,
    },
    data() {
        return {
            activeTab: 0,
            last_notification: null,
        };
    },
    props: {
        tabs: Array,
        clients: Array,
        prospects: Array,
        combined: Array,
        locations: Array,
        threeplCarriers: [],
        getClients: Function,
        getLocations: Function,
    },

    mounted() {
        for (const tab of this.allowedTabs) {
            if (this.$route.fullPath === tab.to) {
                this.activeTab = tab.id;
            }
        }
        let events = useEventsStore();
        return {
            events,
        };
    },
    watch: {
        events: {
            handler: function (val) {
                if (val.events && val.events.length > 0) {
                    const message = val.events[val.events.length - 1];
                    // its the same message, don't show it again (prevent duplicates)

                    if (message && message.tms) {
                        if (message.tms === this.last_notification) return;
                        this.showMessage(message);
                        this.last_notification = message.tms;
                    }
                }
            },
            deep: true,
        },
    },
    computed: {
        allowedTabs() {
            return this.tabs.filter((tab) => !tab.adminOnly || (tab.adminOnly && this.$store.getters.isAdmin));
        },
    },

    methods: {
        getActiveTabIndex(index) {
            this.tabContent = this.allowedTabs[index];
        },
        showMessage(message) {
            const timeOut = message.data.persist ? 9999999999 : 9000;
            // For some reason autohide doesn't work with notify... so we just set the timeout to a large number
            if (message.data.type === "error") {
                this.$notify.error({ message: message.data.message, timeOut });
            } else {
                this.$notify.success({ message: message.data.message, timeOut });
            }
        },
    },
};
</script>

<style scoped>
.v-tabs-no-flex {
    flex: none;
}

.mh-500 {
    min-height: 500px;
}
</style>

const state = {
    alertMessage: null,
    alertType: null,
    alertDuration: 5000,
};

const getters = {
    alertMessage: (state) => state.alertMessage,
    alertType: (state) => state.alertType,
    alertDuration: (state) => state.alertDuration,
};

const actions = {
    clearAlertMessage({ commit }) {
        commit("setAlert", { message: null, type: null });
    },

    setAlertMessage({ state, commit, dispatch }, alert) {
        // TODO break existing alert if exists
        commit("setAlert", alert);
        if (!alert.persist) {
            setTimeout(() => {
                dispatch("clearAlertMessage");
            }, state.alertDuration);
        }
    },
};

const mutations = {
    setAlert(state, alert) {
        state.alertMessage = alert.message;
        state.alertType = alert.type;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};

<template>
    <mdb-container fluid v-if="profileRates">
        <mdb-row>
            <mdb-col col="10" v-if="selectedCategory && selectedProfileVersion">
                <h2>{{ selectedCategory }}</h2>
                <div v-if="profileVersions.length > 0">
                    <h3 v-if="newStartDate == null">
                        {{
                            (selectedProfileVersion.contract_startdate
                                ? selectedProfileVersion.contract_startdate.split("T")[0].replaceAll("-", "/")
                                : "") +
                            " - " +
                            (selectedProfileVersion.contract_enddate
                                ? selectedProfileVersion.contract_enddate.split("T")[0].replaceAll("-", "/")
                                : "")
                        }}
                    </h3>
                    <mdb-row v-else>
                        <mdb-date-picker-2
                            no-title
                            label="Contract Start Date"
                            :min="dateTomorrow()"
                            v-model="newStartDate"
                            @input="mnuEndDate = false"
                            class="w-25"
                        ></mdb-date-picker-2>
                        <mdb-btn
                            outlined
                            class="mt-0"
                            color="orange"
                            @click.native="changeVersionStartDate()"
                            :disabled="
                                !profileVersionInFuture ||
                                selectedProfileVersion.contract_enddate !== null ||
                                newStartDate == selectedProfileVersion.contract_startdate.split('T')[0]
                            "
                            >Update Start Date</mdb-btn
                        >
                    </mdb-row>
                </div>
                <div v-else>
                    <h4>
                        Set discount percentages and choose a description and start date to create your first profile
                        version.
                    </h4>
                </div>
            </mdb-col>
        </mdb-row>
        <div class="d-flex justify-items-center mt-2">
            <div class="flex-fill">
                <mdb-input
                    type="checkbox"
                    id="checkbox-dim"
                    name="checkbox-dim"
                    v-if="profileRates[categoryFormatted]"
                    label="Update All"
                    value="blnUpdateAll"
                    v-model="blnUpdateAll"
                ></mdb-input>
            </div>
            <div class="flex-fill">
                <mdb-input
                    type="text"
                    v-if="profileRates[categoryFormatted] && !blnShowDimInfo && blnUpdateAll"
                    v-model="txtAllDiscount"
                    label="Discount %"
                    filled
                    prefix="%"
                    reverse
                    @keydown.native.enter="updateDisplayedBatchRates"
                />
            </div>
            <div class="flex-fill">
                <mdb-input
                    type="text"
                    v-if="profileRates[categoryFormatted] && !blnShowDimInfo && blnUpdateAll"
                    v-model="txtAllMin"
                    label="Minimum $"
                    filled
                    prefix="%"
                    reverse
                    @keydown.native.enter="updateDisplayedBatchRates"
                />
            </div>
            <div class="flex-fill">
                <mdb-btn
                    v-if="profileRates[categoryFormatted] && !blnShowDimInfo && blnUpdateAll"
                    color="primary"
                    outlined
                    @click.native="updateDisplayedBatchRates"
                >
                    UPDATE
                </mdb-btn>
            </div>
            <div class="flex-fill">
                <mdb-input
                    type="text"
                    v-if="profileRates[categoryFormatted]"
                    v-model="searchText"
                    append-icon="search"
                    label="Search"
                    filled
                />
            </div>
            <mdb-dropdown  v-if="profileVersions && profileVersions.length !== 0">
                <mdb-dropdown-toggle slot="toggle" class="w-100" outline="orange">
                    {{
                        selectedProfileVersion
                            ? selectedProfileVersion.description !== null
                                ? selectedProfileVersion.description
                                : selectedProfileVersion.id
                            : "Select Version"
                    }}
                </mdb-dropdown-toggle>
                <mdb-dropdown-menu color="orange" class="overflow-auto" style="max-height: 200px; margin-top: -30px">
                    <mdb-dropdown-item v-if="!profileVersions.length">Loading Versions</mdb-dropdown-item>
                    <mdb-dropdown-item
                        v-for="version in profileVersions"
                        :key="version.description ? version.description : version.id"
                        :active="selectedProfileVersion && version.id === selectedProfileVersion.id"
                        @click.native="changeVersion(version)"
                        >{{ version.description ? version.description : version.id }}</mdb-dropdown-item
                    >
                </mdb-dropdown-menu>
            </mdb-dropdown>
        </div>
        <mdb-row>
            <mdb-col col="2">
                <div v-for="category in profileCategories" :key="category">
                    <mdb-btn
                        flat
                        :class="{ selected: selectedCategory === category }"
                        class="d-flex flex-column p-1 m-1 text-center w-100"
                        @click.native="switchCategory(category)"
                    >
                        <span class="w-100 text-center">{{ category }}</span>
                        <mdb-progress
                            indeterminate
                            color="orange darken-2"
                            absolute
                            bottom
                            v-if="loading[formatName(category)]"
                        ></mdb-progress>
                    </mdb-btn>
                </div>
            </mdb-col>

            <mdb-col col="10" v-if="selectedProfileVersion">
                <vue-good-table
                    ref="dataFiltered"
                    :columns="headersForTable"
                    :rows="profileRates[categoryFormatted]"
                    :search-options="{
                        enabled: true,
                        externalQuery: searchText,
                    }"
                    :sort-options="{
                        enabled: true,
                    }"
                    :options.sync="pagination"
                    :footer-props="{
                        'items-per-page-options': arrPageOptions,
                    }"
                    :hide-default-footer="!profileRates[categoryFormatted]"
                    :is-loading="isLoading"
                >
                    <div slot="emptystate">No records found for {{ selectedCategory }}</div>
                    <template slot="table-row" slot-scope="props">
                        <span v-if="props.column.field === 'cpg'">
                            <b>{{ props.row.cpg }}</b>
                        </span>
                        <span v-if="props.column.field === 'carrier'">
                            <b>{{ props.row.carrier }}</b>
                        </span>
                        <span v-if="props.column.field === 'weight_break'" class="text-md-left">
                            {{ props.row.weight_break }}
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'discSubClient'">
                            {{
                                props.row.baseline && props.row.baseline.discount
                                    ? props.row.baseline.discount[0].toFixed(2)
                                    : "0.00"
                            }}%
                        </span>
                        <span v-if="props.column.field === 'altDiscProspect' && !blnShowDimInfo">
                            <mdb-input
                                type="text"
                                v-model="
                                    profileRates[categoryFormatted][props.row.originalIndex].altContract.discountNew
                                "
                                :ref="'discount_' + props.row.originalIndex + '_altContract'"
                                reverse
                                @focus="$event.target.select()"
                                :disabled="
                                    profileVersions.length !== 0 && selectedProfileVersion.contract_enddate !== null
                                "
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'discPropose'">
                            <mdb-input
                                type="text"
                                v-model="profileRates[categoryFormatted][props.row.originalIndex].subclient.discountNew"
                                :ref="'discount_' + props.row.originalIndex + '_subclient'"
                                reverse
                                @focus="$event.target.select()"
                                :disabled="
                                    profileVersions.length !== 0 && selectedProfileVersion.contract_enddate !== null
                                "
                            >
                                <span class="input-group-text md-addon" slot="append">%</span>
                            </mdb-input>
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'discContract'">
                            {{
                                props.row.threepl && props.row.threepl.discount
                                    ? props.row.threepl.discount[0].toFixed(2)
                                    : 0
                            }}%
                        </span>
                        <span v-if="!blnShowDimInfo && props.column.field === 'minPropose'">
                            <mdb-input
                                type="text"
                                v-model="profileRates[categoryFormatted][props.row.originalIndex].subclient.minimumNew"
                                :ref="'minimum_' + props.row.originalIndex + '_subclient'"
                                reverse
                                @focus="$event.target.select()"
                                :disabled="
                                    profileVersions.length !== 0 && selectedProfileVersion.contract_enddate !== null
                                "
                            >
                                <span class="input-group-text md-addon" slot="prepend">$</span>
                            </mdb-input>
                        </span>
                        <span v-if="!blnShowDimInfo && props.column.field === 'altMinProspect'">
                            <mdb-input
                                type="text"
                                v-model="
                                    profileRates[categoryFormatted][props.row.originalIndex].altContract.minimumNew
                                "
                                :ref="'minimum_' + props.row.originalIndex + '_altContract'"
                                reverse
                                @focus="$event.target.select()"
                                :disabled="
                                    profileVersions.length !== 0 && selectedProfileVersion.contract_enddate !== null
                                "
                            >
                                <span class="input-group-text md-addon" slot="prepend">$</span>
                            </mdb-input>
                        </span>
                        <span class="text-md-right" v-if="!blnShowDimInfo && props.column.field === 'minContract'">
                            ${{ props.row.threepl ? formatMoney(props.row.threepl.minimum) : "0.00" }}
                        </span>
                        <span v-if="!blnShowDimInfo && props.column.field === 'spend.count'" class="text-md-right">
                            {{ props.row.spend ? props.row.spend.count.toLocaleString() : "0" }}
                        </span>
                        <span
                            v-if="!blnShowDimInfo && props.column.field === 'spend.gross_spend'"
                            class="text-md-right"
                        >
                            ${{ props.row.spend ? formatMoney(props.row.spend.gross_spend) : "0.00" }}
                        </span>
                    </template>
                </vue-good-table>
            </mdb-col>
        </mdb-row>
        <br/>
        <mdb-row v-if="!profileVersionInFuture">
            <div class="w-75">
                <mdb-input type="text" v-model="descriptionText" label="Profile Description"></mdb-input>
            </div>
            <div class="w-25">
                <mdb-date-picker-2
                    no-title
                    :min="dateTomorrow()"
                    v-model="profileStartDate"
                    @input="mnuEndDate = false"
                ></mdb-date-picker-2>
            </div>
        </mdb-row>
        <mdb-row>
            <mdb-btn
                outlined
                class="mt-0"
                color="orange"
                @click.native="createNewProfileVersion()"
                :disabled="descriptionText == null || descriptionText == '' || profileVersionInFuture"
                >CREATE NEW VERSION</mdb-btn
            >
            <mdb-btn
                outlined
                class="mt-0"
                color="orange"
                @click.native="confirmDeleteProfileVersion()"
                v-if="
                    profileVersionInFuture &&
                    selectedProfileVersion.contract_enddate == null &&
                    selectedProfileVersion.contract_startdate > new Date().toISOString()
                "
                >DELETE VERSION</mdb-btn
            >
        </mdb-row><br/><br/>
    </mdb-container>
</template>

<script>
import PMO_Global from "@/assets/js/PMO_Global";
import ProfileHistory from "@/components/common/ProfileHistory.vue";

export default {
    name: "objProfileRatesDiscountVersions",
    components: {},

    props: ["propSubClient", "propCarrier", "propAnalysis", "prop3plCarrier"],

    data() {
        return {
            showConfirmDelete: false,
            tplHeadersRateDiscountAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Proposed Prospect Discount", field: "discPropose", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Proposed Prospect Discount",
                    field: "altDiscProspect",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                { label: "Current Prospect Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Proposed Prospect Minimum", field: "minPropose", align: "right", sortable: false },
                { label: "3PL Minimum", field: "minContract", align: "right", sortable: false },
                { label: "Current Prospect Minimum", field: "minSubClient", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Prospect Minimum",
                    field: "altMinProspect",
                    align: "right",
                    sortable: false,
                },
                { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersAccRateDiscountAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Proposed Prospect Discount", field: "discPropose", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Proposed Prospect Discount",
                    field: "altDiscProspect",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                { label: "Current Prospect Discount", field: "discSubClient", align: "right", sortable: false },
                { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersDimInfoAnalysis: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Proposed Prospect Divisor", field: "dimDvsrPropose", align: "right", sortable: false },
                { label: "3PL Divisor", field: "dimDvsrContract", align: "right", sortable: false },
                { label: "Proposed Prospect Threshold", field: "dimThshldPropose", align: "right", sortable: false },
                { label: "3PL Threshold", field: "dimThshldContract", align: "right", sortable: false },
            ],

            tplHeadersDimInfoProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Prospect Divisor", field: "dimDvsrPropose", align: "right", sortable: false },
                { label: "3PL Divisor", field: "dimDvsrContract", align: "right", sortable: false },
                { label: "Prospect Threshold", field: "dimThshldPropose", align: "right", sortable: false },
                { label: "3PL Threshold", field: "dimThshldContract", align: "right", sortable: false },
            ],

            tplHeadersRateDiscountProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Current Client Discount", field: "discSubClient", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Client Discount",
                    field: "altDiscSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "New Client Discount", field: "discPropose", align: "right", sortable: false },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                { label: "Client Minimum", field: "minPropose", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Client Minimum",
                    field: "altMinSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "3PL Minimum", field: "minContract", align: "right", sortable: false },
                // { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                // { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            tplHeadersAccRateDiscountProfile: [
                { label: "Price Group", field: "cpg", align: "left", sortable: false },
                { label: "Carrier", field: "carrier", align: "left", sortable: true },
                { label: "Weight Break", field: "weight_break", align: "left", sortable: false },
                { label: "Current Client Discount", field: "discSubClient", align: "right", sortable: false },
                {
                    label: "Alternate Carrier Client Discount",
                    field: "altDiscSubClient",
                    align: "right",
                    sortable: false,
                },
                { label: "New Client Discount", field: "discPropose", align: "right", sortable: false },
                { label: "3PL Discount", field: "discContract", align: "right", sortable: false },
                // { label: "Package Count", field: "spend.count", align: "right", sortable: true, type: "number" },
                // { label: "Gross Spend", field: "spend.gross_spend", align: "right", sortable: true, type: "number" },
            ],

            configCurrencyDiscount: {
                currency: null,
                locale: "en",
                precision: 2,
                valueAsInteger: false,
                distractionFree: true,
                allowNegative: false,
            },

            configCurrencyMinimum: {
                currency: null,
                locale: "en",
                precision: 2,
                valueAsInteger: false,
                distractionFree: true,
                allowNegative: true,
            },

            rateHeaders: [],
            dimInfoHeaders: [],

            pagination: {
                page: 1,
                sortBy: ["low_weight", "high_weight", "cpg"],
                itemsPerPage: -1,
            },

            arrPageOptions: [5, 25, 50, { text: "ALL", value: -1 }],

            isLoading: false,
            getRates: false,

            hasError: false,
            errMsgArray: [],

            profileRates: null,
            profileDimInfo: [],

            profileCategories: null,
            selectedCategory: "",
            selectedProfileVersion: {},
            profileVersionInFuture: false,
            profileStartDate: null, //this.getDateFromDatetime(this.dateTomorrow()),
            newStartDate: null,

            hasUpdateError: false,

            searchText: "",
            descriptionText: "",

            blnUpdateAll: false,
            txtAllDiscount: 0,
            txtAllMin: 0,

            blnShowDimInfo: false,
            txtAllDimDivisor: 0,
            txtAllDimThreshold: 0,
            showDiscountModal: false,
            loading: [],
            valuesForDiscount: null,

            overrideModalConfigs: {
                discount: {
                    title: "Discount Confirm?",
                    message: "The value entered is above your discount. Are you sure you want to continue?",
                },
                minimum: {
                    title: "Minimum Confirm?",
                    message: "The value entered is below your minimum. Are you sure you want to continue?",
                },
            },

            overrideModalItem: {},

            showOverrideModal: false,
            valuesForOverride: null,
            bulkUpdateErrors: false,
            discountComparisons: [],
            profileVersions: [],
        };
    },

    computed: {
        subclient() {
            return this.propSubClient;
        },

        threeplCarrier() {
            return this.prop3plCarrier;
        },
        carrier() {
            return this.propCarrier;
        },
        categoryFormatted() {
            return this.selectedCategory ? this.formatName(this.selectedCategory) : "";
        },
        headersForTable() {
            if (this.propAnalysis) {
                return this.blnShowDimInfo ? this.tplHeadersDimInfoAnalysis : this.rateHeaders;
            } else {
                return this.blnShowDimInfo ? this.tplHeadersDimInfoProfile : this.rateHeaders;
            }
        },
    },

    watch: {
        propAnalysis() {
            if (this.propAnalysis) {
                this.rateHeaders = this.tplHeadersRateDiscountAnalysis;
                this.dimInfoHeaders = this.tplHeadersDimInfoAnalysis;
            } else {
                this.rateHeaders = this.tplHeadersRateDiscountProfile;
                this.dimInfoHeaders = this.tplHeadersDimInfoProfile;
            }
        },
        subclient() {
            this.profileRates = [];
            this.selectedCategory = this.profileCategories[0];
            if (this.subclient && this.carrier && !this.isLoading) {
                this.getRates = true;
                this.getPMORates(this.subclient, this.carrier);
            }

            this.blnUpdateAll = false;
            this.txtAllDiscount = 0;
            this.txtAllMin = 0;
            this.txtAllDimDivisor = 0;
            this.txtAllDimThreshold = 0;
            this.searchText = "";
        },

        carrier() {
            this.profileRates = [];
            this.selectedCategory = this.profileCategories[0];

            if (this.subclient && this.carrier && !this.isLoading) {
                this.getRates = true;
                this.getPMORates(this.subclient, this.carrier);
            }

            this.blnUpdateAll = false;
            this.txtAllDiscount = 0;
            this.txtAllMin = 0;
            this.searchText = "";
        },
    },
    mounted() {
        this.$root.$on("profile-version-delete", () => {
            this.deleteProfileVersion();
        });
    },
    methods: {
        formatMoney: function (_decInAmount) {
            return PMO_Global.formatMoney(_decInAmount);
        },

        async getCategories() {
            const categories = await this.$httpNew.get(`cpg-organization`);
            let categArr = categories.data.pkg;
            if (
                categArr.includes("GROUND RESIDENTIAL") &&
                categArr.includes("GROUND RESIDENTIAL (OTHERS)") &&
                categArr.includes("GROUND COMMERCIAL") &&
                categArr.includes("GROUND COMMERCIAL (OTHERS)") &&
                categArr.includes("INTERNATIONAL") &&
                categArr.includes("DOMESTIC AIR SERVICES") &&
                categArr.includes("POSTAL PACKAGES")
            ) {
                categArr = [
                    "GROUND RESIDENTIAL",
                    "GROUND RESIDENTIAL (OTHERS)",
                    "GROUND COMMERCIAL",
                    "GROUND COMMERCIAL (OTHERS)",
                    "POSTAL PACKAGES",
                    "DOMESTIC AIR SERVICES",
                    "INTERNATIONAL",
                ];
            }
            if (!this.propAnalysis) {
                this.getPMORates(this.subclient, this.carrier);
            }
            this.switchCategory(categArr[0]);
            return [...categArr, "ACCESSORIALS"];
        },

        switchCategory(p_objInCategory) {
            this.selectedCategory = p_objInCategory;
            if (this.propAnalysis) {
                if (this.selectedCategory === "ACCESSORIALS") {
                    this.rateHeaders = this.tplHeadersAccRateDiscountAnalysis;
                } else {
                    this.rateHeaders = this.tplHeadersRateDiscountAnalysis;
                }
                this.dimInfoHeaders = this.tplHeadersDimInfoAnalysis;
            } else {
                if (this.selectedCategory === "ACCESSORIALS") {
                    this.rateHeaders = this.tplHeadersAccRateDiscountProfile;
                } else {
                    this.rateHeaders = this.tplHeadersRateDiscountProfile;
                }
                this.dimInfoHeaders = this.tplHeadersDimInfoProfile;
            }
            // }

            this.blnUpdateAll = false;
            this.txtAllDiscount = 0;
            this.txtAllMin = 0;

            this.searchText = "";
        },
        formatName(val) {
            return val.replace(/ /g, "_");
        },
        async getPMORates(subclientId, carrier, id = 0) {
            this.isLoading = true;
            this.profileRates = [];
            if (id == 0) {
                const contracts = await this.$httpNew.get(`contracts?client_id=${subclientId}&carrier=${carrier}`);
                this.profileRates = contracts.data;
                const accContracts = await this.$httpNew.get(
                    `contracts-acc?client_id=${subclientId}&carrier=${carrier}`
                );
                this.profileRates["ACCESSORIALS"] = accContracts.data;
            } else {
                const contracts = await this.$httpNew.get(
                    `contracts?client_id=${subclientId}&carrier=${carrier}&profile_header_id=${id}`
                );
                this.profileRates = contracts.data;
                const accContracts = await this.$httpNew.get(
                    `contracts-acc?client_id=${subclientId}&carrier=${carrier}&profile_header_id=${id}`
                );
                this.profileRates["ACCESSORIALS"] = accContracts.data;
            }

            for (const key of Object.keys(this.profileRates)) {
                for (const row of this.profileRates[key]) {
                    if (typeof row.subclient.markup_pct == "undefined") {
                        row.subclient["markup_pct"] = 0;
                    }
                }
                this.profileRates[key] = this.profileRates[key].sort((a, b) => {
                    if (a.cpg == b.cpg) {
                        return a.weight_break.localeCompare(b.weight_break);
                    } else {
                        return a.cpg.localeCompare(b.cpg);
                    }
                });
            }
            this.isLoading = false;
        },
        async updateDisplayedBatchRates() {
            for (const rate of this.profileRates[this.selectedCategory.replace(/ /g, "_")]) {
                rate.subclient.discountNew = [this.txtAllDiscount];
                rate.subclient.minimumNew = this.txtAllMin;
            }

            this.blnUpdateAll = false;
            this.txtAllDiscount = 0;
            this.txtAllMin = 0;
        },
        async updateBatchRates(profile_header_id) {
            let data;
            let newRate;
            this.bulkUpdateErrors = false;
            this.isLoading = true;
            let logs = "";
            let failureLogs = "";
            let updateErrors = [];
            let bulkPkgs = [];
            let bulkAccs = [];
            // const items = this.$refs["dataFiltered"].filteredRows[0].children;
            const items = this.profileRates;
            for (const category of Object.keys(items)) {
                for (const rate of items[category]) {
                    data = {
                        client_id: this.subclient,
                        carrier_price_group: rate.cpg,
                        weight_break: rate.weight_break,
                        carrier: rate.carrier,
                        profile_type: "SUBCLIENT",
                        active: true,
                        contract_startdate: this.profileStartDate,
                        contract_enddate: new Date(new Date().getFullYear(), 11, 31),
                        markup: false,
                        markup_pct:
                            rate.subclient.markup_pct > 0
                                ? Number(parseFloat(rate.subclient.markup_pct).toFixed(2))
                                : 0,
                        profile_header_id: profile_header_id,
                        discount: rate.subclient.discountNew[0] ? rate.subclient.discountNew[0] : 0,
                        minimum: rate.subclient.minimumNew,
                    };

                    if (rate.weight_break) {
                        bulkPkgs.push(data);
                    } else {
                        bulkAccs.push(data);
                    }
                }
            }

            try {
                const url = "bulk-contracts";
                let bulkResults = await this.$httpNew.patch(url, bulkPkgs);
            } catch (e) {
                console.log(e);
                this.$notify.error({ message: e.message, timeOut: 9000 });
            }

            try {
                const url = "bulk-contracts-acc";
                let bulkResults = await this.$httpNew.patch(url, bulkAccs);
            } catch (e) {
                console.log(e);
                this.$notify.error({ message: e.message, timeOut: 9000 });
            }

            this.$notify.success({ message: `Successfully created new profile version.`, timeOut: 9000 });

            await this.getAllProfileHeaders();
            await this.getPMORates(this.subclient, this.carrier);
            this.isLoading = false;
            this.txtAllDiscount = 0;
            this.txtAllMin = 0;
        },
        async updateBatchDimInfo() {
            let data;
            let newRate;
            this.isLoading = true;
            this.bulkUpdateErrors = false;
            let updateErrors = [];
            let logs = "";
            const items = this.$refs["dataFiltered"].$children[0].filteredItems;
            for (const rate of items) {
                data = {
                    client_id: this.subclient,
                    carrier_price_group: rate.cpg,
                    weight_break: rate.weight_break,
                    carrier: rate.carrier,
                    profile_type: "SUBCLIENT",
                    active: true,
                    // TODO Remove hardcodes - should be pulled from the rate or not taken into account
                    // contract_startdate: "2019-01-01",
                    // contract_enddate: "2019-12-31",
                    divisor:
                        this.txtAllDimDivisor &&
                        Number(this.txtAllDimDivisor) !== 0 &&
                        Number(this.txtAllDimDivisor) < rate.threepl.divisor
                            ? Number(parseFloat(this.txtAllDimDivisor).toFixed(2))
                            : rate.subclient.divisor,
                    threshold:
                        this.txtAllDimThreshold &&
                        Number(this.txtAllDimThreshold) !== 0 &&
                        Number(this.txtAllDimThreshold) < rate.threepl.threshold
                            ? Number(parseFloat(this.txtAllDimThreshold).toFixed(2))
                            : rate.subclient.threshold,
                };

                try {
                    const url = "dim-factors";
                    newRate = (await this.$httpNew.patch(url, data)).data;
                    rate.subclient.divisorNew = newRate.divisor;
                    rate.subclient.divisor = newRate.divisor;
                    rate.subclient.thresholdNew = newRate.threshold;
                    rate.subclient.threshold = newRate.threshold;
                } catch (e) {
                    updateErrors.push({
                        error: e,
                        client_id: client_id,
                        carrier_price_group: carrier_price_group,
                        weight_break: weight_break,
                    });
                    this.$notify.error({ message: e.message, timeOut: 9000 });
                }
                if (
                    Number(this.txtAllDimThreshold) > rate.threepl.threshold ||
                    Number(this.txtAllDimDivisor) > rate.threepl.divisor
                ) {
                    this.bulkUpdateErrors = true;
                    rate.class = "red lighten-5";
                } else {
                    logs += `${rate.cpg} updated successfully. <br/>`;
                    rate.class = "green lighten-5";
                }
            }
            if (this.bulkUpdateErrors) {
                this.$notify.error({
                    message:
                        "One or more records could not be updated. New amount cannot be greater than 3PL amount. <br/>" +
                        logs,
                    timeOut: 9000,
                });
            } else {
                this.$notify.success({ message: logs, timeOut: 9000 });
            }

            await this.getProfileVersions(this.subclient, this.carrier);
            await this.getPMORates(this.subclient, this.carrier);
            this.txtAllDimDivisor = 0;
            this.txtAllDimThreshold = 0;
            this.isLoading = false;
        },
        async getAllProfileHeaders() {
            let versionResp = await this.$httpNew.get(
                `profile-versions?client_id=${this.subclient}&carrier=${this.carrier}`
            );
            if (versionResp.data.length > 0) {
                this.profileVersions = versionResp.data.sort((a, b) => b.id - a.id);

                this.changeVersion(this.profileVersions[0]);
                if (
                    this.selectedProfileVersion.contract_startdate.split("T")[0] >
                    this.getDateFromDatetime(this.dateTomorrow())
                ) {
                    this.profileVersionInFuture = true;
                }
                else {
                    this.profileVersionInFuture = false;
                }
            }
        },
        async createNewProfileVersion() {
            try {
                let oldHeaderResp = await this.$httpNew.get(
                    `latest-profile-header?client_id=${this.subclient}&carrier=${this.carrier}`
                );
                if (oldHeaderResp.data && oldHeaderResp.data.contract_startdate && oldHeaderResp.data.contract_startdate.split('T')[0] >= this.profileStartDate) {
                    this.$notify.error({ message: 'Cannot create a new profile version that starts on the same day as an active profile version. Please select a new start date or contact an administrator.', timeOut: 9000 });
                }
                else {
                    let newHeaderId = await this.createNewProfileHeader(oldHeaderResp);
                    await this.updateBatchRates(newHeaderId);
                }
            }
            catch (e) {
                this.$notify.error({ message: e.message, timeOut: 9000 });
            }
        },
        async createNewProfileHeader(oldHeaderResp) {
            let version_id;

            let startDateString = this.profileStartDate;
            let closeHeaderDate = new Date(this.profileStartDate);
            closeHeaderDate.setDate(closeHeaderDate.getDate() - 1);
            closeHeaderDate = this.getDateFromDatetime(closeHeaderDate);

            const req_body = {
                client_id: this.subclient,
                carrier: this.carrier,
                start_date: startDateString,
                description: this.descriptionText,
            };
            let profileVersionResp = await this.$httpNew.post("/profile-version", req_body);

            version_id = profileVersionResp.data.id;

            if (
                typeof version_id !== "undefined" &&
                Number(oldHeaderResp.status) < 400 &&
                Object.keys(oldHeaderResp.data).length > 0
            ) {
                let old_header_id = oldHeaderResp.data.id;

                let closeProfileResp = await this.$httpNew.post("/close-profile-version", {
                    id: old_header_id,
                    end_date: closeHeaderDate,
                });
            }

            return version_id;
        },
        async changeVersionStartDate() {
            if (
                this.selectedProfileVersion.contract_enddate == null &&
                this.selectedProfileVersion.contract_startdate > new Date().toISOString() &&
                this.newStartDate !== null
            ) {
                let url = `profile-header-start-date`;
                await this.$httpNew.patch(url, {
                    id: this.selectedProfileVersion.id,
                    start_date: this.newStartDate,
                    client_id: this.subclient,
                });
                await this.resetPage();
            }
        },
        async confirmDeleteProfileVersion() {
            this.$root.$emit("profile-version-delete-confirmation-request", "request");
        },
        async deleteProfileVersion() {
            this.showConfirmDelete = false;
            if (
                this.selectedProfileVersion.contract_enddate == null &&
                this.selectedProfileVersion.contract_startdate > new Date().toISOString()
            ) {
                let url = `profile-version?id=${this.selectedProfileVersion.id}&client_id=${this.subclient}`;
                await this.$httpNew.delete(url);
            } else {
                this.$notify.error({
                    message:
                        "You cannot delete a profile version that has already taken effect. Please contact an administrator.",
                });
            }
            await this.resetPage();
        },
        changeVersion(version) {
            this.selectedProfileVersion = version;
            if (
                this.selectedProfileVersion.contract_enddate == null &&
                this.selectedProfileVersion.contract_startdate > new Date().toISOString()
            ) {
                this.newStartDate = this.selectedProfileVersion.contract_startdate.split("T")[0];
            } else {
                this.newStartDate = null;
            }
            this.getPMORates(this.subclient, this.carrier, this.selectedProfileVersion.id);
        },
        dateTomorrow() {
            let startDate = new Date();
            startDate.setDate(startDate.getDate() + 1); //tomorrow
            return startDate;
        },
        getDateFromDatetime(datetime) {
            return datetime.toISOString().split("T")[0];
        },
        async resetPage() {
            this.profileCategories = await this.getCategories();
            this.selectedCategory = this.profileCategories[0];
            await this.getAllProfileHeaders();
            this.profileStartDate = this.getDateFromDatetime(this.dateTomorrow());
            await this.getPMORates(this.subclient, this.carrier);
        },
    },
    async created() {
        await this.resetPage();
    },
};
</script>

<style lang="sass">
table.v-table
  thead
    th
      white-space: normal
.nopadding
  padding: 0 !important
</style>

<template>
    <div>
        <mdb-btn outline="orange" @click.native="chooseFiles" :disabled="disabled">UPLOAD CARRIER DATA</mdb-btn>
        <input
            id="fileInput"
            type="file"
            accept=".txt,.csv"
            multiple
            hidden
            v-on:change="filesSelected"
            @click="filesSelected"
        />

        <mdb-modal :show="showDialog" width="45em" persistent @close="showDialog = false">
            <mdb-modal-header>Upload Carrier Data</mdb-modal-header>
            <mdb-modal-body v-if="isLoading && !isComplete">
                Please wait while files are processed
                <mdb-progress :height="20" indeterminate color="orange" class="mb-0"></mdb-progress>
            </mdb-modal-body>

            <mdb-modal-body v-if="!isLoading && !isComplete">
                <div class="uploadSummary">
                    <h3>Carrier Data Summary</h3>
                    <br />
                    <div class="summaryAccounts"><b>Total Carrier Accounts:</b> {{ newCarrierAccounts.length }}</div>
                    <div class="summaryInvoices"><b>Invoice Dates:</b> {{ invoiceDateMin }} - {{ invoiceDateMax }}</div>
                </div>
                <vue-good-table :columns="headers" :rows="uploadDetails" item-key="name">
                    <template slot="table-row" slot-scope="props">
                        <tr>
                            <td>
                                <b>{{ props.row.name }}</b>
                            </td>
                            <td>{{ props.row.data.carrier }}</td>
                            <td>{{ props.row.data.carrierAccounts.length }}</td>
                            <td>
                                <mdb-icon
                                    v-if="props.row.uploaded"
                                    class="material-icons md-primary"
                                    icon="done"
                                ></mdb-icon>
                                <mdb-icon
                                    icon="delete"
                                    v-else
                                    class="material-icons cursor"
                                    v-on:click="deleteUpload(props.row.name)"
                                    >delete_forever</mdb-icon
                                >
                            </td>
                        </tr>
                    </template>
                </vue-good-table>
            </mdb-modal-body>

            <mdb-modal-body v-if="isComplete">
                <div class="results">
                    <div v-if="importTriggered">
                        The data has been uploaded and processing has been initiated. The wait time could be up to a few
                        hours, depending on the amount of data. Please check back later.
                    </div>
                    <div v-else>
                        The following error was encountered:
                        <br />
                        <br />
                        <span class="submitError">{{ errorMsg }}</span>
                    </div>
                </div>
            </mdb-modal-body>

            <mdb-modal-footer>
                <mdb-btn v-if="isComplete" color="primary" text v-on:click="exitFileUpload">OK</mdb-btn>
                <mdb-btn v-if="!isLoading && !isComplete" color="success" text v-on:click="startImporter()"
                    >Submit</mdb-btn
                >
                <mdb-btn v-if="!isLoading && !isComplete" color="danger" text v-on:click="exitFileUpload()"
                    >Cancel</mdb-btn
                >
            </mdb-modal-footer>
        </mdb-modal>
    </div>
</template>

<script>
import AWS from "aws-sdk";
import * as config from "../../aws-exports";

AWS.config.update({
    region: config.default.AWS_S3_Config.bucketRegion,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: config.default.AWS_S3_Config.identityPoolId,
    }),
});

var s3 = new AWS.S3({
    apiVersion: "2006-03-01",
    params: { Bucket: config.default.AWS_S3_Config.uploadBucketName },
});

const CARRIER_SETUP = [
    {
        carrier: "UPS",
        fileExtension: "csv",
        delimiter: ",",
        hasHeaderRow: false,
        cntFields: 251,
        sizeAcctNbr: 6,
        colAcctNbr: 2,
        colInvoiceDate: 4,
    },
    {
        carrier: "FDX",
        fileExtension: "txt",
        delimiter: ";",
        hasHeaderRow: true,
        cntFields: 158,
        sizeAcctNbr: 9,
        colAcctNbr: 1,
        colInvoiceDate: 2,
    },
    {
        carrier: "FDX",
        fileExtension: "csv",
        delimiter: ",",
        hasHeaderRow: true,
        cntFields: 158,
        sizeAcctNbr: 9,
        colAcctNbr: 1,
        colInvoiceDate: 2,
    },
];

function pad(n, width, z) {
    z = z || "0";
    n = n + "";

    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

async function getFileDetails(p_objInFile, p_strInCarrier) {
    // var _objFileContents = "";
    var _objFileDetails = Object;

    var _objCarrier = Object;

    return new Promise(async function (resolve) {
        let _objFileContents = await loadFileContents(p_objInFile);
        let fileExtension = p_objInFile.name.split('.')[1]

        if (_objFileContents) {
            for (var _intCnt = 0; _intCnt < CARRIER_SETUP.length; _intCnt++) {
                if (CARRIER_SETUP[_intCnt].carrier.toUpperCase().trim() === p_strInCarrier.toUpperCase().trim() && CARRIER_SETUP[_intCnt].fileExtension.toUpperCase().trim() == fileExtension.toUpperCase().trim()) {
                    _objCarrier = CARRIER_SETUP[_intCnt];

                    break;
                }
            }

            _objFileDetails = await parseFileContents(_objCarrier, _objFileContents);
        }

        resolve(_objFileDetails);
    });
}

async function loadFileContents(p_objInFile) {
    const readUploadedFile = async (_objInputFile) => {
        const _objFileReader = new FileReader();

        return new Promise((resolve, reject) => {
            _objFileReader.onerror = () => {
                _objFileReader.abort();
                reject(new DOMException("Problem parsing input file."));
            };

            _objFileReader.onload = () => {
                resolve(_objFileReader.result);
            };

            _objFileReader.readAsText(_objInputFile);
        });
    };

    const _objFileData = await readUploadedFile(p_objInFile);

    return _objFileData;
}

async function parseFileContents(p_objInCarrier, p_objInFileContents) {
    var _objResult = Object;
    var _arrLines = [];
    var _arrData = [];

    var _arrAccounts = [];
    var _arrInvoices = [];

    var _strCarrier = "";
    var _intColAccount = -1;
    var _intColInvoiceDate = -1;
    // var _intSizeAccount = -1;

    var _strAccount = "";
    var _strAcctPadded = "";
    var _strInvoiceDate = "";

    return new Promise(function (resolve) {
        try {
            _strCarrier = p_objInCarrier.carrier;
            _intColAccount = p_objInCarrier.colAcctNbr;
            _intColInvoiceDate = p_objInCarrier.colInvoiceDate;
            // _intSizeAccount = p_objInCarrier.sizeAcctNbr;

            _arrLines = p_objInFileContents.split("\n");

            for (var _intCnt = 0; _intCnt < _arrLines.length; _intCnt++) {
                _arrData = _arrLines[_intCnt].split(p_objInCarrier.delimiter);

                if (!p_objInCarrier.hasHeaderRow || (p_objInCarrier.hasHeaderRow && _intCnt > 0)) {
                    _strAccount = _arrData[_intColAccount].replace(/(^")|("$)/g, "");

                    _strAcctPadded = _strAccount.substr(_strAccount.length - p_objInCarrier.sizeAcctNbr);
                    _strInvoiceDate = _arrData[_intColInvoiceDate].replace(/(^")|("$)/g, "");
                    _strInvoiceDate = _strInvoiceDate.replace(/-/g, "");

                    if (_arrAccounts.indexOf(_strAcctPadded) < 0) {
                        _arrAccounts.push(_strAcctPadded);
                    }

                    if (_arrInvoices.indexOf(_strInvoiceDate) < 0) {
                        _arrInvoices.push(_strInvoiceDate);
                    }
                }
            }
        } catch (err) {
            _objResult = undefined;
        } finally {
            _objResult = { carrier: _strCarrier, carrierAccounts: _arrAccounts, invoices: _arrInvoices };
            
            resolve(_objResult);
        }
    });
}

function collectionToArray(p_objInCol) {
    var _arrOutput = new Array();

    for (var _intCnt = 0; _intCnt < p_objInCol.length; _intCnt++) {
        _arrOutput[_arrOutput.length] = p_objInCol[_intCnt];
    }

    return _arrOutput;
}

export default {
    name: "FileUpload",

    props: {
        disabled: Boolean,
        entityID: String,
        carrier: String,
    },

    data() {
        return {
            headers: [
                { text: "File Name", value: "name", sortable: false },
                { text: "Carrier", value: "data.carrier", sortable: false },
                { text: "Carrier Accounts", value: "data.newCarrierAccounts.length", sortable: false },
                { text: "", sortable: false },
            ],

            isLoading: false,
            isComplete: false,
            importTriggered: false,
            existCarrierAccounts: [],
            newCarrierAccounts: [],
            invoiceDateMin: "",
            invoiceDateMax: "",
            uploadFiles: [],
            uploadDetails: [],
            errorMsg: "",
            showDialog: false,
        };
    },

    methods: {
        filesSelected: function (e) {
            this.uploadFiles = e.target.files || e.dataTransfer.files;

            this.startFileUpload();

            this.showDialog = this.uploadFiles.length;
        },

        chooseFiles: function () {
            document.getElementById("fileInput").click();
        },

        startFileUpload: async function () {
            if (this.uploadFiles.length) {
                this.isLoading = true;

                this.existCarrierAccounts = await this.getCarrierAccounts(this.entityID, this.carrier);

                await this.parseFileInfo();

                this.isLoading = false;
            }
        },

        parseFileInfo: async function () {
            var _objFileDetails = Object;

            var _arrCarrierAccounts = [];
            var _dteInvoiceDateMin = Date;
            var _dteInvoiceDateMax = Date;
            var _dteLookup = Date;
            var claimedCarrierAcct = null;

            try {
                _dteInvoiceDateMin = undefined;
                _dteInvoiceDateMax = undefined;

                this.uploadDetails.length = 0;
                let _intCnt;
                for (var _intFile = 0; _intFile < this.uploadFiles.length; _intFile++) {
                    _objFileDetails = await getFileDetails(this.uploadFiles[_intFile], this.carrier);

                    for (_intCnt = 0; _intCnt < _objFileDetails.carrierAccounts.length; _intCnt++) {
                        if (_arrCarrierAccounts.indexOf(_objFileDetails.carrierAccounts[_intCnt]) < 0) {
                            let checkAcctResp = await this.$http.post('/CarrierAcctInUse', {carrier_acct_num: _objFileDetails.carrierAccounts[_intCnt]})
                            if (checkAcctResp.data === true) {
                                let claimedCarrierAcct = _objFileDetails.carrierAccounts[_intCnt];
                                this.errorMsg = `Carrier account number ${claimedCarrierAcct} is already use and cannot be used for this prospect.`
                                this.$notify.error({message: this.errorMsg, timeOut: 9000})
                                this.exitFileUpload()
                                return
                            }
                            _arrCarrierAccounts.push(_objFileDetails.carrierAccounts[_intCnt]);
                        }
                    }

                    for (_intCnt = 0; _intCnt < _objFileDetails.invoices.length; _intCnt++) {
                        _dteLookup = new Date(
                            _objFileDetails.invoices[_intCnt].substr(0, 4),
                            _objFileDetails.invoices[_intCnt].substr(4, 2) - 1,
                            _objFileDetails.invoices[_intCnt].substr(6, 2)
                        );

                        if (_dteInvoiceDateMin === undefined || _dteLookup < _dteInvoiceDateMin) {
                            _dteInvoiceDateMin = _dteLookup;
                        }

                        if (_dteInvoiceDateMax === undefined || _dteLookup > _dteInvoiceDateMax) {
                            _dteInvoiceDateMax = _dteLookup;
                        }
                    }

                    this.uploadDetails.push({
                        name: this.uploadFiles[_intFile].name,
                        data: _objFileDetails,
                        uploaded: false,
                        uploadError: "",
                    });
                }
            } catch (err) {
                console.log(err.message);
            } finally {
                this.invoiceDateMin =
                    _dteInvoiceDateMin !== undefined
                        ? pad(_dteInvoiceDateMin.getMonth() + 1, 2) +
                          "/" +
                          pad(_dteInvoiceDateMin.getDate(), 2) +
                          "/" +
                          _dteInvoiceDateMin.getFullYear()
                        : "";
                this.invoiceDateMax =
                    _dteInvoiceDateMax !== undefined
                        ? pad(_dteInvoiceDateMax.getMonth() + 1, 2) +
                          "/" +
                          pad(_dteInvoiceDateMax.getDate(), 2) +
                          "/" +
                          _dteInvoiceDateMax.getFullYear()
                        : "";

                this.newCarrierAccounts = _arrCarrierAccounts;
            }
        },

        deleteUpload: function (p_strInFileName) {
            var _arrFiles = collectionToArray(this.uploadFiles);

            for (var _intFile = 0; _intFile < _arrFiles.length; _intFile++) {
                if (_arrFiles[_intFile].name === p_strInFileName) {
                    for (var _intDetails = 0; _intDetails < this.uploadDetails.length; _intDetails++) {
                        if (this.uploadDetails[_intDetails].name === _arrFiles[_intFile].name) {
                            if (this.uploadDetails[_intDetails].data.carrierAccounts.length > 0) {
                                for (
                                    var _intCarrAcct = 0;
                                    _intCarrAcct < this.uploadDetails[_intDetails].data.carrierAccounts.length;
                                    _intCarrAcct++
                                ) {
                                    this.newCarrierAccounts.splice(
                                        this.newCarrierAccounts.indexOf(
                                            this.uploadDetails[_intDetails].data.carrierAccounts[_intCarrAcct]
                                        ),
                                        1
                                    );
                                }
                            }

                            this.uploadDetails.splice(_intDetails, 1);
                        }
                    }
                    _arrFiles.splice(_intFile, 1);
                }
            }

            this.uploadFiles = _arrFiles;

            if (this.uploadFiles.length === 0) {
                this.exitFileUpload();
            }
        },

        startImporter: async function () {
            // var _blnHasError = false;
            // var _objFile = Object;
            // var _strFileName = "";

            var _objFiles = [];
            var _objDetails = [];

            var _strErrorMsg = "";

            var _arrNewAccounts = [];

            // var _blnUpdateCarrierAccounts = false;

            var _intUploadCnt = 0;

            try {
                _objFiles = this.uploadFiles;
                _objDetails = this.uploadDetails;

                if (_objFiles.length) {
                    _arrNewAccounts = this.filterNewCarrierAccounts(this.existCarrierAccounts, this.newCarrierAccounts);

                    if (_arrNewAccounts.length > 0) {
                        await this.updateCarrierAccounts(this.newCarrierAccounts);
                    }

                    for (var _intFile = 0; _intFile < _objFiles.length; _intFile++) {
                        let _blnHasError = false;
                        let _objFile = _objFiles[_intFile];
                        let _strFileName = _objFile.name.split(' ').join('_');

                        const user = this.$store.getters.user;
                        s3.upload(
                            {
                                Key: _strFileName,
                                Body: _objFile,
                                ACL: "public-read",
                                Metadata: {
                                    author: user ? user.username : '',
                                    user: user ? user.username : '',
                                    client_id: this.entityID
                                }
                            },
                            function (err) {
                                if (err) {
                                    console.log(`There was an error: ${_strFileName} `, err.message);
                                    _blnHasError = true;
                                }
                            }
                        );

                        for (var _intDetails = 0; _intDetails < _objDetails.length; _intDetails++) {
                            if (_objDetails[_intDetails].name.split(' ').join('_') === _strFileName) {
                                if (!_blnHasError) {
                                    _objDetails[_intDetails].uploaded = !_blnHasError;

                                    _intUploadCnt += 1;
                                } else {
                                    _objDetails[_intDetails].uploadError = "Unable to upload file";
                                }

                                break;
                            }
                        }
                    }

                    if (_intUploadCnt > 0) {
                        this.importTriggered = true;
                    }
                }
            } catch (err) {
                _strErrorMsg = err.message;
            } finally {
                this.uploadDetails.length = 0;

                this.errorMsg = _strErrorMsg.charAt(0).toUpperCase() + _strErrorMsg.slice(1);

                this.isComplete = true;
            }
        },

        filterNewCarrierAccounts: function (p_arrInExistCarrierAccounts, p_arrInNewCarrierAccounts) {
            var _arrResult = [];

            try {
                if (p_arrInNewCarrierAccounts.length > 0) {
                    for (var _intCnt = 0; _intCnt < p_arrInNewCarrierAccounts.length; _intCnt++) {
                        if (p_arrInExistCarrierAccounts.indexOf(p_arrInNewCarrierAccounts[_intCnt]) < 0) {
                            _arrResult.push(p_arrInNewCarrierAccounts[_intCnt]);
                        }
                    }
                }
            } catch (err) {
                console.log(err.message);

                _arrResult.length = 0;
            }
            return _arrResult;
        },

        getCarrierAccounts: async function (p_strInEntityID, p_strInCarrier) {
            var _arrResult = [];

            // var _apiCarrierAccounts = Object;

            try {
                const url = `CarrierAcctNums/${p_strInEntityID}/${p_strInCarrier}`;

                const _apiCarrierAccounts = await this.$http.get(url);
                if (_apiCarrierAccounts.data.error_msg === undefined) {
                    if (
                        _apiCarrierAccounts.data.id.toUpperCase().trim() === p_strInEntityID.toUpperCase().trim() ||
                        _apiCarrierAccounts.data.carrier.toUpperCase().trim() === p_strInCarrier.toUpperCase().trim()
                    ) {
                        for (var _intCnt = 0; _intCnt < _apiCarrierAccounts.data.carrier_acct_nums.length; _intCnt++) {
                            _arrResult.push(_apiCarrierAccounts.data.carrier_acct_nums[_intCnt]);
                        }
                    }
                } else {
                    throw new Error(_apiCarrierAccounts.data.error_msg);
                }
            } catch (err) {
                _arrResult.length = 0;

                console.log(err.message);
            }
            return _arrResult;
        },

        updateCarrierAccounts: async function (p_arrInCarrierAccounts) {
            var _blnResult = false;

            // var _strErrorMsg = undefined;

            // var _apiResponse = Object;

            var _objAPIPostData = {
                sub_client_id: "",
                carrier: "",
                carrier_account_nums: [],
            };

            try {
                _objAPIPostData.sub_client_id = this.entityID;
                _objAPIPostData.carrier = this.carrier;
                _objAPIPostData.carrier_account_nums = p_arrInCarrierAccounts;

                const url = "AddUpdateDeleteCarrierAccount/";
                const _apiResponse = this.$http.post(url, _objAPIPostData);

                if (_apiResponse.data.error_msg === undefined) {
                    _blnResult = _apiResponse.data.success;
                } else {
                    throw new Error(_apiResponse.data.error_msg);
                }
            } catch (err) {
                console.log(err.message);

                _blnResult = !(err.message.trim().length > 0);

                // _strErrorMsg = err.message;
            } finally {
                _objAPIPostData = null;
            }

            return _blnResult;
        },

        exitFileUpload: function () {
            this.showDialog = false;
            this.uploadFiles = [];
            this.isComplete = false;
            this.$emit("input", this.importTriggered);
        },
    },
};
</script>

<!-- SASS styling -->
<style lang="scss" scoped>
.results {
    display: flex;
    justify-content: left;
    margin-top: 1em;
    margin-bottom: 1em;
    margin-left: 1em;
    margin-right: 1em;
}

.submitError {
    color: red;
    text-transform: initial;
}

.uploadError {
    font-style: italic;
    vertical-align: text-top;
    margin-top: 0;
    margin-left: 3em;
    padding-top: 0;
    padding-left: 0;
}

.uploadSummary {
    text-align: left;
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 3em;
}

.summaryAccounts {
    width: auto;
    display: block;
    float: left;
}

.summaryInvoices {
    width: auto;
    display: block;
    float: right;
}
</style>

const state = {
    signedIn: false,
    user: null,
};

const getters = {
    user: (state) => state.user,
    userDisplayName: (state) => (state.user ? state.user.username : ""),
    signedIn: (state) => state.signedIn,
    isAdmin: (state) => {
        if (state.user && state.user.signInUserSession.idToken.payload["cognito:groups"]) {
            return state.user.signInUserSession.idToken.payload["cognito:groups"].includes("Administrators");
        } else {
            return false;
        }
    },
};

const mutations = {
    setUser(state, user) {
        state.user = user;
    },
    setSignedIn(state, signedIn) {
        state.signedIn = signedIn;
    },
};

const actions = {
    signIn({ commit }, user) {
        commit("setUser", user);
        commit("setSignedIn", true);
    },

    signOut({ commit }) {
        commit("setUser", null);
        commit("setSignedIn", false);
    },
};

export default {
    state,
    getters,
    mutations,
    actions,
};
